import { basicNoise3, powCurve } from '../shaders';
// used for both leaves and limbs
export const treeVertPreamble = /* glsl */ `
uniform float windIntensity;
uniform float elapsedTime;
attribute float thickness;

${basicNoise3}

`;

export const leafVertPreamble = /* glsl */ `

uniform float leafOffset1;
uniform float leafOffset2;

mat4 createRotationMatrix(vec3 axis, float angle) {
  axis = normalize(axis);
  float s = sin(angle);
  float c = cos(angle);
  float oc = 1.0 - c;
  
  return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
              oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
              oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
              0.0,                                0.0,                                0.0,                                1.0);
}

vec3 rotate(vec3 v, vec3 axis, float angle) {
  mat4 m = createRotationMatrix(axis, angle);
  return (m * vec4(v, 1.0)).xyz;
}

mat4 rotationFromEuler(vec3 euler) {
  float x = euler.x;
  float y = euler.y;
  float z = euler.z;
  float a = cos(x);
  float b = sin(x);
  float c = cos(y);
  float d = sin(y);
  float e = cos(z);
  float f = sin(z);

  float ae = a * e;
  float af = a * f;
  float be = b * e;
  float bf = b * f;

  // te[0] = c * e;
  // te[4] = - c * f;
  // te[8] = d;

  // te[1] = af + be * d;
  // te[5] = ae - bf * d;
  // te[9] = - b * c;

  // te[2] = bf - ae * d;
  // te[6] = be + af * d;
  // te[10] = a * c;

  // bottom row
  // te[3] = 0;
  // te[7] = 0;
  // te[11] = 0;

  // last column
  // te[12] = 0;
  // te[13] = 0;
  // te[14] = 0;
  // te[15] = 1;

  return mat4(c * e,        - c * f,      d,        0.0,
              af + be * d,  ae - bf * d,  - b * c,  0.0,
              bf - ae * d,  be + af * d,  a * c,    0.0,
              0.0,          0.0,          0.0,      1.0);
}

const float PI_2 = 1.5707963267948966;

vec3 flutterLeaf(vec3 pos, float elapsedTime, float windIntensity) {
  
  float noise1 = noise(instanceColor * (elapsedTime + leafOffset1));
  float noise2 = noise(instanceColor * (elapsedTime + leafOffset2));

  vec3 axis = normalize(vec3(0.1 * noise1, 1.0, 0.2 * noise2));

  float angle = cos((elapsedTime + leafOffset1 * 10.0) * 13.0) * PI_2 * windIntensity / 2.0;
  angle += cos((elapsedTime + leafOffset2 * 10.0) * 3.0) * PI_2 * windIntensity / 2.0;

  mat4 rotation = createRotationMatrix(axis, angle);
  vec3 result = pos.xyz;
  result.y -= 0.05;
  result = (rotation * vec4(result, 1.0)).xyz;
  result.y += 0.05;

  return result;
}
`;

export const leafVertMainInjection = /* glsl */ `
// transformed += noise(transformed * elapsedTime * 0.1 * windIntensity).xyz;
transformed = flutterLeaf(transformed, elapsedTime, windIntensity);
`;

export const limbsVertMainInjection = /* glsl */ `
// transformed += noise(transformed * elapsedTime * 0.1 * windIntensity).xyz;
`;

export const leafFragPreamble = /* glsl */ `
${powCurve}
`;

export const leafFragMainInjection = /* glsl */ `
// TODO: would be nice if gl_FrontFacing worked. Can't seem to get it happening
// In which case we could lighten the back sides of leaves.
// https://github.com/mrdoob/three.js/issues/25149#issuecomment-1353614434
// https://github.com/mrdoob/three.js/issues/27892
//
// As-is, let's at least lighten the shadows a bit
gl_FragColor.rgb = powCurve(gl_FragColor.rgb, 0.47);
`;
