import { ChangeEventHandler, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { formatDuration } from 'date-fns';
import { StoreContext } from '../../store';

export const SearchForm = observer(() => {
  const { history: store } = useContext(StoreContext);

  const handleSearchChanged: ChangeEventHandler<HTMLInputElement> = event => {
    const values = event.target.value.toLowerCase().split(' ');
    if (values.length > 0) {
      const matchingKeywords: string[] = [];
      values.forEach(value => {
        matchingKeywords.push(...store.keywords.filter(k => k.toLowerCase().includes(value)));
      });
      store.setSelectedKeywords(matchingKeywords);
    } else {
      store.clearSelectedKeywords();
    }
  };

  return (
    <div className="search-form">
      <div className="years-experience">
        <div className="duration">
          {formatDuration(store.selectedKeywordsExperienceDuration, { format: ['years', 'months'] })}
        </div>
        {/* <input onChange={handleSearchChanged} value={Array.from(store.selectedKeywords).join(' ')} /> */}
      </div>
      <div className="clear-filters-control">
        <span className="clear-filters-label">Clear Filters</span>
        <button onClick={() => store.clearSelectedKeywords()}>[x]</button>
      </div>
    </div>
  );
});
