import { useContext, useEffect, useState } from 'react';
import { Mesh, MeshBasicMaterial, MeshStandardMaterial, SRGBColorSpace, Texture, Vector2 } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { objIsMesh } from './utils';
import { Triplet } from '../../../types';
import { StoreContext } from '../../../store';

// ick?
export const Mountains = ({
  position = [0, 120, -1000],
  rotation = [0, Math.PI * -1, 0],
  scale = [2, 3, 2],
}: {
  position?: Triplet;
  rotation?: Triplet;
  scale?: Triplet;
}) => {
  const { background: store } = useContext(StoreContext);
  const [mesh, setMesh] = useState<Mesh>();

  useEffect(() => {
    (async () => {
      const [gltf /* , diffuse, normal */] = await Promise.all([
        store.loadAsset<GLTF>('/mountain-lake/mountain-range/MLRT_MountainRange.gltf'),
        // TODO: these look crap
        // store.loadAsset<Texture>('/mountain-lake/mountain-range/MLRT_Mountainrange_BaseMap.jpg'),
        // store.loadAsset<Texture>('/mountain-lake/mountain-range/MLRT_Mountainrange_Normal.jpg'),
      ]);
      // diffuse.colorSpace = SRGBColorSpace;
      const obj = gltf.scene.children[0].children[0];
      if (objIsMesh(obj)) {
        obj.material = new MeshBasicMaterial({ color: 'black' });
        // obj.material = new MeshStandardMaterial({
        //   map: diffuse,
        //   normalMap: normal,
        //   // normalScale: new Vector2(2, 2),
        // });
        setMesh(obj);
      }
    })();
  }, []);

  return mesh ? (
    <group position={position} rotation={rotation} scale={scale}>
      <primitive object={mesh} />
    </group>
  ) : null;
};
