import { useContext } from 'react';
import { StoreContext } from '../../../store';
import { Project, Job, JobType } from '../../../store/types';
import { HistoryItemView, HistoryItemSubclassProps } from './HistoryItemView';
import { assertIsDefined } from '../../../utils';
import { Link } from 'react-router-dom';

export const ProjectView = ({
  project,
  ...rest
}: {
  project: Project;
} & HistoryItemSubclassProps) => {
  const { history: historyStore } = useContext(StoreContext);

  let jobs: Job[] = [];
  if (project.jobIds) {
    project.jobIds.forEach(jobId => {
      const job = historyStore.getJob(jobId);
      if (job) {
        jobs.push(job);
      }
    });
  }

  // jobIds on the project will override a companyId.
  const projectCompany = historyStore.getCompany(project.companyId);
  let projectCompanyLink = null;
  if (projectCompany && projectCompany.url) {
    projectCompanyLink = <a href={projectCompany.url}>{projectCompany.name}</a>;
  } else if (projectCompany) {
    projectCompanyLink = projectCompany.name;
  }

  const employerList = (
    <span>
      {jobs.length
        ? jobs.map((job, i) => {
            const company = historyStore.getCompany(job.companyId);
            if (company) {
              const includeComma = jobs.length > 0 && i < jobs.length - 1;
              return (
                <span key={job.id}>
                  <Link to={`/jobs/${job.id}`}>{company.name}</Link>
                  {includeComma ? ',' : ''}{' '}
                </span>
              );
            }
            return null;
          })
        : projectCompanyLink}
    </span>
  );

  let itemDetail = (
    <div>
      {project.role ? <span>{project.role} @ </span> : null}
      {employerList}
      {jobs.length ? <span className="detail-secondary"> ({jobs.map(j => j.type).join(', ')})</span> : null}
      {project.clientName ? <span> for {project.clientName}</span> : null}
    </div>
  );
  return (
    <HistoryItemView
      item={project}
      itemName={project.name}
      itemDetail={itemDetail}
      itemUrl={project.url}
      moreLinkUrl={`/projects/${project.id}`}
      {...rest}
    />
  );
};
