import { makeAutoObservable, runInAction, when } from 'mobx';
import {
  VIEW_MED_BREAKPT,
  VIEW_SML_BREAKPT,
  VIEW_TNY_BREAKPT,
  LIST_MIN_WIDTH_PX,
  LIST_MAX_WIDTH_PX,
  HISTORY_VIEW_MAX_WIDTH_PX,
  TIMELINE_MAX_WIDTH_PX,
  TIMELINE_RANGE_MAX_WIDTH_PX,
  TIMELINE_DEFAULT_WIDTH_PCT,
  HISTORY_VIEW_DEFAULT_LIST_WIDTH_PCT,
  TIMELINE_MIN_WIDTH_PX,
  GRID_GAP_PX,
} from './constants';
import { HvMode, HvLayoutBasis, HvBreakPt } from './types';
import { clamp } from '../utils';

export class HvLayout {
  containerWidth: number;
  mode: HvMode;
  isInitialized: boolean;

  constructor({ containerWidth, mode }: HvLayoutBasis) {
    this.containerWidth = containerWidth;
    this.mode = mode;
    this.isInitialized = this.containerWidth > 0;
    makeAutoObservable(this);

    if (!this.isInitialized) {
      when(() => this.containerWidth > 0).then(() => {
        // give the css transitions a second to run before we say we're ready.
        setTimeout(() => {
          runInAction(() => {
            this.isInitialized = true;
          });
        }, 1000);
      });
    }
  }

  setContainerWidth(width: number) {
    this.containerWidth = width;
  }

  setMode(mode: HvMode) {
    this.mode = mode;
  }

  get gapWidth() {
    if (this.viewBreakPts.includes('sml')) {
      return GRID_GAP_PX / 2;
    }
    return GRID_GAP_PX;
  }

  get viewBreakPts() {
    const result: HvBreakPt[] = [];
    if (this.containerWidth < VIEW_MED_BREAKPT) {
      result.push('med');
    }
    if (this.containerWidth < VIEW_SML_BREAKPT) {
      result.push('sml');
    }
    if (this.containerWidth < VIEW_TNY_BREAKPT) {
      result.push('tny');
    }
    return result;
  }

  get historyViewWidth() {
    return Math.min(HISTORY_VIEW_MAX_WIDTH_PX, this.containerWidth);
  }

  get centerline() {
    switch (this.mode) {
      case 'all':
        return this.historyViewWidth / 2;
      case 'jobs':
        const jobsCentered =
          this.historyViewWidth / 2 + this.timelineWidth / 2 + this.gapWidth + this.jobsListWidth / 2;
        const jobsEdge = this.historyViewWidth - this.timelineWidth / 2;
        return Math.min(jobsCentered, jobsEdge);
      case 'projects':
        const projectsCentered =
          this.historyViewWidth / 2 - this.timelineWidth / 2 - this.gapWidth - this.projectsListWidth / 2;
        const projectsEdge = this.timelineWidth / 2;
        return Math.max(projectsCentered, projectsEdge);
    }
    throw new Error(`Unknown mode '${this.mode}'`);
  }

  get timelineRight() {
    return this.centerline + this.timelineWidth / 2;
  }

  get timelineLeft() {
    return this.centerline - this.timelineWidth / 2;
  }

  get historyViewWidthMinusGaps() {
    return this.historyViewWidth - this.gapWidth * 2;
  }

  get historyViewHeight() {
    return 6500; // TODO
  }

  get timelineRangeWidth() {
    return TIMELINE_RANGE_MAX_WIDTH_PX;
  }

  get timelineWidth() {
    switch (this.mode) {
      case 'all':
        return clamp(
          this.historyViewWidthMinusGaps * TIMELINE_DEFAULT_WIDTH_PCT,
          TIMELINE_MIN_WIDTH_PX,
          TIMELINE_MAX_WIDTH_PX,
        );
      case 'jobs':
        return clamp(
          this.historyViewWidthMinusGaps * TIMELINE_DEFAULT_WIDTH_PCT,
          TIMELINE_MIN_WIDTH_PX,
          TIMELINE_MAX_WIDTH_PX,
        );
      case 'projects':
        return clamp(
          this.historyViewWidthMinusGaps * TIMELINE_DEFAULT_WIDTH_PCT,
          TIMELINE_MIN_WIDTH_PX,
          TIMELINE_MAX_WIDTH_PX,
        );
    }
    throw new Error(`Unknown mode '${this.mode}'`);
  }

  get jobsListWidth() {
    switch (this.mode) {
      case 'all':
        return clamp(
          this.historyViewWidthMinusGaps * HISTORY_VIEW_DEFAULT_LIST_WIDTH_PCT,
          LIST_MIN_WIDTH_PX,
          LIST_MAX_WIDTH_PX,
        );
      case 'jobs':
        return clamp(this.containerWidth - this.timelineWidth - this.gapWidth, LIST_MIN_WIDTH_PX, LIST_MAX_WIDTH_PX);
      case 'projects':
        return clamp(
          this.historyViewWidthMinusGaps * HISTORY_VIEW_DEFAULT_LIST_WIDTH_PCT,
          LIST_MIN_WIDTH_PX,
          LIST_MAX_WIDTH_PX,
        );
    }
    throw new Error(`Unknown mode '${this.mode}'`);
  }

  get projectsListWidth() {
    switch (this.mode) {
      case 'all':
        return clamp(
          this.historyViewWidthMinusGaps * HISTORY_VIEW_DEFAULT_LIST_WIDTH_PCT,
          LIST_MIN_WIDTH_PX,
          LIST_MAX_WIDTH_PX,
        );
      case 'jobs':
        return clamp(
          this.historyViewWidthMinusGaps * HISTORY_VIEW_DEFAULT_LIST_WIDTH_PCT,
          LIST_MIN_WIDTH_PX,
          LIST_MAX_WIDTH_PX,
        );
      case 'projects':
        return clamp(this.containerWidth - this.timelineWidth - this.gapWidth, LIST_MIN_WIDTH_PX, LIST_MAX_WIDTH_PX);
    }
    throw new Error(`Unknown mode '${this.mode}'`);
  }

  get projectsListX() {
    return this.timelineRight + this.gapWidth;
  }

  get jobsListX() {
    return this.timelineLeft - this.gapWidth - this.jobsListWidth;
  }

  get timelineX() {
    return this.centerline - this.timelineWidth / 2;
  }
}
