import { createBrowserRouter, useLoaderData } from 'react-router-dom';
import { ProjectView, Chrome, ProjectList, HistoryView } from './plain/components';
import { Project, singleton } from './store';
import { About } from './plain/components/About';

// const LoadedProject = () => {
//   const project = useLoaderData() as Project;
//   return <ProjectView project={project} />;
// };

const { history: store } = singleton();

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Chrome />,
    children: [
      {
        index: true,
        element: <HistoryView />,
      },
      {
        path: 'projects',
        element: <HistoryView />,
      },
      {
        path: 'projects/:projectId',
        element: <HistoryView />,
      },
      {
        path: 'jobs',
        element: <HistoryView />,
      },
      {
        path: 'jobs/:jobId',
        element: <HistoryView />,
      },
      {
        path: 'about',
        element: <About />,
      },
      // {
      //   path: 'projects/:projectId',
      //   id: 'project',
      //   loader: ({ params }) => {
      //     const project = store.getProject(params.projectId!);
      //     if (!project) throw new Response('Not Found', { status: 404 });
      //     return project;
      //   },
      //   element: <LoadedProject />,
      // },
    ],
  },
]);

// https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
// Also, this is lame.
router.subscribe(state => {
  let matches = state.location.pathname.match(/projects\/(.+)/i);
  if (matches?.length === 2) {
    store.setSelectedProjectId(matches[1]);
  } else {
    store.setSelectedProjectId();
  }
  matches = state.location.pathname.match(/jobs\/(.+)/i);
  if (matches?.length === 2) {
    store.setSelectedJobId(matches[1]);
  } else {
    store.setSelectedJobId();
  }
});
