import { useContext, useEffect, useState } from 'react';
import { JobView } from './JobView';
import { Link } from 'react-router-dom';
import { StoreContext } from '../../../store';
import { observer } from 'mobx-react-lite';
import { AnimatePresence } from 'framer-motion';
import { HistoryItemLayout } from './types';

export const JobList = observer(
  ({
    layouts,
    listWidth,
    onMinimumItemHeightChanged,
  }: {
    layouts: HistoryItemLayout[];
    listWidth: number;
    onMinimumItemHeightChanged: (height: number) => void;
  }) => {
    const { history: store } = useContext(StoreContext);
    const [minimumItemHeight, setMinimumItemHeight] = useState(Number.MAX_SAFE_INTEGER);

    useEffect(() => {
      onMinimumItemHeightChanged(minimumItemHeight);
    }, [minimumItemHeight, onMinimumItemHeightChanged]);

    return (
      <div className="history-item-list">
        <AnimatePresence>
          {store.visibleJobs.map((job, i) => {
            return (
              <JobView
                job={job}
                listWidth={listWidth}
                onHeightChanged={height => {
                  setMinimumItemHeight(Math.min(height, minimumItemHeight));
                }}
                itemLayout={layouts[i]}
                isOpen={store.selectedJobId === job.id}
                key={job.id}
                isHighlighted={job.id === store.highlightedJobId}
                onMouseEnter={() => store.setHighlightedJobId(job.id)}
                onMouseLeave={() => store.setHighlightedJobId(undefined)}
              />
            );
          })}
        </AnimatePresence>
      </div>
    );
  },
);
