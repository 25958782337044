import classNames from 'classnames';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

export const Switch = ({
  isOn,
  knob,
  disabled,
  onChange,
  small = false,
}: {
  isOn: boolean;
  knob?: ReactNode;
  disabled?: boolean;
  onChange: (isOn: boolean) => void;
  small?: boolean;
}) => {
  return (
    <motion.div
      layout
      className={classNames('switch', { on: isOn, disabled, small })}
      onClick={() => {
        if (!disabled) {
          onChange(!isOn);
        }
      }}>
      <motion.div layout className={classNames('switch-knob')}>
        {knob ?? <div className="standard-knob" />}
      </motion.div>
    </motion.div>
  );
};
