import { useContext } from 'react';
import { StoreContext } from '../../store';
import { Switch } from './Switch';
import { ESiteMode } from '../../store/UiStore';
import { SceneIconLine, SceneIconColor, PfIconColor, PfIconLIne } from './icons';
import { Gauge } from './Gauge';
import { observer } from 'mobx-react-lite';
import { ControlPanel } from './ControlPanel';
import classNames from 'classnames';

export const SiteModeSwitches = observer(
  ({
    showBgControls = true,
    orientation = 'horizontal',
    small = false,
  }: {
    showBgControls?: boolean;
    orientation?: 'horizontal' | 'vertical';
    small?: boolean;
  }) => {
    const { ui: uiStore, background: backgroundStore } = useContext(StoreContext);
    const bgSwitchIsOn = uiStore.siteMode === ESiteMode.bgOnly || uiStore.siteMode === ESiteMode.both;
    const pfSwitchIsOn = uiStore.siteMode === ESiteMode.portfolioOnly || uiStore.siteMode === ESiteMode.both;
    const isLoading = backgroundStore.loadProgress > 0 && backgroundStore.loadProgress < 1;

    // const bgSwitchIsOn = true;

    return (
      <div className={classNames('site-mode-switches', orientation)}>
        <div className={classNames('pf-switch', { small })}>
          <Switch
            isOn={pfSwitchIsOn}
            small={small}
            knob={pfSwitchIsOn ? <PfIconColor /> : <PfIconLIne />}
            disabled={uiStore.siteMode === ESiteMode.portfolioOnly}
            onChange={isOn => {
              switch (uiStore.siteMode) {
                case ESiteMode.portfolioOnly:
                case ESiteMode.both:
                  uiStore.setMode(ESiteMode.bgOnly);
                  break;
                case ESiteMode.bgOnly:
                  uiStore.setMode(ESiteMode.both);
              }
            }}
          />
        </div>
        <div className={classNames('bg-switch', { small })}>
          <Switch
            isOn={bgSwitchIsOn}
            small={small}
            knob={bgSwitchIsOn ? <SceneIconColor /> : <SceneIconLine />}
            disabled={uiStore.siteMode === ESiteMode.bgOnly}
            onChange={isOn => {
              switch (uiStore.siteMode) {
                case ESiteMode.bgOnly:
                case ESiteMode.both:
                  uiStore.setMode(ESiteMode.portfolioOnly);
                  break;
                case ESiteMode.portfolioOnly:
                  uiStore.setMode(ESiteMode.both);
              }
            }}
          />

          <div
            className="bg-loader"
            style={{
              opacity: isLoading ? 1 : 0,
            }}>
            <Gauge size={100} from={0} to={1} value={backgroundStore.loadProgress} bgColor="transparent" />
          </div>
          {showBgControls && bgSwitchIsOn && !isLoading ? (
            <div className="bg-controls">
              <ControlPanel />
            </div>
          ) : null}
          <div className="bg-fps">{bgSwitchIsOn ? `${backgroundStore.performance.meanFps} fps` : ' '}</div>
        </div>
      </div>
    );
  },
);
