import { makeAutoObservable } from 'mobx';
import { sphericalToCartesian } from '../utils';
import { MathUtils, Vector3, Euler, Color } from 'three';
import { inQuint, outQuad } from '../utils';
import { SphericalPos } from '../types';
import {
  AssetLoader,
  LoadRequestType,
  Loadable,
  focalLengthToFov,
  fovToFocalLength,
  getSunColor,
} from '../plain/components/Background/utils';

export interface IBackgroundState {
  wind: {
    isManual: boolean;
    intensity: number;
  };
  sun: {
    azimuth: number;
    elevation: number;
    turbidity: number;
    rayleigh: number;
    mieCoefficient: number;
    mieDirectionalG: number;
  };
  camera: {
    xRot: number;
    yRot: number;
    fov: number; // in degrees, for PerspectiveCamera
    xPos: number;
    yPos: number;
    zPos: number;
    exposure: number;
  };
  cameraTrack: {
    yPosProgress: number;
    xPosProgress: number;
    fovProgress: number;

    xRotIsManual: boolean;
    yPosIsManual: boolean;
    xPosIsManual: boolean;
    fovIsManual: boolean;

    minFov: number;
    maxFov: number;

    maxYPos: number;
    minYPos: number;

    maxXPos: number;
    minXPos: number;

    maxXRot: number;
    minXRot: number;
  };
  performance: {
    instantFps: number;
    meanFps: number;
  };
}

export type BackgroundJson = Omit<IBackgroundState, 'performance'>;

// afternoon, starts looking up at the sky, a bit far away.
// rotates x from up to down.
const DEFAULT_BG_STATE_1: IBackgroundState = {
  wind: {
    isManual: false,
    intensity: 0,
  },
  sun: {
    azimuth: 131,
    elevation: 75,
    turbidity: 2,
    rayleigh: 1,
    mieCoefficient: 0.005,
    mieDirectionalG: 0.8,
  },
  camera: {
    xRot: Math.PI * 0.25,
    yRot: 0,
    fov: 60,
    xPos: 3,
    yPos: 20,
    zPos: 8,
    exposure: 1.2,
  },
  cameraTrack: {
    yPosProgress: 0,
    xPosProgress: 1,
    fovProgress: 1,
    xRotIsManual: false,
    yPosIsManual: false,
    xPosIsManual: false,
    fovIsManual: false,
    minFov: 14,
    maxFov: 110,
    minYPos: 0,
    maxYPos: 20,
    maxXPos: 8,
    minXPos: -4,
    maxXRot: Math.PI * 0.25,
    minXRot: 0,
  },
  performance: {
    instantFps: 0,
    meanFps: 0,
  },
};

// close in, no x rotation, dusk.
const DEFAULT_BG_STATE_2: IBackgroundState = {
  wind: {
    isManual: false,
    intensity: 0,
  },
  sun: {
    azimuth: 45,
    elevation: 180,
    turbidity: 2,
    rayleigh: 1,
    mieCoefficient: 0.005,
    mieDirectionalG: 0.8,
  },
  camera: {
    xRot: 0,
    yRot: 0,
    fov: 18,
    xPos: 3,
    yPos: 20,
    zPos: 8,
    exposure: 1.2,
  },
  cameraTrack: {
    yPosProgress: 0,
    xPosProgress: 0.5,
    fovProgress: 0.5,
    xRotIsManual: false,
    yPosIsManual: false,
    xPosIsManual: false,
    fovIsManual: false,
    minFov: 18,
    maxFov: 110,
    minYPos: 0,
    maxYPos: 23,
    maxXPos: 1.5,
    minXPos: -2,
    maxXRot: 0,
    minXRot: 0,
  },
  performance: {
    instantFps: 0,
    meanFps: 0,
  },
};

export const defaultBackgroundState = DEFAULT_BG_STATE_2;

export class BackgroundStore implements IBackgroundState {
  wind: {
    isManual: boolean;
    intensity: number;
  };
  sun: {
    azimuth: number;
    elevation: number;
    turbidity: number;
    rayleigh: number;
    mieCoefficient: number;
    mieDirectionalG: number;
  };
  camera: {
    xRot: number;
    yRot: number;
    fov: number;
    xPos: number;
    yPos: number;
    zPos: number;
    exposure: number;
  };
  cameraTrack: {
    yPosProgress: number; // drives both yPos and xRot
    xPosProgress: number;
    fovProgress: number;
    xRotIsManual: boolean;
    yPosIsManual: boolean;
    xPosIsManual: boolean;
    fovIsManual: boolean;
    minFov: number;
    maxFov: number;
    maxYPos: number;
    minYPos: number;
    maxXPos: number;
    minXPos: number;
    maxXRot: number;
    minXRot: number;
  };
  performance: { instantFps: number; meanFps: number };

  loader: AssetLoader;

  constructor(backgroundState?: IBackgroundState) {
    // TS is not smart enough to figure this out.
    // Object.assign(this, { ...defaultBackgroundState, ...backgroundState })
    this.wind = { ...defaultBackgroundState.wind, ...backgroundState?.wind };
    this.sun = { ...defaultBackgroundState.sun, ...backgroundState?.sun };
    this.camera = { ...defaultBackgroundState.camera, ...backgroundState?.camera };
    this.cameraTrack = { ...defaultBackgroundState.cameraTrack, ...backgroundState?.cameraTrack };
    this.performance = { ...defaultBackgroundState.performance, ...backgroundState?.performance };
    this.loader = new AssetLoader();

    makeAutoObservable(this);
  }

  setWindIsManual(isManual: boolean) {
    this.wind.isManual = isManual;
  }
  setWindIntensity(intensity: number) {
    this.wind.intensity = intensity;
  }
  setSunAzimuth(azimuth: number) {
    this.sun.azimuth = azimuth;
  }
  setSunElevation(elevation: number) {
    this.sun.elevation = elevation;
  }
  setCameraFocalLength(length: number) {
    this.camera.fov = MathUtils.radToDeg(focalLengthToFov(length));
  }
  setCameraXRot(xRot: number) {
    this.camera.xRot = xRot;
  }
  setCameraYRot(yRot: number) {
    this.camera.yRot = yRot;
  }
  /**
   * Pass fov in degrees.
   */
  setCameraFov(fov: number) {
    this.camera.fov = fov;
  }
  setCameraXPos(xPos: number) {
    this.camera.xPos = xPos;
  }
  setCameraYPos(yPos: number) {
    this.camera.yPos = yPos;
  }
  setCameraZPos(zPos: number) {
    this.camera.zPos = zPos;
  }
  setCameraTrackYPosProgress(progress: number) {
    this.cameraTrack.yPosProgress = progress;
  }
  setCameraTrackXPosProgress(progress: number) {
    this.cameraTrack.xPosProgress = progress;
  }
  setCameraTrackFovProgress(progress: number) {
    this.cameraTrack.fovProgress = progress;
  }
  setCameraTrackXRotIsManual(isManual: boolean) {
    this.cameraTrack.xRotIsManual = isManual;
  }
  setCameraTrackYPosIsManual(isManual: boolean) {
    this.cameraTrack.yPosIsManual = isManual;
  }
  setCameraTrackXPosIsManual(isManual: boolean) {
    this.cameraTrack.xPosIsManual = isManual;
  }
  setCameraTrackFovIsManual(isManual: boolean) {
    this.cameraTrack.fovIsManual = isManual;
  }
  setCameraTrackMinFov(min: number) {
    this.cameraTrack.minFov = min;
  }
  setCameraTrackMaxFov(max: number) {
    this.cameraTrack.maxFov = max;
  }
  setCameraTrackMaxYPos(yPos: number) {
    this.cameraTrack.maxYPos = yPos;
  }
  setCameraTrackMinYPos(yPos: number) {
    this.cameraTrack.minYPos = yPos;
  }
  setCameraTrackMaxXRot(xRot: number) {
    this.cameraTrack.maxXRot = xRot;
  }
  setCameraTrackMinXRot(xRot: number) {
    this.cameraTrack.minXRot = xRot;
  }
  setCameraExposure(exposure: number) {
    this.camera.exposure = exposure;
  }
  setPerformanceInstantFps(fps: number) {
    this.performance.instantFps = fps;
  }
  setPerformanceMeanFps(fps: number) {
    this.performance.meanFps = fps;
  }

  get sunSphericalPosition() {
    return { elevation: this.sun.elevation, azimuth: this.sun.azimuth };
  }

  get cameraXRot() {
    return this.cameraTrack.xRotIsManual
      ? this.camera.xRot
      : MathUtils.lerp(this.cameraTrack.maxXRot, this.cameraTrack.minXRot, this.cameraTrack.yPosProgress);
  }

  get cameraYPos() {
    return this.cameraTrack.yPosIsManual
      ? this.camera.yPos
      : MathUtils.lerp(this.cameraTrack.maxYPos, this.cameraTrack.minYPos, this.cameraTrack.yPosProgress);
  }

  get cameraXPos() {
    return this.cameraTrack.xPosIsManual
      ? this.camera.xPos
      : MathUtils.lerp(this.cameraTrack.minXPos, this.cameraTrack.maxXPos, this.cameraTrack.xPosProgress);
  }

  get cameraFov() {
    return this.cameraTrack.fovIsManual
      ? this.camera.fov
      : MathUtils.lerp(this.cameraTrack.minFov, this.cameraTrack.maxFov, this.cameraTrack.fovProgress);
  }

  get cameraPosition() {
    return new Vector3(this.cameraXPos, this.cameraYPos, this.camera.zPos);
  }

  get cameraRotation() {
    return new Euler(this.cameraXRot, this.camera.yRot, 0);
  }

  get cameraFocalLength() {
    return fovToFocalLength(MathUtils.degToRad(this.camera.fov));
  }

  get sunCartesianPos() {
    return sphericalToCartesian({ elevation: this.sun.elevation, azimuth: this.sun.azimuth });
  }

  get sunHorizonScalar() {
    const normalized = this.sunCartesianPos.clone().normalize();
    return normalized.y > 0 ? Math.abs(Math.sin(MathUtils.degToRad(this.sun.elevation))) : 0;
  }

  get sunColor() {
    return getSunColor(this.sun.elevation);
  }

  get directionalLightIntensity() {
    return MathUtils.clamp(inQuint(this.sunHorizonScalar * 7), 0, 3);
  }

  get hemisphericalLightIntensity() {
    return MathUtils.clamp(outQuad(this.sunHorizonScalar), 0, 3);
  }

  get ambientLightIntensity() {
    return this.hemisphericalLightIntensity * 0.3;
  }

  curveForScattering(min: number, max: number) {
    return MathUtils.lerp(min, max, inQuint(inQuint(1 - this.sunHorizonScalar)));
  }

  get scattering() {
    // TODO: probably want different curves for these guys
    return {
      turbidity: this.curveForScattering(0.1, 7.2),
      rayleigh: this.curveForScattering(0.01, 4),
      mieCoefficient: this.curveForScattering(0.024, 0.1),
      mieDirectionalG: 0.988,
    };
  }

  static fromJson(json: BackgroundJson) {
    return new BackgroundStore({ ...defaultBackgroundState, ...json });
  }

  get loadProgress() {
    return this.loader.progress;
  }

  loadAsset<T extends Loadable>(path: string, type?: LoadRequestType) {
    return this.loader.load<T>(path, type);
  }

  reset() {
    this.sun = defaultBackgroundState.sun;
    this.camera = defaultBackgroundState.camera;
    this.cameraTrack = defaultBackgroundState.cameraTrack;
    this.performance = defaultBackgroundState.performance;
  }

  toJSON(): BackgroundJson {
    return {
      wind: Object.assign({}, this.wind),
      sun: Object.assign({}, this.sun),
      camera: Object.assign({}, this.camera),
      cameraTrack: Object.assign({}, this.cameraTrack),
    };
  }
}
