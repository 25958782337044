import { useContext, useState } from 'react';
import { StoreContext } from '../../../store';
import { observer } from 'mobx-react-lite';
import { ESiteMode, siteModeToName } from '../../../store/UiStore';
import { BackgroundControls } from './BackgroundControls';
import { UiControls } from './UiControls';
import classNames from 'classnames';
import { CaratDownIcon, SettingsIcon } from '../icons';

export const ControlPanel = observer(() => {
  const { reset } = useContext(StoreContext);
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className={classNames('control-panel-container', { visible: isVisible })}>
      <div className="control-panel-button" onClick={() => setIsVisible(!isVisible)}>
        <SettingsIcon scale={0.8} />
      </div>
      <div className={classNames('control-panel', { visible: isVisible })}>
        {/* <UiControls /> */}
        <BackgroundControls />
        <div className="cp-section">
          <div className="control">
            <button className="control-input" onClick={reset} style={{ gridColumnStart: 'enable' }}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
