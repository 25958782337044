import { useEffect, useState, useRef, useContext } from 'react';
import {
  Group,
  Mesh,
  MeshStandardMaterial,
  DoubleSide,
  RepeatWrapping,
  WebGLProgramParametersWithUniforms,
  SRGBColorSpace,
  MeshBasicMaterial,
  Object3D,
  Texture,
} from 'three';
import { StoreContext } from '../../../../store';
import { useFrame } from '@react-three/fiber';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { basicNoise3, powCurve } from '../shaders';

import { Triplet } from '../../../../types';
import { BackgroundStore } from '../../../../store/BackgroundStore';

export type GrlBushType = 'Broadleaf Bush L1' | 'Broadleaf Bush M1';

const getTextures = async (store: BackgroundStore) => {
  return Promise.all([
    store.loadAsset<Texture>(`/grasslands/common/bush-albedo.png`),
    store.loadAsset<Texture>(`/grasslands/common/bush-normal.png`),
  ]);
};

const getGltfPath = (type: GrlBushType) => {
  switch (type) {
    case 'Broadleaf Bush L1':
      return `/grasslands/${type}/tree_Broadleaf_Bush_L1_LOD0.glb`;
    case 'Broadleaf Bush M1':
      return `/grasslands/${type}/tree_Broadleaf_Bush_M1_LOD0.glb`;
  }
};

export const GrlBush = ({
  type,
  position = [0, 0, 0],
  scale = [1, 1, 1],
  rotation = [0, 0, 0],
}: {
  type: GrlBushType;
  position?: Triplet;
  scale?: Triplet;
  rotation?: Triplet;
}) => {
  const { background: store } = useContext(StoreContext);
  const [group, setGroup] = useState<Group>();
  const leafShader = useRef<any>();

  useEffect(() => {
    getTextures(store).then(([albedo, normal]) => {
      [albedo, normal].forEach(texture => {
        texture.colorSpace = SRGBColorSpace;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        texture.flipY = true;
      });
      store.loadAsset<GLTF>(getGltfPath(type)).then(gltf => {
        const group = gltf.scene as Group;
        const mesh = group.children[0] as Mesh;
        const uvs = mesh.geometry.getAttribute('uv');
        for (let i = 0; i < uvs.count; i++) {
          uvs.setY(i, uvs.getY(i) * -1);
        }
        const material = new MeshStandardMaterial({
          map: albedo,
          normalMap: normal,
          side: DoubleSide,
          alphaTest: 0.6,
        });
        material.onBeforeCompile = (shader: WebGLProgramParametersWithUniforms) => {
          shader.uniforms.time = { value: 0 };
          shader.uniforms.windIntensity = { value: 0 };
          const heightFactor = type === 'Broadleaf Bush L1' ? '6.0' : '2.0';
          shader.vertexShader =
            `
              uniform float time;
              uniform float windIntensity;
              varying vec3 vPosition;
              ` + shader.vertexShader;
          shader.vertexShader = shader.vertexShader.replace(
            '#include <begin_vertex>',
            `
            #include <begin_vertex>
            vPosition = transformed;
            float windEffect = sin((time * 4.0) + transformed.x * 2.0 + transformed.z * 2.0) * windIntensity * 0.04;
            windEffect += sin(time * 10.0 + transformed.x + transformed.z) * windIntensity * 0.01;
            windEffect *= transformed.y / ${heightFactor};
            transformed.x += sin(time * 3.0 + transformed.z) * windEffect;
            transformed.y += windEffect;
            `,
          );
          shader.fragmentShader =
            `
            varying vec3 vPosition;
            ${basicNoise3}
            ` + shader.fragmentShader;
          // TODO: this is a bit wonky and doesn't really match the grove tree
          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <opaque_fragment>',
            `
            #include <opaque_fragment>
            gl_FragColor.r = pow(abs(gl_FragColor.r), 1.0 - noise(vPosition) * 0.9) * 0.7 * max(vPosition.y / ${heightFactor}, 0.3);
            gl_FragColor.g = pow(abs(gl_FragColor.g), 1.0 - noise(vPosition) * 0.8) * 0.6 * max(vPosition.y / ${heightFactor}, 0.3);
            gl_FragColor.b = pow(abs(gl_FragColor.b), 1.0 - noise(vPosition) * 0.4) * 0.5 * max(vPosition.y / ${heightFactor}, 0.3);
            `,
          );
          leafShader.current = shader;
        };
        material.customProgramCacheKey = () => type;
        mesh.material = material;
        mesh.castShadow = true;
        group.castShadow = true;
        mesh.receiveShadow = true;
        setGroup(group);
      });
    });
  }, []);

  useFrame(state => {
    const time = state.clock.elapsedTime;
    if (leafShader.current) {
      leafShader.current.uniforms.time = { value: time };
      leafShader.current.uniforms.windIntensity = { value: store.wind.intensity };
    }
  });

  return group ? <primitive object={group} position={position} scale={scale} rotation={rotation} /> : null;
};
