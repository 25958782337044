import { useCallback, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const tags = [
  'WORK IN PROGRESS',
  'NOT FOR BROADCAST',
  'WORK IN PROGRESS',
  'NOT MOBILE-FRIENDLY (YET!)',
  'WORK IN PROGRESS',
  'DO NOT TAUNT PATTERNLEAF.COM',
  'WORK IN PROGRESS',
  '* NOT TECHNICALLY BULLET-PROOF',
  'WORK IN PROGRESS',
  'MORE CONTENT COMING SOON',
  'WORK IN PROGRESS',
  'DO NOT FEED AFTER MIDNIGHT',
  'WORK IN PROGRESS',
  'ASK YOUR DOCTOR IF PATTERNLEAF.COM IS RIGHT FOR YOU',
];

export const Wippy = () => {
  const [currentTagIdx, setCurrentTagIdx] = useState<number>(0);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const stepTags = useCallback(() => {
    setCurrentTagIdx(c => (c + 1) % tags.length);
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(stepTags, 5000);
  }, []);

  useEffect(() => {
    stepTags();
  }, []);

  return (
    <div className="wippy-container">
      <AnimatePresence>
        <motion.p
          key="wippy-a"
          initial={{ y: 10 }}
          animate={{ y: 0 }}
          exit={{ y: -10 }}
          transition={{ duration: 0.5 }}
          className="wippy wippy-a">
          {tags[currentTagIdx]}
        </motion.p>
      </AnimatePresence>
    </div>
  );
};
