import { useContext, useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ProjectView } from './ProjectView';
import { StoreContext } from '../../../store';
import { observer } from 'mobx-react-lite';
import { HistoryItemLayout } from './types';

export const ProjectList = observer(
  ({
    layouts,
    listWidth,
    onMinimumItemHeightChanged,
  }: {
    layouts: HistoryItemLayout[];
    listWidth: number;
    onMinimumItemHeightChanged: (height: number) => void;
  }) => {
    const { history: store } = useContext(StoreContext);
    const [minimumItemHeight, setMinimumItemHeight] = useState(Number.MAX_SAFE_INTEGER);

    useEffect(() => {
      onMinimumItemHeightChanged(minimumItemHeight);
    }, [minimumItemHeight, onMinimumItemHeightChanged]);

    return (
      <div className="history-item-list">
        <AnimatePresence>
          {store.visibleProjects.map((project, i) => {
            return (
              <ProjectView
                project={project}
                key={project.id}
                listWidth={listWidth}
                onHeightChanged={height => {
                  setMinimumItemHeight(Math.min(height, minimumItemHeight));
                }}
                itemLayout={layouts[i]}
                isOpen={store.selectedProjectId === project.id}
                isHighlighted={project.id === store.highlightedProjectId}
                onMouseEnter={() => store.setHighlightedProjectId(project.id)}
                onMouseLeave={() => store.setHighlightedProjectId(undefined)}
              />
            );
          })}
        </AnimatePresence>
      </div>
    );
  },
);
