import { createContext } from 'react';
import { BackgroundJson, BackgroundStore } from './BackgroundStore';
import { HistoryStore, HistoryJson } from './HistoryStore';
import { UiJson, UiStore } from './UiStore';
export * from './HistoryStore';
export * from './types';

type Store = {
  history: HistoryStore;
  background: BackgroundStore;
  ui: UiStore;
  reset: () => void;
};

type StoreJson = {
  history: HistoryJson;
  background: BackgroundJson;
  ui: UiJson;
};

let gStore: Store | undefined;

const PERSISTED_STATE_KEY = 'pl23-state';
export const singleton = () => {
  if (!gStore) {
    const persisted = localStorage.getItem(PERSISTED_STATE_KEY);
    let json: StoreJson | undefined;
    if (persisted) {
      try {
        json = JSON.parse(persisted);
      } catch (error) {
        console.log('could not parse stored state', persisted);
      }
    }
    gStore = {
      history: json?.history ? HistoryStore.fromJson(json.history) : new HistoryStore(),
      background: json?.background ? BackgroundStore.fromJson(json.background) : new BackgroundStore(),
      ui: json?.ui ? UiStore.fromJson(json.ui) : new UiStore(),
      reset: () => {
        localStorage.removeItem(PERSISTED_STATE_KEY);
        gStore?.background.reset();
        gStore?.history.reset();
        gStore?.ui.reset();
      },
    };
  }
  document.addEventListener('visibilitychange', () => {
    if (gStore) {
      const persistedState = {
        history: gStore.history.toJSON(),
        background: gStore.background.toJSON(),
        ui: gStore.ui.toJSON(),
      };
      localStorage.setItem(PERSISTED_STATE_KEY, JSON.stringify(persistedState));
    }
  });
  return gStore;
};

export const StoreContext = createContext(singleton());
