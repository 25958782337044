import { add, intervalToDuration, sub } from 'date-fns';
import type { Duration } from 'date-fns';
import { SphericalPos } from '../types';
import { MathUtils, Vector3 } from 'three';

export * from './easing';

export const findCmp = <T,>(arr: T[], cmp: (a: T, b: T) => boolean) => {
  if (arr.length === 0) return undefined;
  let minIndex = 0;
  for (let i = 1; i < arr.length; i++) {
    if (cmp(arr[i], arr[minIndex])) {
      minIndex = i;
    }
  }
  return arr[minIndex];
};

// Thanks, https://github.com/date-fns/date-fns/issues/2253#issuecomment-1737596439
export const addDurations = (a: Duration, b: Duration) => {
  const baseDate = new Date(0); // can probably be any date, 0 just seemed like a good start

  return intervalToDuration({
    start: baseDate,
    end: add(add(baseDate, a), b),
  });
};

/**
 * Returns a - b;
 */
export const subtractDurations = (a: Duration, b: Duration) => {
  const baseDate = new Date(0);
  const end = sub(add(baseDate, a), b);
  const result = intervalToDuration({
    start: baseDate,
    end: end.getTime() >= baseDate.getTime() ? end : baseDate,
  });
  return result;
};

export const MILLIS_PER_SEC = 1000;
export const MILLIS_PER_MINUTE = 60 * MILLIS_PER_SEC;
export const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;
export const MILLIS_PER_DAY = 24 * MILLIS_PER_HOUR;
export const MILLIS_PER_MONTH = 30 * MILLIS_PER_DAY;
export const MILLIS_PER_YEAR = 365 * MILLIS_PER_DAY;
/**
 * A very rough estimation.
 */
export const durationToMillisecs = (duration: Duration) => {
  return (
    (duration.years ?? 0) * MILLIS_PER_YEAR +
    (duration.months ?? 0) * MILLIS_PER_MONTH +
    (duration.days ?? 0) * MILLIS_PER_DAY +
    (duration.hours ?? 0) * MILLIS_PER_HOUR +
    (duration.minutes ?? 0) * MILLIS_PER_MINUTE +
    (duration.seconds ?? 0) * MILLIS_PER_SEC
  );
};

export const slerp = (from: number, to: number, amt: number) => {
  return from + (to - from) * amt;
};

export const randomNeg1 = () => {
  return Math.random() > 0.5 ? 1 : -1;
};

export const fetchImage = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise<typeof Image>(resolve => {
    const img = new Image(256, 256);
    const url = URL.createObjectURL(blob);
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve(img as any);
    };
    img.src = url;
  });
};

export class AssertionError extends Error {}

export function isAssertionError(value: unknown): value is AssertionError {
  return value instanceof AssertionError;
}

export function assert(condition: unknown, msg?: string): asserts condition {
  if (!condition) {
    throw new AssertionError(msg ?? 'Assertion failed.');
  }
}

export function assertIsDefined<T>(value: T, msg?: string): asserts value is NonNullable<T> {
  if (value == null) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new AssertionError(msg ?? `Expected 'value' to be defined, but received ${value}.`);
  }
}

export function sphericalToCartesian(spherical: SphericalPos, distance = 1, flipTheta = false) {
  const phi = MathUtils.degToRad(90 - spherical.elevation);
  const theta = MathUtils.degToRad(spherical.azimuth);
  return new Vector3().setFromSphericalCoords(distance, phi, theta * (flipTheta ? -1 : 1));
}

export function clamp(n: number, min: number, max: number) {
  return Math.min(Math.max(min, n), max);
}

/**
 * Converts 2D polar coordinates to cartesian coordinates.
 */
export const polarToCartesian = (center: { x: number; y: number }, radius: number, thetaInRadians: number) => {
  return {
    x: center.x + Math.cos(thetaInRadians) * radius,
    y: center.y + Math.sin(thetaInRadians) * radius,
  };
};

/**
 * Returns an svg path definition for a circular arc.
 */
export const getCircularArcPathD = ({
  center,
  startTheta,
  endTheta,
  radius,
}: {
  center: { x: number; y: number };
  startTheta: number;
  endTheta: number;
  radius: number;
}) => {
  const arcStart = polarToCartesian(center, radius, startTheta);
  const arcDest = polarToCartesian(center, radius, endTheta);
  const largeArcFlag = endTheta - startTheta > Math.PI ? 1 : 0;
  const sweepFlag = endTheta >= startTheta ? 1 : 0;

  return `M${arcStart.x},${arcStart.y} A${radius},${radius} 0 ${largeArcFlag},${sweepFlag} ${arcDest.x},${arcDest.y}`;
};
