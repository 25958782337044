import {
  BackSide,
  Matrix4,
  Euler,
  Vector2,
  Vector3,
  ShaderMaterial,
  CubeRefractionMapping,
  WebGLCubeRenderTarget,
  SRGBColorSpace,
  WebGLRenderTarget,
  Scene,
  PlaneGeometry,
  OrthographicCamera,
  Mesh,
  CubeCamera,
  BoxGeometry,
  WebGLRenderer,
  CubeTexture,
  Texture,
  MeshStandardMaterial,
  MeshBasicMaterial,
  SphereGeometry,
  ACESFilmicToneMapping,
} from 'three';
import { useState, useRef, useEffect, FC, useCallback } from 'react';
import { extend, useFrame, ReactThreeFiber, useLoader, useThree } from '@react-three/fiber';
import { shaderMaterial, useCubeTexture, useTexture } from '@react-three/drei';
import StarrySkyShader from '../../../../shaders/StarrySky';
import { Triplet, SphericalPos } from '../../../../types';
import { sphericalToCartesian } from '../utils';
import { SkyCubeCamera } from './SkyCubeCamera';

const CLOUD_DENSITY = 2000;
const RECOMPUTE_FREQ = 1.0;

const SkyMaterial = shaderMaterial(
  {
    cubeMapTex: null, // cubeTexture,
    turbidity: 11.6,
    rayleigh: 4,
    mieCoefficient: 0.024,
    mieDirectionalG: 0.988,
    sunPosition: [-0.4, 0.001, -1],
    up: new Vector3(0, 1, 0),
    resolution: new Vector2(1000, 1000),
    cloudDensity: 0,
    elapsedTime: 0,
  },
  StarrySkyShader.vertexShader,
  StarrySkyShader.fragmentShader,
);

extend({ SkyMaterial });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      skyMaterial: ReactThreeFiber.MaterialNode<ShaderMaterial, typeof SkyMaterial>;
    }
  }
}

export type Scattering = {
  turbidity: number;
  rayleigh: number;
  mieCoefficient: number;
  mieDirectionalG: number;
};

// TODO: can't get this to render anything but black. :/
const createWebGLRenderer = (resolution: { width: number; height: number }) => {
  const canvas = new OffscreenCanvas(resolution.width, resolution.height);
  const context = canvas.getContext('webgl2');
  if (!context) {
    throw new Error('Could not create context for renderer.');
  }
  const renderer = new WebGLRenderer({
    context,
    preserveDrawingBuffer: true,
    antialias: true,
  });
  renderer.toneMapping = ACESFilmicToneMapping;
  return { renderer, canvas };
};

class RenderToTexture {
  renderer: WebGLRenderer;
  scene: Scene;
  material: ShaderMaterial;
  target: WebGLCubeRenderTarget;
  camera: SkyCubeCamera;
  lastCameraNum: number;
  canvas?: OffscreenCanvas;

  constructor({
    renderer,
    resolution,
    sunSphericalPos,
    scattering,
    elapsedTime = 0,
    cloudDensity,
  }: {
    renderer?: WebGLRenderer; // actually required
    resolution: { width: number; height: number };
    sunSphericalPos: SphericalPos;
    scattering: Scattering;
    elapsedTime?: number;
    cloudDensity: number;
  }) {
    this.target = new WebGLCubeRenderTarget(resolution.width, {
      colorSpace: SRGBColorSpace,
    });
    if (renderer) {
      this.renderer = renderer;
    } else {
      // TODO: no worky
      const { renderer, canvas } = createWebGLRenderer(resolution);
      this.renderer = renderer;
      this.canvas = canvas;
    }
    this.scene = new Scene();
    this.camera = new SkyCubeCamera(1, 80000, this.target);

    // with a vanilla CubeCamera:
    // this.camera = new CubeCamera(1, 80000, this.target);
    // this.camera.applyMatrix4(new Matrix4().makeScale(-1, -1, -1));

    const geometry = new SphereGeometry(45000);

    this.material = new ShaderMaterial({
      uniforms: {
        cubeMapTex: { value: null }, // cubeTexture,
        turbidity: { value: scattering.turbidity },
        rayleigh: { value: scattering.rayleigh },
        mieCoefficient: { value: scattering.mieCoefficient },
        mieDirectionalG: { value: scattering.mieDirectionalG },
        sunPosition: { value: sphericalToCartesian(sunSphericalPos, 1) },
        up: { value: new Vector3(0, 1, 0) },
        resolution: { value: new Vector2(1000, 1000) },
        cloudDensity: { value: cloudDensity },
        elapsedTime: { value: elapsedTime },
      },
      vertexShader: StarrySkyShader.vertexShader,
      fragmentShader: StarrySkyShader.fragmentShader,
      // side: BackSide,
      depthWrite: false,
      toneMapped: true,
    });
    const mesh = new Mesh(geometry, this.material);
    this.scene.add(mesh);
    this.camera.update(this.renderer, this.scene, [0, 1, 2, 3, 4, 5]);
    this.lastCameraNum = 0;
  }

  render({
    resolution,
    sunSphericalPos,
    scattering,
    elapsedTime,
    cloudDensity,
  }: {
    resolution: { width: number; height: number };
    sunSphericalPos: SphericalPos;
    scattering: Scattering;
    elapsedTime: number;
    cloudDensity: number;
  }) {
    this.material.uniforms.turbidity.value = scattering.turbidity;
    this.material.uniforms.rayleigh.value = scattering.rayleigh;
    this.material.uniforms.mieCoefficient.value = scattering.mieCoefficient;
    this.material.uniforms.mieDirectionalG.value = scattering.mieDirectionalG;
    this.material.uniforms.sunPosition.value = sphericalToCartesian(sunSphericalPos, 1);
    this.material.uniforms.resolution.value = new Vector2(resolution.width, resolution.height);
    this.material.uniforms.elapsedTime.value = elapsedTime;
    this.material.uniforms.cloudDensity.value = cloudDensity;
    this.material.uniformsNeedUpdate = true;
    const nextCameraNum = (this.lastCameraNum + 1) % 6;
    this.camera.update(this.renderer, this.scene, [nextCameraNum]);
    // this.camera.update(this.renderer, this.scene, [0, 1, 2, 3, 4, 5]);
    this.lastCameraNum = nextCameraNum;

    // TODO: the hope is to move this to a worker, render to a shared array buffer,
    // then back in the main thread read the buffer into a texture.
    // const buffer = new Uint8Array(resolution.width * resolution.width * 4); // assumes rgba
    // this.renderer.readRenderTargetPixels(this.target, 0, 0, resolution.width, resolution.width, buffer, 0);
  }

  get texture() {
    return this.target.texture;
  }

  // get sunColors() {
  //   this.renderer.readRenderTargetPixels(this.target, )
  // }
}

export const Sky: FC<{
  sunSphericalPos: SphericalPos;
  scattering: Scattering;
  cloudDensity: number;
}> = ({ sunSphericalPos, scattering, cloudDensity }) => {
  const materialRef = useRef<ShaderMaterial | null>(null);
  const [resolution, setResolution] = useState<{ width: number; height: number }>({
    width: window.outerWidth,
    height: window.outerHeight,
  });
  const [isFreeRunning, setIsFreeRunning] = useState(true);
  const freeRunningTimer = useRef<ReturnType<typeof setTimeout>>();
  const [nextRecomputeTexture, setNextRecomputeTexture] = useState(0);
  const { gl } = useThree();
  const textureRenderer = useRef<RenderToTexture | undefined>();

  useEffect(() => {
    // console.log('re-render to set material properties');
    const onWindowSizeChanged = () => {
      setResolution({ width: window.outerWidth, height: window.outerHeight });
    };
    const setOnInitialLoad = () => {
      if (!materialRef.current) {
        requestAnimationFrame(setOnInitialLoad);
      } else {
        setResolution({ width: window.outerWidth, height: window.outerHeight });
      }
    };

    window.addEventListener('resize', onWindowSizeChanged);
    requestAnimationFrame(setOnInitialLoad);
    return () => {
      window.removeEventListener('resize', onWindowSizeChanged);
    };
  }, []);

  useEffect(() => {
    textureRenderer.current = new RenderToTexture({
      renderer: gl,
      resolution,
      sunSphericalPos,
      scattering,
      cloudDensity,
    });
  }, []);

  useEffect(() => {
    if (freeRunningTimer.current) {
      clearTimeout(freeRunningTimer.current);
    }
    setIsFreeRunning(true);
    freeRunningTimer.current = setTimeout(() => {
      setIsFreeRunning(false);
    }, 1000);
  }, [scattering, sunSphericalPos, resolution]);

  useFrame(state => {
    const elapsedTime = state.clock.elapsedTime;
    if (materialRef.current && isFreeRunning) {
      const mat = materialRef.current as any;
      mat.sunPosition = sphericalToCartesian(sunSphericalPos).normalize();
      mat.turbidity = scattering.turbidity;
      mat.rayleigh = scattering.rayleigh;
      mat.mieCoefficient = scattering.mieCoefficient;
      mat.mieDirectionalG = scattering.mieDirectionalG;
      mat.resolution = resolution;
      mat.elapsedTime = elapsedTime;
      mat.cloudDensity = cloudDensity;
    } else if (elapsedTime > nextRecomputeTexture) {
      textureRenderer.current?.render({
        resolution,
        sunSphericalPos,
        scattering,
        elapsedTime,
        cloudDensity,
      });
      setNextRecomputeTexture(elapsedTime + RECOMPUTE_FREQ);
    }
  });

  return (
    <>
      <mesh scale={450000}>
        <skyMaterial ref={materialRef} side={BackSide} depthWrite={false} />
        <sphereGeometry args={[1, 1, 1]} />
      </mesh>
      <mesh scale={450000} visible={!isFreeRunning}>
        <meshBasicMaterial envMap={textureRenderer.current?.texture} side={BackSide} />
        <sphereGeometry args={[1, 1, 1]} />
      </mesh>
    </>
  );
};
