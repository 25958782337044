import { Company, Project, Job } from './types';

export const companies: Company[] = [
  {
    id: 'sifteo',
    name: 'Sifteo',
    location: 'San Francisco, CA',
  },
  {
    id: 'cpb',
    name: 'Crispin Porter + Bogusky',
    location: 'Boulder, CO',
    url: 'https://cpbgroup.com',
  },
  {
    id: 'articulate',
    name: 'Articulate',
    location: 'Remote',
    url: 'https://articulate.com',
  },
  {
    id: 'crowd-favorite',
    name: 'Crowd Favorite',
    location: 'Denver, CO',
    url: 'https://crowdfavorite.com',
  },
  {
    id: 'gravy-plane',
    name: 'Gravy Plane LLC',
    location: 'Denver, CO',
  },
  {
    id: 'school',
    name: 'School',
    location: 'Boulder, CO',
    url: 'https://www.linkedin.com/company/school-',
  },
  {
    id: 'piton',
    name: 'The Piton Foundation',
    location: 'Remote',
    url: 'https://www.piton.org',
  },
  {
    id: 'digidesign',
    name: 'Digidesign/Avid',
    location: 'San Francisco, CA',
    url: 'https://protools.com',
  },
  {
    id: 'double-nines',
    name: 'Double Nines Productions',
    location: 'Remote',
    url: 'https://doublenines.co',
  },
  {
    id: 'inspiring-apps',
    name: 'Inspiring Apps',
    location: 'Boulder, CO',
    url: 'https://inspiringapps.com',
  },
  {
    id: 'wonder-giant',
    name: 'Wonder Giant',
    location: 'Colorado',
    url: 'https://www.wondergiant.com',
  },
  {
    id: 'optimal',
    name: 'Optimal LLC',
    location: 'Remote',
    url: 'https://itsoptimal.com',
  },
  {
    id: 'ryder',
    name: 'Ryder System, Inc',
    location: 'Remote',
    url: 'https://www.ryder.com',
  },
  {
    id: 'patternleaf',
    name: 'patternleaf LLC',
    location: 'Remote',
    url: 'https://patternleaf.com',
  },
  {
    id: 'digidesign',
    name: 'Digidesign / Avid',
    location: 'SF Bay Area',
    url: 'https://www.avid.com/pro-tools',
  },
];

export const jobs: Job[] = [
  {
    id: 'digidesign',
    imageUrl: '/avid-logo.svg',
    imageSize: 'contain',
    companyId: 'digidesign',
    type: 'W2',
    projectIds: ['pro-tools'],
    dateRange: {
      desc: '2000-2003',
      start: new Date('2000-05-01'),
      end: new Date('2003-08-01'),
    },
    keywords: [],
  },
  {
    id: 'crowd-favorite',
    imageUrl: 'crowd-favorite.png',
    imageSize: 'cover',
    companyId: 'crowd-favorite',
    type: 'W2',
    projectIds: [],
    dateRange: {
      start: new Date('2007-05-01'),
      end: new Date('2010-09-30'),
    },
    keywords: ['php', 'WordPress', 'mySQL', 'Javascript', 'HTML', 'CSS', 'jQuery'],
  },
  {
    id: 'sifteo',
    imageUrl: 'sifteo.jpg',
    imageSize: 'cover',
    companyId: 'sifteo',
    type: 'W2',
    description: 'Sifteo was an MIT Media Lab project turned into a tabletop physical video game system.',
    projectIds: ['siftrunner'],
    dateRange: {
      start: new Date('2010-10-01'),
      end: new Date('2012-04-01'),
    },
    keywords: [],
  },
  {
    id: 'inspiring-apps',
    imageUrl: 'inspiringapps.jpg',
    imageSize: 'contain',
    type: 'W2',
    companyId: 'inspiring-apps',
    description: 'A small, award-winning technology shop in Boulder, CO',
    dateRange: {
      start: new Date('2014-02-01'),
      end: new Date('2016-08-01'),
    },
    keywords: ['OpenGL', 'ObjC', 'Swift', 'iOS', 'Android', 'Java', 'Ember', 'Canvas', 'avlib'],
  },
  {
    id: 'cpb',
    imageUrl: 'cpb.svg',
    imageSize: 'contain',
    companyId: 'cpb',
    type: 'W2',
    projectIds: ['coop'],
    dateRange: {
      start: new Date('2016-09-01'),
      end: new Date('2020-03-31'),
    },
    keywords: ['Arduino', 'C', 'C++', 'Android', 'iOS', 'Javascript', 'Typescript', 'MongoDB', 'glsl', 'three.js'],
  },
  {
    id: 'double-nines-1',
    imageUrl: 'double-nines.svg',
    imageSize: 'cover',
    companyId: 'double-nines',
    type: '1099',
    projectIds: ['coop'],
    dateRange: {
      start: new Date('2020-04-01'),
      end: new Date('2021-10-31'),
    },
    keywords: [],
  },
  {
    id: 'articulate',
    imageUrl: 'articulate.png',
    imageSize: 'cover',
    companyId: 'articulate',
    type: '1099',
    projectIds: ['hollywood'],
    dateRange: {
      start: new Date('2021-11-01'),
      end: new Date('2023-04-30'),
    },
    keywords: [],
  },
  {
    id: 'double-nines-2',
    imageUrl: 'double-nines.svg',
    imageSize: 'cover',
    companyId: 'double-nines',
    type: '1099',
    projectIds: ['optimal'],
    dateRange: {
      start: new Date('2023-10-01'),
      end: new Date(),
    },
    keywords: [],
  },
];

export const projects: Project[] = [
  {
    id: 'pplus-movie-nights',
    name: `Paramount+ Movie Nights LP`,
    dateRange: {
      desc: 'Q2 2024',
      start: new Date('2024-04-01'),
      end: new Date(),
    },
    companyId: 'double-nines',
    role: 'Tech Lead',
    clientName: 'Paramount+',
    jobIds: ['double-nines-2'],
    url: 'https:/www.pplusmovienights.com',
    imageUrl: 'pplus-movie-nights.jpg',
    imageSize: 'cover',
    description: `A landing page for the 2024 season of Paramount+ Movie Nights`,
    keywords: ['Typescript', 'React', 'mobx', 'CSS', 'Gatsby', 'frontend', 'leadership', 'UI/UX'],
    bulletPts: ['Built key interactive components from scratch: the carousel, animated accordions, trivia game.'],
  },
  {
    id: 'optimal',
    name: `It's Optimal IOT Platform`,
    companyId: 'double-nines',
    clientName: 'Optimal LLC',
    role: 'Tech Lead',
    jobIds: ['double-nines-2'],
    url: 'https://itsoptimal.com',
    imageUrl: 'optimal.png',
    imageAltText: '',
    imageSize: 'cover',
    description:
      'React Native client and internet-of-things platform for a line of smart tankless water heaters. (Currently in closed beta.)',
    keywords: [
      'Typescript',
      'Javascript',
      'React',
      'React Native',
      'mobx',
      'architecture',
      'UI/UX',
      'frontend',
      'backend',
      'API design',
      'AWS',
      'leadership',
    ],
    dateRange: {
      desc: 'Oct 2023 - May 2024',
      start: new Date('2023-10-01'),
      end: new Date('2024-06-01'),
    },
    bulletPts: [
      'Built a React Native client app, backend-for-frontend, and simulators for hardware and backend components when unavailable.',
      'Designed and helped implement data flow architectures for hardware, BFF, and client app.',
      'Designed and/or implemented platform security architecture, including user and hardware authentication flows.',
      'Collaborated with design, product, and hardware teams on MVP-level product requirements.',
    ],
  },
  {
    id: 'hollywood',
    name: 'Unreleased Video Editor',
    companyId: 'articulate',
    role: 'Front-End Architect',
    jobIds: ['articulate'],
    imageUrl: 'hollywood-figma-blurred.jpg',
    imageAltText: '',
    imageSize: 'cover',
    description: 'A browser-based video editor. The project was not released.',
    keywords: [
      'Typescript',
      'Javascript',
      'React',
      'mobx',
      'architecture',
      'UI/UX',
      'frontend',
      'SVG',
      'HTMLMediaElement',
      'API design',
      'AWS',
      'HLS video',
    ],
    dateRange: {
      desc: '2021-2023',
      start: new Date('2021-11-01'),
      end: new Date('2023-05-01'),
    },
    bulletPts: [
      'Led front-end architecture on an internal React/Typescript video recording/editing app with a team of 5-10 developers.',
      'Developed an MVC application architecture using a reactive async observer pattern, enabling rapid iteration in UX development and data persistence.',
      'Advocated for and modeled best practices in code patterns, leading to improved performance, easier maintainability, and better developer and user experiences.',
      'Built visual debugging harnesses for hard-to-debug timeline interactions, enabling faster time to production on new features.',
      'Collaborated with design and UX to adapt known design patterns the NLE media editing application space (eg, video and audio editors) to our use case.',
      'Trained team members on datamodel structures and algorithms via in-code commenting, diagrams, video explainers, and wiki entries.',
    ],
  },
  {
    id: 'coop',
    name: 'COOP By Ryder',
    companyId: 'cpb',
    clientName: 'Ryder',
    role: 'Principal Architect',
    jobIds: ['cpb', 'double-nines-1'],
    url: 'https://coop.com',
    imageUrl: 'coop-app-screen.png',
    imageAltText: '',
    imageSize: 'contain',
    description: 'A commercial truck-sharing platform; an “AirBnB for logistics”.',
    keywords: [
      'Typescript',
      'Javascript',
      'React',
      'React Native',
      'Node',
      'architecture',
      'full-stack',
      'mobx',
      'API design',
      'UI/UX',
      'frontend',
      'backend',
      'AWS',
      'leadership',
      'CSS',
      'HTML',
      'MongoDB',
      'NoSQL',
      'Stripe',
    ],
    dateRange: {
      desc: '2017-2021',
      start: new Date('2017-09-01'),
      end: new Date('2021-10-31'),
    },
    bulletPts: [
      'Directed a small international team building a React/React Native + Node/MongoDB platform in Typescript and Javascript. We scaled the business from a few hundred customers into a rapidly-growing multi-state enterprise during my tenure.',
      'Led front-end architecture for the React Native mobile app on iOS and Android. Set up and managed delivery to app stores.',
      'Architected FRP-inspired back-end design patterns to guarantee data coherency, a performant API, and easier maintainability over complex calculation pipelines.',
      'Spearheaded efforts to re-use components and code across codebases, using both Storybook for visual components and a shared library of business logic.',
      'Collaborated with leadership, UX, and design on feature development, enabling rapid iteration and feature vetting.',
      'Mentored junior developers, co-crafted practices to support team growth.',
    ],
  },
  {
    id: 'pro-tools',
    name: 'Pro Tools',
    companyId: 'digidesign',
    role: 'Software Engineer',
    jobIds: ['digidesign'],
    url: 'http://protools.com',
    imageUrl: 'pt6.png',
    imageAltText: '',
    imageSize: 'cover',
    description:
      "I did the first-pass port of the industry-standard audio software Pro Tools to Mac OS X. Helped re-architect PT's application-level event loop. Minor feature development.",
    keywords: ['Carbon', 'C++', 'MacApp', 'Cocoa', 'Win32', 'RS232 serial protocol', 'macOS'],
    dateRange: {
      desc: '2000-2003',
      start: new Date('2000-05-01'),
      end: new Date('2003-08-01'),
    },
    bulletPts: [
      'First-pass port of ProTools to Mac OSX (C, C++, MacApp, Carbon), laying the foundation for the next decade of releases.',
      'Helped re-architect the application-level event system, modernizing for the (then) new world of preemptive multitasking. (C, C++, Carbon, Win32)',
    ],
  },
  {
    id: '3trace',
    name: '3Trace',
    companyId: 'patternleaf',
    imageUrl: '3trace.png',
    imageAltText: '',
    imageSize: 'cover',
    keywords: ['C++', 'OpenGL', 'Cocoa', 'HTML', 'YAML', 'macOS', 'UI/UX'],
    description: 'An exploration of the structure of information.',
    dateRange: {
      desc: '2004',
      fuzziness: 0.5,
      start: new Date('2006-01-01'),
      end: new Date('2007-08-01'),
    },
    bulletPts: [
      "Part of my master's thesis for CU Denver's M.Arch program.",
      "3Trace generated dynamic three-dimensional free-floating 'towers' from information harvested in real-time from Wikipedia, Flickr, and other online sources.",
    ],
  },
  {
    id: 'siftrunner',
    name: 'Sifteo Siftrunner',
    jobIds: ['sifteo'],
    companyId: 'sifteo',
    role: 'Software Engineer',
    imageUrl: 'siftrunner.png',
    imageAltText: '',
    imageSize: 'contain',
    description: "Sifteo's original desktop client. C++/Qt-powered desktop application with a WebKit front-end.",
    dateRange: {
      desc: '2010-2012',
      start: new Date('2010-10-01'),
      end: new Date('2012-04-01'),
    },
    keywords: ['C++', 'QT', 'HTML5', 'CSS', 'Javascript'],
    bulletPts: ['Built features for the web and desktop aspects of Sifteo’s game system'],
  },
  {
    id: 'squashable',
    name: 'Squashable',
    companyId: 'gravy-plane',
    role: 'CTO',
    imageUrl: 'squashable.png',
    imageAltText: '',
    imageSize: 'contain',
    description: 'A quirky, fun iOS novelty app.',
    dateRange: {
      desc: '2015',
      fuzziness: 0.25,
      start: new Date('2015-01-01'),
      end: new Date('2015-12-31'),
    },
    keywords: ['iOS', 'C++', 'Cinder', 'OpenGL', 'GLSL', 'ObjC', 'OpenCV'],
  },
  {
    id: 'lantern',
    name: 'Lantern',
    companyId: 'patternleaf',
    url: 'https://github.com/patternleaf/lantern',
    description: 'An audio-reactive 336-LED installation, remote controllable from iOS.',
    imageUrl: 'lantern-gif.gif',
    imageAltText: 'An animated gif of the Lantern project running.',
    imageSize: 'cover',
    keywords: [
      'fadecandy',
      'C++',
      'ObjC',
      'WS2812',
      'audio feature extraction',
      'Swift',
      'Raspberry Pi',
      'websockets',
      'interactive installation',
      'iOS',
    ],
    dateRange: {
      desc: '2017',
      fuzziness: 0.25,
      start: new Date('2016'),
      end: new Date('2017'),
    },
  },
  {
    id: 'map-the-meal-gap',
    name: 'Map the Meal Gap',
    url: 'https://map.feedingamerica.org/',
    role: 'Full-Stack Software Engineer',
    jobIds: ['wonder-giant'],
    companyId: 'wonder-giant',
    clientName: 'Feeding America',
    description: 'Interactive cloropleth of food insecurity in the US.',
    imageUrl: 'map-the-meal-gap.png',
    imageAltText: '',
    imageSize: 'contain',
    keywords: [
      'Ember',
      'Javascript',
      'HTML5',
      'GeoJSON',
      'TopoJSON',
      'SVG',
      'PHP',
      'API design',
      'architecture',
      'frontend',
      'backend',
      'full-stack',
    ],
    dateRange: {
      desc: '2015-2016',
      start: new Date('2015-02-01'),
      end: new Date('2016-02-01'),
      fuzziness: 0.25,
    },
  },
  {
    id: 'whmdc',
    name: "Nike Women's Half Marathon DC + SF, 2014",
    companyId: 'school',
    role: 'Software Engineer',
    clientName: 'Nike',
    description:
      "RFID-powered personalized animated messages on giant screens for the Nike Women's Half Marathon in DC and SF.",
    imageUrl: 'wall-pano.jpg',
    imageAltText: '',
    imageSize: 'cover',
    keywords: ['Cinder', 'C++', 'GLSL', 'ChronoTrack', 'TCP', 'OpenGL', 'interactive installation', 'macOS'],
    dateRange: {
      desc: '2014',
      start: new Date('2014-02-01'),
      end: new Date('2014-11-01'),
    },
  },
  {
    id: 'floodlight',
    name: 'The Floodlight Project',
    companyId: 'piton',
    role: 'Front-End Developer',
    url: 'https://philanthropynewsdigest.org/features/on-the-web/floodlight-project',
    description: 'A citizen journalism/story-building application.',
    imageUrl: 'floodlight.png',
    imageAltText: '',
    imageSize: 'cover',
    keywords: ['Javascript', 'Backbone', 'Python', 'Django', 'HTML5', 'CSS', 'frontend'],
    dateRange: {
      start: new Date('2012-09-01'),
      end: new Date('2013-08-01'),
      // fuzziness: 0.25,
    },
    bulletPts: ['Played a supporting role in feature development and UX design.'],
  },
  {
    id: 'the-sitting-room',
    name: 'The Sitting Room',
    companyId: 'patternleaf',
    clientName: 'Rayann Gordon',
    description: 'Wood, reptile skin, light, water, and sound envelop participants in the process of body-mind.',
    imageUrl: 'chair.jpg',
    imageAltText: '',
    imageSize: 'cover',
    keywords: ['Cinder', 'C++', 'Kinect', 'OpenGL', 'PWM LEDs', 'Arduino', 'macOS', 'interactive installation'],
    url: 'https://vimeo.com/51389234',
    dateRange: {
      desc: '2011',
      fuzziness: 0.25,
      start: new Date('2011-01-01'),
      end: new Date('2011-10-01'),
    },
  },
  {
    id: 'tempolab',
    name: 'tempolab',
    imageUrl: 'tempolab.png',
    imageAltText: '',
    imageSize: 'cover',
    companyId: 'patternleaf',
    dateRange: {
      desc: '2012-ish',
      fuzziness: 0.25,
      start: new Date('2012-01-01'),
      end: new Date('2012-08-01'),
    },
    description: 'An Apogaea 2012 art grant winner.',
    url: 'https://vimeo.com/61766877',
    keywords: ['Cinder', 'C++', 'MIDI protocol', 'aligned video projection', 'macOS', 'interactive installation'],
    bulletPts: [
      "Tempolab allowed visitors to create light and sound by their presence, and by interacting with 'instruments' housing wireless accelerometers.",
      'Used Sifteo cubes, Ableton Live, a Kinect, and aligned video projection inside a giant metal structure.',
    ],
  },
];
