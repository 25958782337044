import { useContext, useEffect, useRef, useState } from 'react';
import { motion, useAnimate } from 'framer-motion';
import { ProjectList } from './ProjectList';
import { StoreContext } from '../../../store';
import { observer } from 'mobx-react-lite';
import { TimelineView } from './TimelineView';
import { JobList } from './JobList';
import { Link, useLocation } from 'react-router-dom';
import { HistoryViewContext } from './context';
import { useMeasure } from '@uidotdev/usehooks';
import { getTimelineLayout } from './utils';
import classNames from 'classnames';

import { Animator } from './Animator';
import { outCubic } from '../../../utils';

export const HistoryView = observer(() => {
  const { background: backgroundStore, history: historyStore, ui: uiStore } = useContext(StoreContext);
  const [scope, animate] = useAnimate();
  const [containerMeasureRef, { width: containerWidth }] = useMeasure();

  const isJobsRoute = uiStore.isJobsRoute;
  const isProjectsRoute = uiStore.isProjectsRoute;
  const isRootRoute = uiStore.isRootRoute;
  const mode = uiStore.hvMode;
  const hasOpenItem = !!historyStore.selectedJobId || !!historyStore.selectedProjectId;

  const cameraAnimator = useRef<Animator | undefined>();

  const [minimumItemHeight, setMinimumItemHeight] = useState(0);

  const timelineLayout = getTimelineLayout({
    minimumItemHeight: Math.max(500, minimumItemHeight),
    visibleJobs: historyStore.visibleJobs,
    visibleProjects: historyStore.visibleProjects,
  });

  useEffect(() => {
    if (containerWidth && mode) {
      uiStore.hvLayout.setContainerWidth(containerWidth);
      uiStore.hvLayout.setMode(mode);
    }
  }, [uiStore, containerWidth, mode]);

  useEffect(() => {
    if (cameraAnimator.current === undefined) {
      cameraAnimator.current = new Animator({
        initial: {
          xPosProgress: backgroundStore.cameraTrack.xPosProgress,
          fovProgress: backgroundStore.cameraTrack.fovProgress,
        },
        onTick: v => {
          backgroundStore.setCameraTrackXPosProgress(v.xPosProgress);
          backgroundStore.setCameraTrackFovProgress(v.fovProgress);
        },
        easeFn: outCubic,
      });
    }
    if (mode === 'all') {
      cameraAnimator.current.setNext({
        xPosProgress: 0.5,
        fovProgress: 0.3,
      });
    } else if (mode === 'jobs') {
      cameraAnimator.current.setNext({
        xPosProgress: 0,
        fovProgress: 0,
      });
    } else if (mode === 'projects') {
      cameraAnimator.current.setNext({
        xPosProgress: 1,
        fovProgress: 0,
      });
    }
  }, [mode, backgroundStore]);

  const layout = uiStore.hvLayout;
  return (
    <div
      className={classNames('history-view-container', { initialized: layout.isInitialized })}
      ref={containerMeasureRef}>
      <HistoryViewContext.Provider value={{ timelineLayout, listItemWidth: layout.projectsListWidth ?? 0 }}>
        <motion.div
          className={classNames('history-view', { jobs: isJobsRoute, projects: isProjectsRoute })}
          style={{
            height: `${timelineLayout.height}px`,
          }}
          ref={scope.current}>
          <motion.div
            className="job-list"
            animate={{ opacity: isJobsRoute || isRootRoute ? 1 : 0.2 }}
            style={{
              height: `${timelineLayout.height}px`,
              width: `${layout.jobsListWidth}px`,
              transform: `translate(${layout.jobsListX}px, 0)`,
            }}>
            <JobList
              listWidth={layout.jobsListWidth}
              onMinimumItemHeightChanged={height => {
                if (height < minimumItemHeight) setMinimumItemHeight(height);
              }}
              layouts={timelineLayout.jobs}
            />
          </motion.div>
          <div
            className="timeline-view"
            style={{ transform: `translate(${layout.timelineX}px, 0px)`, width: `${layout.timelineWidth}px` }}>
            <TimelineView
              yearTicks={timelineLayout.yearTicks}
              timelineHeight={timelineLayout.height}
              timelineRange={timelineLayout.timelineRange}
            />
          </div>
          <motion.div
            className="project-list"
            animate={{ opacity: isProjectsRoute || isRootRoute ? 1 : 0.2 }}
            style={{
              height: `${timelineLayout.height}px`,
              width: `${layout.projectsListWidth}px`,
              transform: `translate(${layout.projectsListX}px, 0)`,
            }}>
            <ProjectList
              listWidth={layout.projectsListWidth}
              onMinimumItemHeightChanged={height => {
                if (height < minimumItemHeight) setMinimumItemHeight(height);
              }}
              layouts={timelineLayout.projects}
            />
          </motion.div>
        </motion.div>
      </HistoryViewContext.Provider>
    </div>
  );
});
