import { useEffect, useState, useContext } from 'react';
import { Group, Mesh, MeshStandardMaterial, DoubleSide, RepeatWrapping, SRGBColorSpace, Texture } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { Triplet } from '../../../../types';
import { BackgroundStore } from '../../../../store/BackgroundStore';
import { StoreContext } from '../../../../store';

export type GrlRockType =
  | 'Boulder Medium - Variant 1'
  | 'Boulder Medium - Variant 2'
  | 'Boulder Small - Variant 1'
  | 'Boulder Small - Variant 2'
  | 'Boulder Group';

const getTextures = async (store: BackgroundStore) => {
  return Promise.all([
    store.loadAsset<Texture>(`/grasslands/common/boulder-albedo.jpg`),
    store.loadAsset<Texture>(`/grasslands/common/boulder-normal.jpg`),
    store.loadAsset<Texture>(`/grasslands/common/boulder-occlusion.jpg`),
    store.loadAsset<Texture>(`/grasslands/common/boulder-smoothness.jpg`),
  ]);
};

export const GrlRock = ({
  type,
  position = [0, 0, 0],
  scale = [1, 1, 1],
  rotation = [0, 0, 0],
}: {
  type: GrlRockType;
  position?: Triplet;
  scale?: Triplet;
  rotation?: Triplet;
}) => {
  const [group, setGroup] = useState<Group>();
  const { background: store } = useContext(StoreContext);

  useEffect(() => {
    getTextures(store).then(([albedo, normal, ao, smoothness]) => {
      [albedo, normal, ao, smoothness].forEach(texture => {
        texture.colorSpace = SRGBColorSpace;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        texture.flipY = true;
      });
      store.loadAsset<GLTF>(`/grasslands/${type}/${type}.glb`).then(gltf => {
        const group = gltf.scene as Group;
        const mesh = group.children[0] as Mesh;
        const material = new MeshStandardMaterial({
          map: albedo,
          normalMap: normal,
          roughnessMap: smoothness,
          aoMap: ao,
          side: DoubleSide,
        });
        mesh.material = material;
        mesh.castShadow = true;
        group.castShadow = true;
        mesh.receiveShadow = true;
        setGroup(group);
      });
    });
  }, []);

  return group ? <primitive object={group} position={position} scale={scale} rotation={rotation} /> : null;
};
