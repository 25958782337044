import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useContext, useState, useMemo, useCallback, useEffect, useRef, ReactNode } from 'react';
import { StoreContext } from '../../store';
import { observer } from 'mobx-react-lite';
import { NumberCounter } from './NumberCounter';
import { makeAutoObservable, runInAction } from 'mobx';

type Timer = ReturnType<typeof setTimeout>;

const STARTING_KEYWORDS = ['Typescript', 'React', 'React Native', 'C++'];

class KeywordParadeMgr {
  allKeywords: string[];
  selectedKeyword?: string;
  visibleKeywords: string[];
  visibleKeywordsTimer?: Timer;
  stepSelectedKeywordTimer?: Timer;
  nVisibleKeywords: number;

  constructor(allKeywords: string[], nVisibleKeywords?: number) {
    this.allKeywords = allKeywords;
    this.nVisibleKeywords = nVisibleKeywords ?? this.allKeywords.length;
    this.visibleKeywords = STARTING_KEYWORDS;
    this.selectedKeyword = STARTING_KEYWORDS[0];
    // this.stepSelectedKeywordTimer = setTimeout(this.selectKeyword.bind(this), 1000);
    this.visibleKeywordsTimer = setTimeout(this.stepVisibleKeywords.bind(this), 0);
    makeAutoObservable(this);
  }

  dispose() {
    if (this.stepSelectedKeywordTimer) {
      clearTimeout(this.stepSelectedKeywordTimer);
    }
    if (this.visibleKeywordsTimer) {
      clearTimeout(this.visibleKeywordsTimer);
    }
  }

  setNVisibleKeywords(n?: number) {
    this.nVisibleKeywords = n ?? this.allKeywords.length;
  }

  stepVisibleKeywords() {
    let result: string[] = [...this.visibleKeywords];
    let newKeyword = this.pickRandomKeyword(result);
    if (result.length === this.nVisibleKeywords) {
      result.shift();
      result.push(newKeyword);
    }
    while (result.length < Math.min(this.allKeywords.length - 1, this.nVisibleKeywords)) {
      result.push(this.pickRandomKeyword(result));
    }
    this.visibleKeywords = result;
    this.visibleKeywordsTimer = setTimeout(this.stepVisibleKeywords.bind(this), 2000);
    const selectedIdx = this.visibleKeywords.findIndex(k => this.selectedKeyword === k);
    if (selectedIdx < 0) {
      this.selectedKeyword = this.visibleKeywords[0];
    }
  }

  selectKeyword(keyword: string) {
    this.selectedKeyword = keyword;
  }

  // selectKeyword() {
  //   let idx = Math.floor(Math.random() * this.visibleKeywords.length);
  //   while (this.selectedKeyword && this.visibleKeywords[idx] === this.selectedKeyword) {
  //     idx = Math.floor(Math.random() * this.visibleKeywords.length);
  //   }
  //   runInAction(() => {
  //     this.selectedKeyword = this.visibleKeywords[idx];
  //     this.stepSelectedKeywordTimer = setTimeout(this.selectKeyword.bind(this), 3500);
  //   });
  // }

  private pickRandomKeyword(currentKeywords: string[]) {
    let pick = this.allKeywords[Math.floor(Math.random() * this.allKeywords.length)];
    while (currentKeywords.includes(pick)) {
      pick = this.allKeywords[Math.floor(Math.random() * this.allKeywords.length)];
    }
    return pick;
  }
}

export const KeywordParade = observer(({ prefix }: { prefix?: ReactNode }) => {
  const { history: historyStore } = useContext(StoreContext);
  const mgr = useRef<KeywordParadeMgr | undefined>();
  const [currentDuration, setCurrentDuration] = useState<Duration>({});

  const selectedKeyword = mgr.current?.selectedKeyword;
  const visibleKeywords = mgr.current?.visibleKeywords;

  useEffect(() => {
    if (selectedKeyword) {
      setCurrentDuration(historyStore.getDurationForKeywords([selectedKeyword]));
    }
  }, [selectedKeyword, historyStore]);

  useEffect(() => {
    mgr.current = new KeywordParadeMgr(historyStore.keywords, 25);
    return () => {
      mgr.current?.dispose();
    };
  }, []);

  const { months, years } = currentDuration;

  return (
    <div className="keyword-parade-container">
      {prefix}{' '}
      <div className="duration">
        {years ? (
          <NumberCounter start={0} target={years} duration={500} postfix={years === 1 ? ' year' : ' years'} />
        ) : null}{' '}
        {months ? (
          <NumberCounter start={0} target={months} duration={500} postfix={months === 1 ? ' month' : ' months'} />
        ) : null}{' '}
        of experience with
      </div>
      <div className="keyword-parade">
        {visibleKeywords?.map(keyword => (
          <motion.span
            layout
            key={keyword}
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -200 }}
            className={classNames('keyword', { highlighted: selectedKeyword === keyword })}
            onClick={() => {
              mgr.current?.selectKeyword(keyword);
            }}
            style={{ fontSize: `${1.5}rem` }}>
            {keyword}
          </motion.span>
        ))}
      </div>
    </div>
  );
});
