import { createContext } from 'react';
import { HvLayout1 } from './types';

export const HistoryViewContext = createContext<HvLayout1>({
  timelineLayout: {
    yearTicks: [],
    projects: [],
    jobs: [],
    height: 0,
    timelineRange: { start: new Date(), end: new Date() },
  },
  // viewWidth: 0,
  listItemWidth: 0,
});
