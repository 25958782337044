import { useContext } from 'react';
import { StoreContext } from '../../../store';
import { Job } from '../../../store/types';
import { HistoryItemView, HistoryItemSubclassProps } from './HistoryItemView';
import { assertIsDefined } from '../../../utils';

export const JobView = ({
  job,
  ...rest
}: {
  job: Job;
} & HistoryItemSubclassProps) => {
  const { history: historyStore } = useContext(StoreContext);
  const company = historyStore.companies.find(({ id }) => id === job.companyId);
  assertIsDefined(company);
  const itemDetail = company.location;
  return (
    <HistoryItemView
      item={job}
      itemName={company.name}
      itemUrl={company.url}
      moreLinkUrl={`/jobs/${job.id}`}
      itemDetail={itemDetail}
      {...rest}
    />
  );
};
