import {
  AnimationAction,
  AnimationMixer,
  InstancedMesh,
  Matrix4,
  Mesh,
  Vector3,
  WebGLProgramParametersWithUniforms,
} from 'three';
import { Triplet } from '../../../../types';

export enum ETreeAnimationMode {
  cpuMorph,
  gpuCustom,
}

export enum ETreeColoringMode {
  realistic,
  stylizedDark, // TODO
  stylizedLight, // TODO
}

export type LeafInstance = {
  positionMatrix: Matrix4;
  meshPosition: Vector3;
  leafinessTransform: Matrix4;
  morphTargets: Vector3[];
  noiseOffset: Triplet;
};

export type LeafWorkerSetupRequest = {
  topic: 'setup';
  data: {
    workerNum: number;
    leafInstances: LeafInstance[];
    sharedMatrices: SharedArrayBuffer;
    noiseSrc?: Float32Array;
  };
};

export type LeafWorkerFrameRequest = {
  topic: 'frame';
  data: {
    startIndex: number;
    endIndex: number;
    windIntensity: number;
    morphTargetInfluences: number[];
    elapsedTime: number;
  };
};

export const isFrameRequest = (value: LeafWorkerRequest): value is LeafWorkerFrameRequest => {
  return value.topic === 'frame';
};
export const isSetupRequest = (value: LeafWorkerRequest): value is LeafWorkerSetupRequest => {
  return value.topic === 'setup';
};

export type LeafWorkerRequest = LeafWorkerFrameRequest | LeafWorkerSetupRequest;

export type LeafWorkerFrameResponse = {
  topic: 'frame';
  data: {
    workerNum: number;
    startIndex: number;
    endIndex: number;
  };
};

export const isFrameResponse = (value: LeafWorkerFrameResponse): value is LeafWorkerFrameResponse => {
  return value.topic === 'frame';
};

export type GetNoise2d = (x: number, y: number) => number;
// export type GetNoise3d = (x: number, y: number, z: number) => number;

export type WorkerConfig = {
  worker: Worker;
  startIndex: number;
  endIndex: number;
  isUpToDate: boolean;
};

export type TreeThings = {
  limbsMesh: Mesh;
  leavesMesh: InstancedMesh;
  onFrame: (params: { elapsedTime: number; windIntensity: number }) => void;
  dispose: () => void;
  getLimbsShader: () => WebGLProgramParametersWithUniforms | undefined;
  leafInstances: LeafInstance[];
  getLeavesShader: () => WebGLProgramParametersWithUniforms | undefined;
  limbsAnimationMixer?: AnimationMixer;
  limbsAnimationAction?: AnimationAction;
};
