import { useEffect, useState, useContext } from 'react';
import { Group, Mesh, MeshStandardMaterial, DoubleSide, RepeatWrapping, SRGBColorSpace, Texture } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { Triplet } from '../../../../types';
import { StoreContext } from '../../../../store';
import { BackgroundStore } from '../../../../store/BackgroundStore';

export type MLRockType = 'Large 1' | 'Large 2' | 'Med 1' | 'Med 2' | 'Small 1' | 'Small 2' | 'Small 3';

const getTextures = async (type: MLRockType, store: BackgroundStore) => {
  return Promise.all([
    store.loadAsset<Texture>(`/mountain-lake/rocks/MT_Rocks_diffuse_lofi.jpg`),
    store.loadAsset<Texture>(`/mountain-lake/rocks/MT_Rocks_normal_lofi.jpg`),
  ]);
};
const getGlbFilename = (type: MLRockType) => {
  switch (type) {
    case 'Large 1':
      return 'MT_Rock_Large_01_LOD2.glb';
    case 'Large 2':
      return 'MT_Rock_Large_02_LOD1.glb';
    case 'Med 1':
      return 'MT_Rock_Med_01_LOD2.glb';
    case 'Med 2':
      return 'MT_Rock_Med_02_LOD1.glb';
    case 'Small 1':
      return 'MT_Rock_Small_01_LOD0.glb';
    case 'Small 2':
      return 'MT_Rock_Small_02_LOD0.glb';
    case 'Small 3':
      return 'MT_Rock_Small_03_LOD0.glb';
  }
};

export const MLRock = ({
  type,
  position = [0, 0, 0],
  scale = [1, 1, 1],
  rotation = [0, 0, 0],
}: {
  type: MLRockType;
  position?: Triplet;
  scale?: Triplet;
  rotation?: Triplet;
}) => {
  const [group, setGroup] = useState<Group>();
  const { background: store } = useContext(StoreContext);

  useEffect(() => {
    getTextures(type, store).then(([albedo, normal]) => {
      [albedo, normal].forEach(texture => {
        texture.colorSpace = SRGBColorSpace;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        // texture.flipY = true;
      });
      store.loadAsset<GLTF>(`/mountain-lake/rocks/${getGlbFilename(type)}`).then(gltf => {
        // console.log({ gltf });
        const group = gltf.scene as Group;
        const mesh = group.children[0] as Mesh;

        const uvs = mesh.geometry.getAttribute('uv');
        for (let i = 0; i < uvs.count; i++) {
          uvs.setY(i, uvs.getY(i) * -1);
        }

        const material = new MeshStandardMaterial({
          map: albedo,
          normalMap: normal,
          side: DoubleSide,
        });
        mesh.material = material;
        mesh.castShadow = true;
        group.castShadow = true;
        mesh.receiveShadow = true;
        setGroup(group);
      });
    });
  }, []);

  return group ? <primitive object={group} position={position} scale={scale} rotation={rotation} /> : null;
};
