import { makeAutoObservable } from 'mobx';
import { HvLayout } from './HvLayout';

export enum ESiteMode {
  bgOnly = 'bg-only',
  portfolioOnly = 'portfolio-only',
  both = 'both',
}

export const siteModeToName = (mode: ESiteMode) => {
  switch (mode) {
    case ESiteMode.bgOnly:
      return 'Background Only';
    case ESiteMode.portfolioOnly:
      return 'Portfolio Only';
    case ESiteMode.both:
      return 'Both';
  }
};

export interface IUiState {
  siteMode: ESiteMode;
}

export type UiJson = IUiState;

const defaultUiState: IUiState = {
  siteMode: ESiteMode.both,
};

export type HvMode = 'jobs' | 'projects' | 'all';

/**
 * For things that need to span the divide between react state and Background state.
 */
export class UiStore implements IUiState {
  windowHeight: number;
  siteMode: ESiteMode;
  headerScrollProgress: number;
  locationPath: string;
  hvLayout: HvLayout;

  constructor(state?: IUiState) {
    this.windowHeight = window.outerHeight;
    this.siteMode = state?.siteMode ?? defaultUiState.siteMode;
    this.headerScrollProgress = 0;
    this.locationPath = window.location.pathname;
    this.hvLayout = new HvLayout({ mode: this.hvMode, containerWidth: 0 });
    makeAutoObservable(this);
  }

  setWindowHeight(height: number) {
    this.windowHeight = height;
  }

  setLocationPath(path: string) {
    this.locationPath = path;
  }

  get isJobsRoute() {
    return this.locationPath.includes('jobs');
  }

  get isProjectsRoute() {
    return this.locationPath.includes('projects');
  }

  get isRootRoute() {
    return !this.isJobsRoute && !this.isProjectsRoute;
  }

  get hvMode(): HvMode {
    return this.isJobsRoute ? 'jobs' : this.isProjectsRoute ? 'projects' : 'all';
  }

  setHeaderScrollProgress(progress: number) {
    this.headerScrollProgress = progress;
  }

  get headerScrollIsComplete() {
    return this.headerScrollProgress >= 1;
  }

  setMode(mode: ESiteMode) {
    this.siteMode = mode;
  }

  static fromJson(state: UiJson) {
    return new UiStore(state);
  }

  reset() {
    this.siteMode = defaultUiState.siteMode;
  }

  toJSON(): UiJson {
    return {
      siteMode: this.siteMode,
    };
  }
}
