// copied/modified from https://gist.github.com/gre/1650294

/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 */

// no easing, no acceleration
export const linear = (t: number) => {
  return t;
};
// accelerating from zero velocity
export const inQuad = (t: number) => {
  return t * t;
};
// decelerating to zero velocity
export const outQuad = (t: number) => {
  return t * (2 - t);
};
// acceleration until halfway, then deceleration
export const inOutQuad = (t: number) => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};
// accelerating from zero velocity
export const inCubic = (t: number) => {
  return t * t * t;
};
// decelerating to zero velocity
export const outCubic = (t: number) => {
  return --t * t * t + 1;
};
// acceleration until halfway, then deceleration
export const inOutCubic = (t: number) => {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
};
// accelerating from zero velocity
export const inQuart = (t: number) => {
  return t * t * t * t;
};
// decelerating to zero velocity
export const outQuart = (t: number) => {
  return 1 - --t * t * t * t;
};
// acceleration until halfway, then deceleration
export const inOutQuart = (t: number) => {
  return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
};
// accelerating from zero velocity
export const inQuint = (t: number) => {
  return t * t * t * t * t;
};
// decelerating to zero velocity
export const outQuint = (t: number) => {
  return 1 + --t * t * t * t * t;
};
// acceleration until halfway, then deceleration
export const inOutQuint = (t: number) => {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
};
