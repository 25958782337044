import { useFrame } from '@react-three/fiber';
import { useEffect, useState, useContext } from 'react';
import { Group, Color, MeshStandardMaterial } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { Triplet } from '../../../../types';
import { objIsMesh } from '../utils';
import { createTree } from './tree-utils';
import { TreeThings, ETreeAnimationMode, ETreeColoringMode } from './tree-types';
import { StoreContext } from '../../../../store';
import { observer } from 'mobx-react-lite';
import { ESiteMode } from '../../../../store/UiStore';

export const GroveTree = observer(
  ({
    path = '/trees/aspen-v2-2.glb',
    // animationMode = ETreeAnimationMode.cpuMorph,
    animationMode = ETreeAnimationMode.gpuCustom,
    coloringMode = ETreeColoringMode.realistic,
    position = [0, 0, 0],
    rotation = [0, 0, 0],
    scale = [1, 1, 1],
  }: {
    path?: string;
    animationMode?: ETreeAnimationMode;
    coloringMode?: ETreeColoringMode;
    position?: Triplet;
    rotation?: Triplet;
    scale?: Triplet;
  }) => {
    const { background: backgroundStore, ui: uiStore } = useContext(StoreContext);
    const [treeThings, setTreeThings] = useState<TreeThings | undefined>();
    const [roots, setRoots] = useState<Group>();
    const windIntensity = backgroundStore.wind.intensity;

    useEffect(() => {
      (async () => {
        // setTreeThings(await createTree({ path: 'trees/aspen-v2-thickness-attr.glb', mode }));
        setTreeThings(await createTree({ path, animationMode, coloringMode, store: backgroundStore }));
      })();
    }, [path, animationMode, coloringMode, backgroundStore]);

    useEffect(() => {
      backgroundStore.loadAsset<GLTF>('/trees/roots 1.glb').then(obj => {
        obj.scene.traverse(object => {
          if (objIsMesh(object)) {
            object.material = new MeshStandardMaterial({ color: new Color(0.9, 0.8, 0.8) });
          }
        });
        setRoots(obj.scene);
      });
    }, []);

    useFrame((state, delta) => {
      if (treeThings && uiStore.siteMode !== ESiteMode.portfolioOnly) {
        const { onFrame, limbsAnimationMixer, limbsAnimationAction } = treeThings;
        if (limbsAnimationMixer && limbsAnimationAction) {
          if (!limbsAnimationAction.isRunning()) {
            limbsAnimationAction.play();
          }
          limbsAnimationAction.weight = windIntensity;
          limbsAnimationMixer.update(delta);
        }
        onFrame({ windIntensity, elapsedTime: state.clock.getElapsedTime() });
      }
    });

    return (
      <group position={position} rotation={rotation} scale={scale}>
        {treeThings?.limbsMesh && <primitive object={treeThings.limbsMesh} />}
        {treeThings?.leavesMesh && <primitive object={treeThings.leavesMesh} />}
        {roots && <primitive object={roots} position={[0, -0.4, 0]} />}
      </group>
    );
  },
);
