import { InstancedMesh, Mesh, Matrix4 } from 'three';
import { LeafInstance, WorkerConfig, LeafWorkerFrameResponse, isFrameResponse } from './tree-types';
import { BYTES_PER_MATRIX, calculateLeafInstanceMatrices, setMatrixFromBuffer } from './tree-utils';
import { N_LEAF_WORKERS } from './constants';

const ROUND_ROBIN_UPDATES = false;

export class LeafWorkerMgr {
  leavesMesh: InstancedMesh;
  limbsMesh: Mesh;
  instances: LeafInstance[];
  sharedMatrices: SharedArrayBuffer;
  workerConfigs: WorkerConfig[];
  workerUpdateRoundRobin: number;

  constructor({
    leavesMesh,
    limbsMesh,
    instances,
    workerConfigs,
  }: {
    leavesMesh: InstancedMesh;
    limbsMesh: Mesh;
    instances: LeafInstance[];
    workerConfigs: WorkerConfig[];
  }) {
    this.leavesMesh = leavesMesh;
    this.limbsMesh = limbsMesh;
    this.instances = instances;
    this.sharedMatrices = new SharedArrayBuffer(instances.length * BYTES_PER_MATRIX);
    this.workerConfigs = workerConfigs;
    this.workerUpdateRoundRobin = 0;

    this.setupWorkers();
  }

  dispose() {
    this.workerConfigs.forEach(config => {
      config.worker.terminate();
    });
  }

  private setupWorkers() {
    if (N_LEAF_WORKERS > 0) {
      const leavesPerWorker = Math.floor(this.instances.length / N_LEAF_WORKERS);
      for (let i = 0, startIndex = 0; i < N_LEAF_WORKERS; i++, startIndex += leavesPerWorker) {
        const worker = new Worker(new URL('./leaf-worker.ts', import.meta.url));
        const workerConfig: WorkerConfig = {
          worker,
          startIndex,
          endIndex: startIndex + leavesPerWorker,
          isUpToDate: true,
        };
        worker.postMessage({
          topic: 'setup',
          data: {
            leafInstances: this.instances,
            sharedMatrices: this.sharedMatrices,
          },
        });
        worker.onmessage = (event: MessageEvent<LeafWorkerFrameResponse>) => {
          if (isFrameResponse(event.data)) {
            workerConfig.isUpToDate = true;
          }
        };
        this.workerConfigs.push(workerConfig);
      }
    }
  }

  calculateMatrices() {
    calculateLeafInstanceMatrices({
      instances: this.instances,
      windIntensity: 0,
      startIndex: 0,
      endIndex: this.instances.length,
      morphTargetInfluences: this.leavesMesh.morphTargetInfluences!,
      elapsedTime: 0,
      sharedMatrices: this.sharedMatrices,
    });
  }

  onFrame({ windIntensity, elapsedTime }: { windIntensity: number; elapsedTime: number }) {
    if (this.workerConfigs.length > 0) {
      // look for a worker who's up to date.
      // copy their work into the scene and send them a new frame to work on.

      if (ROUND_ROBIN_UPDATES) {
        // round-robin the eligible workers so only one worker's update goes through per frame.
        // this reduces the overall framerate for leaves but makes performance suck less.
        let eligibleWorkerIndex = (this.workerUpdateRoundRobin + 1) % N_LEAF_WORKERS;
        let nextWorkerConfig = this.workerConfigs[eligibleWorkerIndex];
        while (!nextWorkerConfig.isUpToDate && eligibleWorkerIndex !== this.workerUpdateRoundRobin) {
          eligibleWorkerIndex = (eligibleWorkerIndex + 1) % N_LEAF_WORKERS;
          nextWorkerConfig = this.workerConfigs[eligibleWorkerIndex];
        }
        if (eligibleWorkerIndex !== this.workerUpdateRoundRobin) {
          this.updateWorker(eligibleWorkerIndex, { windIntensity, elapsedTime });
          this.workerUpdateRoundRobin = eligibleWorkerIndex;
        }
      } else {
        this.workerConfigs.forEach((config, i) => {
          if (config.isUpToDate) {
            this.updateWorker(i, { windIntensity, elapsedTime });
          }
        });
      }
    }
  }

  private updateWorker(index: number, frame: { windIntensity: number; elapsedTime: number }) {
    const config = this.workerConfigs[index];
    const { windIntensity, elapsedTime } = frame;
    const matrix = new Matrix4();
    for (let i = config.startIndex; i < config.endIndex; i++) {
      setMatrixFromBuffer({ src: this.sharedMatrices, dest: matrix, i });
      this.leavesMesh.setMatrixAt(i, matrix);
    }
    this.leavesMesh.instanceMatrix.needsUpdate = true;

    config.worker.postMessage({
      topic: 'frame',
      data: {
        windIntensity,
        morphTargetInfluences: this.limbsMesh.morphTargetInfluences,
        elapsedTime,
        startIndex: config.startIndex,
        endIndex: config.endIndex,
      },
    });
    config.isUpToDate = false;
  }
}
