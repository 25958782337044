import { Canvas, useFrame } from '@react-three/fiber';
import { useEffect, useState, useRef, useMemo, useContext, createContext } from 'react';
import { PerformanceMonitor, PerspectiveCamera, SoftShadows } from '@react-three/drei';
import {
  MathUtils,
  Vector3,
  ACESFilmicToneMapping,
  DirectionalLight,
  PlaneGeometry,
  BoxGeometry,
  DirectionalLightHelper,
} from 'three';
import { createNoise2D } from 'simplex-noise';
import { clamp } from 'framer-motion';
import { SphericalPos } from '../../../types';
import { GroveTree } from './GroveTree';
import { Scattering, Sky } from './Sky/Sky';
import { Grass } from './Grass';
import {
  getGroundYPosition,
  positionOnGround,
  getSunColor,
  sphericalToCartesian,
  focalLengthToFov,
  fovToFocalLength,
} from './utils';
import { Grass2 } from './Grass2';
import { Ground } from './Ground';
import { slerp, inQuint, inQuad, outQuad } from '../../../utils';
import { observer } from 'mobx-react-lite';
import { DepthOfField, EffectComposer, LensFlare, SSAO } from '@react-three/postprocessing';
import { Underground } from './Underground';
import { GrlTree } from './grasslands/GrlTree';
import { Mountains } from './Mountains';
import { GrlRock } from './grasslands/GrlRock';
import { MLRock } from './mountain-lake/MLRock';
import { StoreContext, singleton } from '../../../store';
import { ESiteMode } from '../../../store/UiStore';
import { GrlBush } from './grasslands/GrlBush';

const noise = createNoise2D(Math.random);

const maxY = 20;
const minY = -0;
const maxRotX = Math.PI * 0.25;
const minRotX = 0;

const SUN_POSITION_DISTANCE = 100;

const WindGenerator = ({
  isManual,
  setWindIntensity,
}: {
  isManual: boolean;
  setWindIntensity: (intensity: number) => void;
}) => {
  const noiseOffset = useRef(Math.random());
  useFrame(() => {
    if (!isManual) {
      const now = Date.now();
      const noise1 = noise(now * 0.00001, now * 0.00003 + noiseOffset.current);
      const wave1 = Math.sin(now * 0.00005) * 0.9;
      const wave2 = Math.sin(now * 0.0001) * 0.2;
      const newWind = clamp(0, 1, Math.abs(wave1 + wave2 + noise1));
      setWindIntensity(newWind);
    }
  });
  return null;
};

export const Background = observer(() => {
  const { background: store, ui: uiStore } = useContext(StoreContext);
  const [dpr, setDpr] = useState(1.5);

  const dirLight = useRef<DirectionalLight>(null);

  return (
    <>
      <div className="background">
        <Canvas
          gl={{
            preserveDrawingBuffer: true,
            toneMapping: ACESFilmicToneMapping,
            toneMappingExposure: store.camera.exposure,
            antialias: true,
          }}
          frameloop={uiStore.siteMode === ESiteMode.portfolioOnly ? 'never' : 'always'}
          className="main-canvas"
          shadows={true}
          dpr={dpr}
          style={{ height: '100vh' }}>
          <PerformanceMonitor
            onIncline={() => setDpr(2)}
            onDecline={() => setDpr(1)}
            onChange={perf => {
              store.setPerformanceInstantFps(perf.fps);
              store.setPerformanceMeanFps(
                perf.averages.length > 0 ? perf.averages.reduce((acc, v) => v + acc, 0) / perf.averages.length : 0,
              );
            }}
          />
          <WindGenerator isManual={store.wind.isManual} setWindIntensity={w => store.setWindIntensity(w)} />
          <PerspectiveCamera
            makeDefault
            position={store.cameraPosition}
            rotation={store.cameraRotation}
            far={700000}
            fov={store.cameraFov}
          />
          <Sky sunSphericalPos={store.sunSphericalPosition} scattering={store.scattering} cloudDensity={1000} />
          <ambientLight args={[store.sunColor, store.ambientLightIntensity]} />
          <hemisphereLight args={[store.sunColor, 0x080820, store.hemisphericalLightIntensity]} />
          <directionalLight
            intensity={store.directionalLightIntensity}
            color={store.sunColor}
            position={store.sunCartesianPos}
            ref={dirLight}
            castShadow={true}
            shadow-camera-far={200}
            shadow-camera-left={-40}
            shadow-camera-right={40}
            shadow-camera-top={40}
            shadow-camera-bottom={-40}
            shadow-camera-near={1}
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
          />

          {/* <fogExp2 color={[0.3, 0.3, 0.4]} density={0.2} /> */}

          <GroveTree />

          <GrlBush type="Broadleaf Bush L1" position={positionOnGround(2, -7)} />
          <GrlBush type="Broadleaf Bush M1" position={positionOnGround(-9, -9)} />
          <GrlBush type="Broadleaf Bush M1" position={positionOnGround(-12, -11)} rotation={[0, Math.PI, 0]} />
          <GrlBush type="Broadleaf Bush M1" position={positionOnGround(-9, -12)} rotation={[0, Math.PI, 0]} />
          <GrlBush
            type="Broadleaf Bush M1"
            position={positionOnGround(-8, -7)}
            rotation={[0, Math.PI * 0.3, 0]}
            scale={[1.2, 1.3, 1.2]}
          />

          <GroveTree path="/trees/aspen-m-1-no-anim.glb" position={positionOnGround(-10, -10)} />
          <GroveTree path="/trees/aspen-m-2-no-anim.glb" position={positionOnGround(-11, -8)} />

          <GrlTree type="Birch L" position={positionOnGround(-20, -45)} />
          <GrlTree type="Birch L" position={positionOnGround(-10, -34)} rotation={[0, 0.5, 0]} />
          <GrlTree type="Birch L" position={positionOnGround(-18, -50)} rotation={[0, 0.5, 0]} />
          <GrlTree type="Birch S" position={positionOnGround(-10, -25)} />
          <GrlTree
            type="Birch S"
            position={positionOnGround(-20, -18)}
            scale={[1.6, 1.9, 1.6]}
            rotation={[0, Math.PI, 0]}
          />
          <Grass
            position={positionOnGround(-13, -15)}
            width={10}
            nInstances={800}
            getGroundYPosition={getGroundYPosition}
            bladeWidth={0.05}
            bladeHeight={1.3}
            joints={3}
          />

          <GrlTree type="Pine L" position={positionOnGround(8, -20)} rotation={[0, 0.3, 0]} />
          <GrlTree type="Pine M" position={positionOnGround(15, -17)} rotation={[0, 1.2, 0]} scale={[0.8, 0.9, 0.8]} />
          <GrlTree type="Pine L" position={positionOnGround(3, -30)} rotation={[0, Math.PI, 0]} />
          <GrlTree type="Pine L" position={positionOnGround(6, -40)} rotation={[0, -0.4, 0]} />
          <GrlTree type="Pine L" position={positionOnGround(8, -43)} rotation={[0, -0.9, 0]} />
          <GrlTree type="Pine M" position={positionOnGround(10, -12)} />
          <GrlTree type="Pine M" position={positionOnGround(11, -25)} rotation={[0, 1.3, 0]} />
          <GrlTree type="Pine M" position={positionOnGround(7, -46)} />
          <GrlTree type="Pine XL" position={positionOnGround(30, -40)} rotation={[0, 1.2, 0]} />

          <GroveTree path="/trees/aspen-l-2-no-anim.glb" position={positionOnGround(14, -8)} />

          <Ground />

          <GrlRock
            type="Boulder Medium - Variant 1"
            position={[-2.5, getGroundYPosition(-2.5, -8) - 1, -8]}
            scale={[7, 5, 4]}
          />
          <GrlRock
            type="Boulder Small - Variant 2"
            position={[8, getGroundYPosition(8, -7) - 0.5 - 0.1, -7]}
            scale={[10, 10, 10]}
          />

          <MLRock type="Large 1" position={[-65, 5, -60]} scale={[10, 10, 10]} />
          <MLRock type="Large 2" position={[75, 10, -50]} scale={[8, 8, 8]} rotation={[0, Math.PI * 1.1, 0]} />
          <MLRock type="Med 1" position={[-78, 17, -60]} scale={[10, 10, 10]} />
          <MLRock type="Small 1" position={positionOnGround(2.4, -3)} scale={[5, 5, 5]} />
          <MLRock type="Small 2" position={positionOnGround(-8, -5)} scale={[9, 9, 9]} />
          <MLRock type="Small 3" position={positionOnGround(-3, -3)} scale={[9, 9, 9]} />

          <MLRock type="Small 2" position={positionOnGround(-3, -50)} scale={[20, 20, 20]} />
          <MLRock type="Med 2" position={positionOnGround(27, -55)} scale={[5, 5, 5]} />

          <Grass
            position={[10, 0, -40]}
            width={30}
            nInstances={400}
            getGroundYPosition={getGroundYPosition}
            bladeWidth={0.05}
            bladeHeight={1.3}
            joints={3}
          />
          <Grass
            position={[-10, 0, -30]}
            width={30}
            nInstances={400}
            getGroundYPosition={getGroundYPosition}
            bladeWidth={0.05}
            bladeHeight={1.4}
            joints={4}
          />
          <Grass
            position={[0, 0, -8]}
            width={5}
            nInstances={400}
            getGroundYPosition={getGroundYPosition}
            bladeWidth={0.05}
            bladeHeight={1.3}
            joints={3}
          />
          <Grass
            position={[2, 0, 1]}
            width={5}
            nInstances={400}
            getGroundYPosition={getGroundYPosition}
            bladeWidth={0.05}
            bladeHeight={1.3}
            joints={3}
          />
          <Grass
            position={[15, 0, -10]}
            width={6}
            nInstances={600}
            getGroundYPosition={getGroundYPosition}
            bladeWidth={0.05}
            bladeHeight={1.3}
            joints={3}
          />
          <Grass
            position={[20, 0, -20]}
            width={6}
            nInstances={600}
            getGroundYPosition={getGroundYPosition}
            bladeWidth={0.05}
            bladeHeight={1.3}
            joints={5}
          />
          <Grass
            position={[10, 0, -10]}
            width={30}
            nInstances={400}
            getGroundYPosition={getGroundYPosition}
            bladeWidth={0.05}
            bladeHeight={1.4}
            joints={4}
          />
          <SoftShadows />
        </Canvas>
      </div>
    </>
  );
});
