import { useContext, useRef } from 'react';
import { motion, useMotionTemplate, useMotionValueEvent, useScroll, useTransform } from 'framer-motion';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { StoreContext } from '../../../store';
import { observer } from 'mobx-react-lite';
import { KeywordParade } from '../KeywordParade';
import { ESiteMode } from '../../../store/UiStore';
import { SiteModeSwitches } from '../SiteModeSwitches';
import { Wippy } from './Wippy';

export const Header = observer(() => {
  const { ui: uiStore } = useContext(StoreContext);
  const headerRef = useRef<HTMLElement | null>(null);

  const windowHeight = uiStore.windowHeight;

  const { scrollY, scrollYProgress } = useScroll({
    target: headerRef,
    offset: ['start start', 'end start'],
  });

  // const headerProgress = useTransform(() => {
  //   const offset = scrollY.get();
  //   const visibleHeader = windowHeight! - offset;
  //   const targetVisibleHeader = HEADER_MIN_HEIGHT;
  //   return Math.max(0, Math.min(1, (visibleHeader - windowHeight!) / (targetVisibleHeader - windowHeight!)));
  // });
  const headerProgress = scrollYProgress;

  const inverseHeaderProgress = useTransform(() => 1 - headerProgress.get());

  const headerBackground = useMotionTemplate`linear-gradient(180deg, rgba(2, 0, 36, .8) 0%, rgba(15, 15, 15, ${headerProgress}) 100%)`;

  // const headerBackground = `transparent`;

  const fixedHeaderBackground = useMotionTemplate`rgba(15, 15, 15, ${headerProgress})`;

  // const fixedHeaderBackground = `linear-gradient(180deg, rgba(15, 15, 15, 0.8) 0%, rgba(15, 15, 15, 0.8) 100%)`;

  useMotionValueEvent(scrollYProgress, 'change', latest => {
    uiStore.setHeaderScrollProgress(latest);
    // setScrollIsComplete(latest >= 1);
  });

  // useMotionValueEvent(headerProgress, 'change', latest => {
  //   console.log({
  //     p: headerProgress.get(),
  //     bg: `linear-gradient(180deg, rgba(2, 0, 36, ${headerProgress.get()}) 0%, rgba(15, 15, 15, ${Math.max(
  //       0,
  //       1 - headerProgress.get(),
  //     )}) 100%)`,
  //   });
  // });

  const isBgOnly = uiStore.siteMode === ESiteMode.bgOnly;
  const hvLayout = uiStore.hvLayout;

  const scrollIsComplete = uiStore.headerScrollIsComplete;

  return (
    <>
      <motion.header
        ref={headerRef}
        style={{
          background: headerBackground,
          display: isBgOnly ? 'none' : 'flex',
        }}>
        <div className="header-content-container">
          <div className="header-content" style={{ height: windowHeight }}>
            <div className="intro">
              <div className="greeting-image">
                <img src="eric.jpg" className="headshot" alt="The author" />
                {/* <img alt="patternleaf logo" src="/images/logo-temp.png" /> */}
              </div>
              <div className="bio">
                <h1 className="greeting-big">Howdy!</h1>
                <p className="desc">My name's Eric. I'm a software craftsman.</p>
                <p className="desc">
                  I enjoy building <strong>bullet-proof</strong>, <strong>media-rich</strong>, and{' '}
                  <strong>human-centered</strong> tools and experiences.
                </p>
              </div>
            </div>
            <p>I've been looking for work recently and while putting together my CV I discovered something...</p>
          </div>
          <div className="header-content" style={{ height: windowHeight }}>
            <h1 className="ive-built">
              <strong>I've built a lof of stuff!</strong>{' '}
            </h1>
            <KeywordParade
              prefix={
                <span className="keyword-parade-prefix">
                  Rough estimates here, but counting <strong>project-time</strong> x <strong>tech</strong>, I have about
                </span>
              }
            />

            <p>
              This site is my (<span className="wippy-wip-wip">work-in-progress!</span>) attempt to document some of it.
            </p>
            {/* <SiteModeSwitches showBgControls={false} orientation="vertical" /> */}
          </div>
        </div>
        <motion.div
          className="corner-wip"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transform: `scale(${windowHeight < 900 ? 0.6 : 1}) translate(0%, 300%) rotate(30deg)`,
          }}>
          WORK IN PROGRESS
        </motion.div>
      </motion.header>
      <motion.div
        className={classNames('stickyable-header', { sticky: scrollIsComplete || isBgOnly })}
        style={{ background: isBgOnly ? 'transparent' : fixedHeaderBackground }}>
        <div className="sticky-header-logo">
          <div className="sticky-header-lockup">
            <div className="sticky-header-logo-img">
              <img alt="patternleaf logo" src="/images/logo-temp.png" />
            </div>
            <h1 className="brand-name">patternleaf</h1>
            <Wippy />
          </div>
        </div>
        <div className="sticky-header-controls">
          <SiteModeSwitches orientation="horizontal" small />
        </div>
        <div
          className={classNames('sticky-header-menu', { initialized: hvLayout.isInitialized })}
          style={{ display: isBgOnly ? 'none' : 'grid' }}>
          <div className="menu-item">
            <Link to="/jobs">Jobs</Link>
          </div>
          <div className="menu-item">
            <Link to="/">All</Link>
          </div>
          <div className="menu-item">
            <Link to="/projects">Projects</Link>
          </div>
        </div>
      </motion.div>
    </>
  );
});
