import { useMemo, useRef, useEffect, useState, useContext } from 'react';
import { extend, ReactThreeFiber, useThree } from '@react-three/fiber';
import {
  BackSide,
  DoubleSide,
  BoxGeometry,
  ShaderMaterial,
  SRGBColorSpace,
  WebGLCubeRenderTarget,
  PlaneGeometry,
  Vector3,
  MeshStandardMaterialParameters,
  RepeatWrapping,
  Vector2,
  Texture,
} from 'three';
import { shaderMaterial } from '@react-three/drei';
import { getGroundYPosition, GROUND_RES, GROUND_WIDTH } from './utils';
import { StoreContext } from '../../../store';

const UndergroundMaterial = shaderMaterial(
  {
    cubeMapTex: null, // cubeTexture,
  },
  // vertex shader
  /*glsl*/ `
  varying vec2 vUv;
  varying vec3 vWorldPosition;
  void main() {
    vUv = uv;
    vec4 worldPosition = modelMatrix * vec4(position, 1.0);
    vWorldPosition = worldPosition.xyz;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    gl_Position.z = gl_Position.w; // set z to camera.far
  }`,
  // fragment shader
  /*glsl*/ `
  uniform float time;
  uniform samplerCube cubeMapTex;

  varying vec2 vUv;
  varying vec3 vWorldPosition;

  #define PI 3.141592654
  
  mat4 rotationMatrix(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;
    
    return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                0.0,                                0.0,                                0.0,                                1.0);
  }

  vec3 rotate(vec3 v, vec3 axis, float angle) {
    mat4 m = rotationMatrix(axis, angle);
    return (m * vec4(v, 1.0)).xyz;
  }
  
  void main() {
    vec3 direction = normalize(vWorldPosition - cameraPosition);
    vec3 cubeMapColor = textureCube(cubeMapTex, normalize(rotate(direction, vec3(0, 1, 0), PI * 0.9))).rgb;
    // gl_FragColor.rgba = vec4(0.5 + 0.3 * sin(vUv.yxx + time) + color, 1.0);
    
    gl_FragColor = vec4(cubeMapColor, 1.0);

    #include <tonemapping_fragment>
    #include <colorspace_fragment>

  }
`,
);

extend({ UndergroundMaterial });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      undergroundMaterial: ReactThreeFiber.MaterialNode<ShaderMaterial, typeof UndergroundMaterial>;
    }
  }
}

export const Ground = () => {
  const { gl } = useThree();
  const undergroundCubemapRef = useRef<ShaderMaterial | null>(null);
  const [groundMatProps, setGroundMatProps] = useState<Partial<MeshStandardMaterialParameters>>({});
  const { background: store } = useContext(StoreContext);

  useEffect(() => {
    const paths = [
      '/mountain-lake/moss/moss-diffuse-lofi.jpg',
      '/mountain-lake/moss/moss-normal-lofi.jpg',
      // '/underground-equi-1.png',
    ];
    (async () => {
      const [map, normalMap /* undergroundEnvMap */] = await Promise.all(
        paths.map(path => store.loadAsset<Texture>(path)),
      );
      [map, normalMap /* undergroundEnvMap */].forEach(texture => {
        texture.colorSpace = SRGBColorSpace;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        texture.repeat = new Vector2(GROUND_RES / 5, GROUND_RES / 5);
      });
      setGroundMatProps({
        map,
        normalMap,
      });
      // const rt = new WebGLCubeRenderTarget(undergroundEnvMap.image.height / 2, { colorSpace: SRGBColorSpace });
      // rt.fromEquirectangularTexture(gl, undergroundEnvMap);
      // if (undergroundCubemapRef.current) {
      //   (undergroundCubemapRef.current as any).cubeMapTex = rt.texture;
      // }
    })();
  }, []);

  const [cubeGeo, planeGeo] = useMemo(() => {
    const planeGeo = new PlaneGeometry(GROUND_WIDTH, GROUND_WIDTH, GROUND_RES, GROUND_RES);
    const cubeGeo = new BoxGeometry(GROUND_WIDTH, GROUND_WIDTH, GROUND_WIDTH, GROUND_RES, 1, GROUND_RES);
    planeGeo.lookAt(new Vector3(0, 1, 0));
    cubeGeo.translate(0, -GROUND_WIDTH / 2, 0);
    let positions = cubeGeo.getAttribute('position');
    for (let i = 0; i < positions.count; i += 1) {
      if (positions.getY(i) === 0) {
        positions.setY(i, getGroundYPosition(positions.getX(i), positions.getZ(i)));
      }
    }

    positions = planeGeo.getAttribute('position');
    for (let i = 0; i < positions.count; i += 1) {
      positions.setY(i, getGroundYPosition(positions.getX(i), positions.getZ(i)));
    }

    positions.needsUpdate = true;
    return [cubeGeo, planeGeo];
  }, []);

  return groundMatProps.map !== undefined ? (
    <>
      <mesh geometry={planeGeo} receiveShadow>
        <meshStandardMaterial
          // color={'#9d968f'}
          side={DoubleSide}
          {...groundMatProps}
        />
      </mesh>
      <mesh geometry={cubeGeo}>
        <undergroundMaterial ref={undergroundCubemapRef} side={BackSide} depthWrite={false} />
      </mesh>
    </>
  ) : null;
};
