import { endOfYear, formatDuration, intervalToDuration } from 'date-fns';
import { makeAutoObservable } from 'mobx';
import { Company, DateRange, Job, Project } from './types';
import { companies, projects, jobs } from './data';
import { addDurations, assertIsDefined, durationToMillisecs, subtractDurations } from '../utils';
import { startOfYear } from 'date-fns';

export enum ESortDirection {
  asc,
  desc,
}

export enum EPortfolioMode {
  projects = 'projects',
  jobs = 'jobs',
  all = 'all',
}

export interface IHistoryState {
  selectedKeywords: Set<string>;
  highlightedProjectId?: string;
  highlightedJobId?: string;
  selectedProjectId?: string;
  selectedJobId?: string;
  mode: EPortfolioMode;
}

export const defaultHistoryState: IHistoryState = {
  selectedKeywords: new Set(),
  highlightedProjectId: undefined,
  highlightedJobId: undefined,
  selectedProjectId: undefined,
  selectedJobId: undefined,
  mode: EPortfolioMode.jobs,
};

export type HistoryJson = Omit<IHistoryState, 'selectedKeywords'> & {
  selectedKeywords: string[];
};

export class HistoryStore {
  companies: Company[];
  projects: Project[];
  jobs: Job[];

  selectedKeywords: Set<string>;
  highlightedProjectId?: string;
  highlightedJobId?: string;
  selectedProjectId?: string;
  selectedJobId?: string;
  mode: EPortfolioMode;

  constructor(state?: IHistoryState) {
    this.companies = companies;
    this.projects = projects;
    this.jobs = jobs;
    this.selectedKeywords = state?.selectedKeywords ?? defaultHistoryState.selectedKeywords;
    this.highlightedProjectId = state?.highlightedProjectId ?? defaultHistoryState.highlightedProjectId;
    this.highlightedJobId = state?.highlightedJobId ?? defaultHistoryState.highlightedJobId;
    this.mode = state?.mode ?? defaultHistoryState.mode;

    // the router will set these
    this.selectedProjectId = undefined;
    this.selectedJobId = undefined;

    makeAutoObservable(this);
  }

  setMode(mode: EPortfolioMode) {
    this.mode = mode;
  }

  getProject(projectId: string) {
    return this.projects.find(p => p.id === projectId);
  }

  getJob(jobId: string) {
    return this.jobs.find(p => p.id === jobId);
  }

  getCompany(companyId: string) {
    return this.companies.find(c => c.id === companyId);
  }

  getSortedProjects(direction: ESortDirection = ESortDirection.desc) {
    return [...this.projects].sort((a, b) =>
      direction === ESortDirection.asc
        ? a.dateRange.start.getTime() - b.dateRange.start.getTime()
        : b.dateRange.start.getTime() - a.dateRange.start.getTime(),
    );
  }

  getSortedJobs(direction: ESortDirection = ESortDirection.desc) {
    return [...this.jobs].sort((a, b) =>
      direction === ESortDirection.asc
        ? a.dateRange.start.getTime() - b.dateRange.start.getTime()
        : b.dateRange.start.getTime() - a.dateRange.start.getTime(),
    );
  }

  setHighlightedProjectId(id?: string) {
    this.highlightedProjectId = id;
  }

  setHighlightedJobId(id?: string) {
    this.highlightedJobId = id;
  }

  setSelectedProjectId(id?: string) {
    this.selectedProjectId = id;
  }

  setSelectedJobId(id?: string) {
    this.selectedJobId = id;
  }

  get someItemIsSelected() {
    return !!this.selectedJobId || !!this.selectedProjectId;
  }

  get keywords() {
    const allKeywords = [...this.projects, ...this.jobs].reduce<string[]>((acc, item) => {
      return [...acc, ...item.keywords];
    }, []);
    return Array.from(new Set(allKeywords).values());
  }

  /**
   * Only pulls from projects, not jobs. Maybe that's better?
   */
  get keywordDurationMap() {
    const result = new Map<string, Duration>();
    this.projects.forEach(project => {
      project.keywords.forEach(keyword => {
        const existingMapping = result.get(keyword);
        if (existingMapping) {
          result.set(keyword, addDurations(existingMapping, intervalToDuration(project.dateRange)));
        } else {
          result.set(keyword, intervalToDuration(project.dateRange));
        }
      });
    });
    return result;
  }

  get weightedKeywords() {
    const result = new Map<string, number>();
    const durationMap = this.keywordDurationMap;
    let maxDuration = 0;
    durationMap.forEach(duration => {
      const durationInMillisecs = durationToMillisecs(duration);
      if (durationInMillisecs > maxDuration) {
        maxDuration = durationInMillisecs;
      }
    });
    durationMap.forEach((duration, keyword) => {
      const durationInMillisecs = durationToMillisecs(duration);
      result.set(keyword, durationInMillisecs / maxDuration);
    });
    return result;
  }

  getProjectsWithKeywords(keywords: string[]) {
    return this.getSortedProjects().filter(project => {
      return (
        keywords.length === 0 ||
        keywords.reduce<string[]>((matchingKeywords, keyword) => {
          if (project.keywords.includes(keyword)) {
            return [...matchingKeywords, keyword];
          }
          return matchingKeywords;
        }, []).length > 0
      );
    });
  }

  getJobsWithKeywords(keywords: string[]) {
    return this.getSortedJobs().filter(job => {
      return (
        keywords.length === 0 ||
        keywords.reduce<string[]>((matchingKeywords, keyword) => {
          const jobKeywords = this.getKeywordsForJob(job.id);
          if (jobKeywords.includes(keyword)) {
            return [...matchingKeywords, keyword];
          }
          return matchingKeywords;
        }, []).length > 0
      );
    });
  }

  get visibleProjects() {
    // if (this.selectedProjectId) {
    //   const project = this.getProject(this.selectedProjectId);
    //   return project ? [project] : [];
    // }
    return this.getProjectsWithKeywords(Array.from(this.selectedKeywords));
  }

  get visibleJobs() {
    // if (this.selectedJobId) {
    //   const job = this.getJob(this.selectedJobId);
    //   return job ? [job] : [];
    // }
    return this.getJobsWithKeywords(Array.from(this.selectedKeywords));
  }

  get visibleHistoryItems() {
    return [...this.visibleJobs, ...this.visibleProjects];
  }

  get selectedKeywordsExperienceDuration() {
    // prev implementation, only counted projects:
    // return this.visibleProjects.reduce((acc, project) => {
    //   const projectDuration = intervalToDuration(project.dateRange);
    //   return projectDuration ? addDurations(acc, projectDuration) : acc;
    // }, intervalToDuration({ start: new Date(), end: new Date() }));
    return this.getDurationForKeywords(this.selectedKeywords);
  }

  getDurationForKeywords(keywords: string[] | Set<string>) {
    keywords = Array.from(keywords);
    const projects = keywords.length ? this.getProjectsWithKeywords(keywords) : this.projects;
    const projectsDuration = projects.reduce((acc, project) => {
      const d = intervalToDuration(project.dateRange);
      return d ? addDurations(acc, d) : acc;
    }, intervalToDuration({ start: new Date(), end: new Date() }));
    const jobs = keywords.length ? this.getJobsWithKeywords(keywords) : this.jobs;
    let jobsDuration = jobs.reduce((acc, project) => {
      const d = intervalToDuration(project.dateRange);
      return d ? addDurations(acc, d) : acc;
    }, intervalToDuration({ start: new Date(), end: new Date() }));

    // don't double-count job keyword due to a project done for a job.
    const projectIds = projects.map(({ id }) => id);
    jobs.forEach(job => {
      if (job.projectIds) {
        job.projectIds.forEach(projectId => {
          if (projectIds.includes(projectId)) {
            const projectDateRange = this.getProject(projectId)?.dateRange;
            if (projectDateRange) {
              jobsDuration = subtractDurations(jobsDuration, intervalToDuration(projectDateRange));
            }
          }
        });
      }
    });
    return addDurations(projectsDuration, jobsDuration);
  }

  unselectKeyword(keyword: string) {
    this.selectedKeywords.delete(keyword);
  }

  selectKeyword(keyword: string) {
    this.selectedKeywords.add(keyword);
  }

  setSelectedKeywords(keywords: string[]) {
    this.selectedKeywords = new Set(keywords);
  }

  clearSelectedKeywords() {
    this.selectedKeywords.clear();
  }

  getKeywordsForHistoryItem(itemId: string) {
    const project = this.projects.find(({ id }) => id === itemId);
    if (project) {
      return project.keywords;
    }
    return this.getKeywordsForJob(itemId);
  }

  getKeywordsForJob(jobId: string) {
    const job = this.jobs.find(({ id }) => id === jobId);
    assertIsDefined(job, `No job with id '${jobId}'.`);
    const { projectIds } = job;
    let result = [...job.keywords];
    if (projectIds) {
      const projectKeywords = projectIds.reduce<string[]>((acc, projectId) => {
        const project = this.projects.find(({ id }) => projectId === id);
        if (project) {
          return [...acc, ...project.keywords];
        }
        return acc;
      }, []);
      result = Array.from(new Set([...result, ...projectKeywords]));
    }
    return result;
  }

  static fromJson(json: HistoryJson) {
    return new HistoryStore({
      ...json,
      selectedKeywords: new Set(json.selectedKeywords),
    });
  }

  reset() {
    this.selectedKeywords = defaultHistoryState.selectedKeywords;
    this.highlightedProjectId = defaultHistoryState.highlightedProjectId;
    this.highlightedJobId = defaultHistoryState.highlightedJobId;
    this.selectedProjectId = defaultHistoryState.selectedProjectId;
    this.mode = defaultHistoryState.mode;
  }

  toJSON() {
    return {
      selectedKeywords: Array.from(this.selectedKeywords),
      highlightedProjectId: this.highlightedProjectId,
      highlightedJobId: this.highlightedJobId,
      selectedProjectId: this.selectedProjectId,
      mode: this.mode,
    };
  }
}
