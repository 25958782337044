import { CSSProperties } from 'react';

type Color = CSSProperties['color'];

export const XIcon = ({ color = '#0B0338', scale = 1 }: { color?: Color; scale?: number }) => {
  return (
    <svg
      width={8 * scale}
      height={8 * scale}
      viewBox="0 0 8 8"
      fill="none"
      scale={scale}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.96465 4.27711C3.97634 4.27912 3.98818 4.28013 4.00001 4.28013C4.01183 4.28013 4.02365 4.27913 4.03533 4.27711L6.63919 6.93676C6.68047 6.97892 6.73448 7 6.78859 7C6.84271 7 6.89672 6.97892 6.93809 6.93676C7.02064 6.85245 7.02064 6.71578 6.93809 6.63146L4.36179 3.99996L6.93802 1.36854C7.02057 1.28422 7.02057 1.14765 6.93802 1.06324C6.85547 0.978921 6.72167 0.978921 6.63912 1.06324L4.03536 3.72289C4.01197 3.71886 3.98806 3.71886 3.96467 3.72289L1.36081 1.06324C1.27826 0.978921 1.14446 0.978921 1.06191 1.06324C0.979363 1.14765 0.979363 1.28422 1.06191 1.36854L3.63821 4.00004L1.06198 6.63146C0.97934 6.71578 0.97934 6.85245 1.06198 6.93676C1.14453 7.02108 1.27824 7.02108 1.36088 6.93676L3.96465 4.27711Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};

export const CaratDownIcon = ({ color = '#0B0338' }: { color?: Color }) => {
  return (
    <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.50006 4.5C4.42791 4.5 4.35589 4.4743 4.30073 4.42289L0.582547 0.94942C0.472484 0.8466 0.472484 0.680052 0.582547 0.577114C0.692611 0.474295 0.87102 0.474295 0.981083 0.577114L4.50006 3.86449L8.01892 0.577114C8.12898 0.474295 8.30739 0.474295 8.41745 0.577114C8.52752 0.680052 8.52752 0.8466 8.41745 0.94942L4.69927 4.42289C4.64424 4.4743 4.57221 4.5 4.50006 4.5Z"
        stroke={color}
      />
    </svg>
  );
};

export const SettingsIcon = ({ color = '#0B0338', scale = 1 }: { color?: Color; scale?: number }) => {
  return (
    <svg width={20 * scale} height={17 * scale} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.36934 6.42635C1.01906 6.15122 0 4.95455 0 3.52417C0 1.891 1.3285 0.5625 2.96167 0.5625C4.59484 0.5625 5.92335 1.891 5.92335 3.52417C5.92335 4.9728 4.87812 6.18172 3.50216 6.43643V15.7312C3.50216 16.044 3.2485 16.2974 2.93575 16.2974C2.623 16.2974 2.36934 16.044 2.36934 15.7312V6.42635ZM1.13282 3.52417C1.13282 2.51567 1.95317 1.69504 2.96167 1.69504C3.97017 1.69504 4.79052 2.51567 4.79052 3.52417C4.79052 4.53267 3.97017 5.3533 2.96167 5.3533C1.95317 5.3533 1.13282 4.53267 1.13282 3.52417Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5788 5.61593V1.12863C17.5788 0.81588 17.3254 0.5625 17.0124 0.5625C16.6997 0.5625 16.446 0.81588 16.446 1.12863V5.62601C15.0957 5.90114 14.0767 7.09781 14.0767 8.52819C14.0767 9.95856 15.0957 11.155 16.446 11.4301V15.8707C16.446 16.1835 16.6997 16.4368 17.0124 16.4368C17.3254 16.4368 17.5788 16.1835 17.5788 15.8707V11.4402C18.9548 11.1855 20 9.97681 20 8.52819C20 7.07956 18.9548 5.87064 17.5788 5.61593ZM15.2095 8.52819C15.2095 7.51996 16.0298 6.69906 17.0383 6.69906C18.0468 6.69906 18.8672 7.51996 18.8672 8.52819C18.8672 9.53669 18.0468 10.357 17.0383 10.357C16.0298 10.357 15.2095 9.53669 15.2095 8.52819Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.40767 1.1289V10.4336C8.05739 10.7087 7.03833 11.9054 7.03833 13.3357C7.03833 14.9689 8.36683 16.2974 10 16.2974C11.6332 16.2974 12.9617 14.9689 12.9617 13.3357C12.9617 11.8871 11.9164 10.6782 10.5405 10.4235V1.1289C10.5405 0.816152 10.2871 0.562772 9.97408 0.562772C9.66132 0.562772 9.40767 0.816152 9.40767 1.1289ZM10 11.5066C8.9915 11.5066 8.17115 12.3272 8.17115 13.3357C8.17115 14.3442 8.9915 15.1649 10 15.1649C11.0085 15.1649 11.8289 14.3442 11.8289 13.3357C11.8289 12.3272 11.0085 11.5066 10 11.5066Z"
        fill={color}
      />
    </svg>
  );
};

export const PfIconLIne = ({ color = '#0B0338' }: { color?: Color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 485.19 485.19">
      <g>
        <circle
          cx="242.6"
          cy="242.6"
          r="237.6"
          style={{ fill: 'none', stroke: color, strokeMiterlimit: '10', strokeWidth: '10px' }}
        />
        <path
          d="M385.41,109.7c14.29-2.35,15.6,19.11,3.49,20.48-13.98,1.58-14.64-18.65-3.49-20.48Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M94.73,109.7c14.29-2.35,15.6,19.11,3.49,20.48-13.98,1.58-14.64-18.65-3.49-20.48Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M239.94,109.7c16.71-2.74,15.21,24.38-.87,20.05-9.72-2.62-8.4-18.52.87-20.05Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M282.43,209.92c5.11-.75,98.16-.67,103.28,0,15.2,1.98,15.26,18.63,3.05,20.48-5.34.81-102.74.87-108.07,0-11.96-1.95-11.91-18.47,1.74-20.48Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M281.99,251.76c5.15-.78,98.98-.67,104.15,0,14.57,1.89,15.74,18.5.87,20.48h-103.71c-15.81-1.94-13.72-18.61-1.31-20.48Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M280.25,293.59c18.49,1.03,99.04-1.36,107.64,0,11.98,1.89,13.83,18.18,0,20.05-5.23.7-100.23.67-105.46,0-13.08-1.68-14.37-15.75-2.18-20.05Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M281.99,168.53c5.19-.77,99.82-.72,105.02,0,13.11,1.8,14.09,18.18,1.74,20.05-5.34.81-102.74.91-108.07,0-11.56-1.97-11.35-18.17,1.31-20.05Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M281.12,334.99c5.21-.79,100.23-.67,105.46,0,13.47,1.72,14.37,18.17.87,20.05-5.2.72-99.82.71-105.02,0-13.81-1.87-13.55-18.19-1.31-20.05Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M97.59,209.92c5.11-.75,98.16-.67,103.28,0,15.2,1.98,15.26,18.63,3.05,20.48-5.34.81-102.74.87-108.07,0-11.96-1.95-11.91-18.47,1.74-20.48Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M97.16,251.76c5.15-.78,98.98-.67,104.15,0,14.57,1.89,15.74,18.5.87,20.48h-103.71c-15.81-1.94-13.72-18.61-1.31-20.48Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M95.42,293.59c18.49,1.03,99.04-1.36,107.64,0,11.98,1.89,13.83,18.18,0,20.05-5.23.7-100.23.67-105.46,0-13.08-1.68-14.37-15.75-2.18-20.05Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M97.16,168.53c5.19-.77,99.82-.72,105.02,0,13.11,1.8,14.09,18.18,1.74,20.05-5.34.81-102.74.91-108.07,0-11.56-1.97-11.35-18.17,1.31-20.05Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M96.29,334.99c5.21-.79,100.23-.67,105.46,0,13.47,1.72,14.37,18.17.87,20.05-5.2.72-99.82.71-105.02,0-13.81-1.87-13.55-18.19-1.31-20.05Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M240.59,247.84c21.07-1.26,22.54,25.88,2.18,26.58-20.59.71-21.16-25.45-2.18-26.58Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M241.46,331.07c20.4-.5,21.11,25.48,1.74,26.58-21.36,1.22-21.57-26.1-1.74-26.58Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M238.85,206.44c21.92-2.94,24.67,23.82,6.1,26.15-21.92,2.75-24-23.75-6.1-26.15Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M240.59,164.6c20.41-1.26,22.68,25.15,3.05,26.58-21.05,1.53-22.26-25.4-3.05-26.58Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
        <path
          d="M240.16,289.67c19.87-1.43,22.75,22.11,6.54,25.71-22.92,5.09-27.17-24.23-6.54-25.71Z"
          style={{ fill: color, strokeWidth: '0px' }}
        />
      </g>
    </svg>
  );
};

export const PfIconColor = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 475.19 475.19">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="111.67"
          y1="439.12"
          x2="363.52"
          y2="36.08"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#04a" />
          <stop offset="1" stopColor="#000" />
        </linearGradient>
      </defs>
      <g>
        <circle cx="237.6" cy="237.6" r="237.6" style={{ fill: 'url(#linear-gradient)', strokeWidth: '0px' }} />
        <path
          d="M380.41,104.7c14.29-2.35,15.6,19.11,3.49,20.48-13.98,1.58-14.64-18.65-3.49-20.48Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M89.73,104.7c14.29-2.35,15.6,19.11,3.49,20.48-13.98,1.58-14.64-18.65-3.49-20.48Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M234.94,104.7c16.71-2.74,15.21,24.38-.87,20.05-9.72-2.62-8.4-18.52.87-20.05Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M277.43,204.92c5.11-.75,98.16-.67,103.28,0,15.2,1.98,15.26,18.63,3.05,20.48-5.34.81-102.74.87-108.07,0-11.96-1.95-11.91-18.47,1.74-20.48Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M276.99,246.76c5.15-.78,98.98-.67,104.15,0,14.57,1.89,15.74,18.5.87,20.48h-103.71c-15.81-1.94-13.72-18.61-1.31-20.48Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M275.25,288.59c18.49,1.03,99.04-1.36,107.64,0,11.98,1.89,13.83,18.18,0,20.05-5.23.7-100.23.67-105.46,0-13.08-1.68-14.37-15.75-2.18-20.05Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M276.99,163.53c5.19-.77,99.82-.72,105.02,0,13.11,1.8,14.09,18.18,1.74,20.05-5.34.81-102.74.91-108.07,0-11.56-1.97-11.35-18.17,1.31-20.05Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M276.12,329.99c5.21-.79,100.23-.67,105.46,0,13.47,1.72,14.37,18.17.87,20.05-5.2.72-99.82.71-105.02,0-13.81-1.87-13.55-18.19-1.31-20.05Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M92.59,204.92c5.11-.75,98.16-.67,103.28,0,15.2,1.98,15.26,18.63,3.05,20.48-5.34.81-102.74.87-108.07,0-11.96-1.95-11.91-18.47,1.74-20.48Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M92.16,246.76c5.15-.78,98.98-.67,104.15,0,14.57,1.89,15.74,18.5.87,20.48h-103.71c-15.81-1.94-13.72-18.61-1.31-20.48Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M90.42,288.59c18.49,1.03,99.04-1.36,107.64,0,11.98,1.89,13.83,18.18,0,20.05-5.23.7-100.23.67-105.46,0-13.08-1.68-14.37-15.75-2.18-20.05Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M92.16,163.53c5.19-.77,99.82-.72,105.02,0,13.11,1.8,14.09,18.18,1.74,20.05-5.34.81-102.74.91-108.07,0-11.56-1.97-11.35-18.17,1.31-20.05Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M91.29,329.99c5.21-.79,100.23-.67,105.46,0,13.47,1.72,14.37,18.17.87,20.05-5.2.72-99.82.71-105.02,0-13.81-1.87-13.55-18.19-1.31-20.05Z"
          style={{ fill: '#80d2d1', strokeWidth: '0px' }}
        />
        <path
          d="M235.59,242.84c21.07-1.26,22.54,25.88,2.18,26.58-20.59.71-21.16-25.45-2.18-26.58Z"
          style={{ fill: '#4d5572', strokeWidth: '0px' }}
        />
        <path
          d="M236.46,326.07c20.4-.5,21.11,25.48,1.74,26.58-21.36,1.22-21.57-26.1-1.74-26.58Z"
          style={{ fill: '#4d5572', strokeWidth: '0px' }}
        />
        <path
          d="M233.85,201.44c21.92-2.94,24.67,23.82,6.1,26.15-21.92,2.75-24-23.75-6.1-26.15Z"
          style={{ fill: '#4d5572', strokeWidth: '0px' }}
        />
        <path
          d="M235.59,159.6c20.41-1.26,22.68,25.15,3.05,26.58-21.05,1.53-22.26-25.4-3.05-26.58Z"
          style={{ fill: '#4d5572', strokeWidth: '0px' }}
        />
        <path
          d="M235.16,284.67c19.87-1.43,22.75,22.11,6.54,25.71-22.92,5.09-27.17-24.23-6.54-25.71Z"
          style={{ fill: '#4d5572', strokeWidth: '0px' }}
        />
      </g>
    </svg>
  );
};

export const SceneIconLine = ({ color = '#0B0338' }: { color?: Color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 485.19 493.94" width="100%" height="100%">
      <defs>
        <clipPath id="clippath">
          <circle cx="242.6" cy="242.6" r="237.6" style={{ fill: 'none', strokeWidth: 0 }} />
        </clipPath>
      </defs>
      <g>
        <g style={{ clipPath: 'url(#clippath)' }}>
          <path
            d="M445.5,387.77c-2.7,4.17-5.8,7.67-8.81,11.56-33.15,7.11-66.45,14.02-99.39,22.03-30.95,7.52-78.62,23.71-107.38,24.78-34.99,1.3-101.3-11.76-134.91-23.68-4.63-1.64-38.61-15.9-40.47-17.07-6.32-3.97-13.44-17.09-17.62-23.68,30.52-15.82,59.82-19.03,93.06-24.23-.05,6.7.04,13.4,0,20.1,12.73,2.11,9.43-.5,8.81-20.65,36.7-5.97,74.62-4.63,110.96,3.3,16.62,3.63,34.05,10.67,50.11,14.32,37.84,8.6,106.12,14.4,145.65,13.22Z"
            style={{ fill: color }}
          />
          <path
            d="M436.69,399.33c-92.12,119.26-272.2,127-374.44,16.24-2.55-2.76-8.94-9.98-7.71-10.19,1.86,1.17,35.85,15.43,40.47,17.07,33.61,11.92,99.92,24.98,134.91,23.68,28.76-1.07,76.42-17.26,107.38-24.78,32.94-8.01,66.24-14.92,99.39-22.03Z"
            style={{ fill: color }}
          />
          <path
            d="M348.04,295.81c32.82,28.83,68.98,53.63,102.97,80.95,1.65,1.75-2.57,6.49-5.51,11.01-39.53,1.18-107.81-4.61-145.65-13.22-16.06-3.65-33.49-10.69-50.11-14.32-36.33-7.93-74.25-9.28-110.96-3.3-.45-14.55-1.11-28.92-1.1-43.5,11.63-7.31,44.38-45.1,47.63-45.7,3.48-.65,7.99,10.04,12.94,5.23,4.23-4.1,33.81-54.29,44.88-65.8,14.3,14.09,26.92,29.67,39.65,45.15q2.48-3.85,4.96-7.71c23.34,12.77,40.51,33.83,60.3,51.21Z"
            style={{ fill: 'none', stroke: color, strokeMiterlimit: 10, strokeWidth: '10px' }}
          />
          <path
            d="M129.98,357.48c-33.24,5.2-62.54,8.4-93.06,24.23-3.35-5.28-2.06-4.17-2.2-4.41,30.64-21.35,64.29-37.45,95.81-57.27-.1,12.48-.45,24.98-.55,37.44Z"
            style={{ fill: 'none', stroke: color, strokeMiterlimit: 10, strokeWidth: '10px' }}
          />
          <path
            d="M134.94,208.53c-.54,18.94.78,37.82,1.65,56.72l1.65-.28c6.85-5.99,8.25-4.49,13.43-11.59,1.43-1.96,4.67-6.87,4.98-6.95,2.07-.57,2.8-.87,4.98-3.03,2.66,6.17,14.37-21.82,16.25-26.61,1.05-2.65,1.51-9.4,4.41-10.19-.17.99-5.22,14.91-6.06,16.79-11.68,26.2-34.85,43.69-36.89,47.63-1.42,2.74-.96,2.18-1.1,5.51-.21,4.9.81,9.66.55,14.59,0,.16-1.65,2.59-1.65,2.75-.23,6.55.56,13.07.55,19.55-.01,14.58.66,28.95,1.1,43.5.62,20.15,3.92,22.75-8.81,20.65.04-6.7-.05-13.4,0-20.1.1-12.47.45-24.96.55-37.44.09-10.83.78-21.63.55-32.49-.09-4.25,1.13-6.69-.55-9.91-1.33-2.54-8.14-8.53-11.01-12.66-1.42-2.05-6.45-8.07-4.41-6.33,9.28,7.88,16.28,18.96,18.17,9.91.27-1.27-2.35-24.68-2.54-25.96-.11-.72-2.03-6.78-4.62-12.59-.86-1.93.07-3.11,0-3.3-.08-.21-4.52-7.73-8.52-16.39-3.43-7.44-3.97-8.9-5.28-12.89l6.81,15.05,11.94,25.18,2.7,1.64"
            style={{ fill: color, stroke: color, strokeMiterlimit: 10, strokeWidth: '3px ' }}
          />
          <path
            d="M138.24,276.54c.14-3.33-.32-2.76,1.1-5.51.15,1.61,9.76.42,12.39,0,24.54-3.93,37.19-15.67,55.34-30.84-13.86,22.95-43.24,34.45-68.83,36.34Z"
            style={{ fill: color, stroke: color, strokeMiterlimit: 10, strokeWidth: '3px ' }}
          />
          <path
            d="M168.8,198.9c-4.29,15.67-6.8,31.76-14.59,46.25-5.17,7.09-9.12,13.83-15.97,19.82"
            style={{ fill: color, stroke: color, strokeMiterlimit: 10, strokeWidth: '3px ' }}
          />
          <path
            d="M130.53,277.64c-4.15-1.17-8.51-1.61-12.66-2.75-18.97-5.21-38.67-15.57-50.66-31.66-6.81-9.14-16.57-28.88,2.75-30.01-.91-8.37-6.51-36.91,9.36-30.29l4.96,2.75c.72-10.52,3.93-38.23,20.37-28.63,2.04-5.86,8.5-17.78,12.66-22.3,17.49-18.99,30.88-.84,40.2,15.14.62,1.06,2.24,6.31,3.03,6.61,2.47.95,26.45-23.93,27.26,20.65,23.75-1.35,10.23,36.05,11.56,37.99,1.12,1.64,23.3-.76,7.71,25.05-18.15,15.17-30.8,26.91-55.34,30.84-2.63.42-12.24,1.61-12.39,0,.29-.55.98-1.37,2-2.43"
            style={{ fill: 'none', stroke: color, strokeMiterlimit: 10, strokeWidth: '10px' }}
          />
          <path
            d="M87.58,211.01c6.32,16.77,18.21,38.61,29.73,52.31.46.55,1.59,1.19,2.2,1.65,2.13,3.07,6.44,7.17,9.06,10.11"
            style={{ fill: 'none', stroke: color, strokeMiterlimit: 10, strokeWidth: '6px ' }}
          />
        </g>
        <circle
          cx="242.6"
          cy="242.6"
          r="237.6"
          style={{ fill: 'none', stroke: color, strokeMiterlimit: 10, strokeWidth: '10px' }}
        />
      </g>
    </svg>
  );
};

export const SceneIconColor = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 475.19 488.94">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="111.67"
          y1="439.12"
          x2="363.52"
          y2="36.08"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#04a" />
          <stop offset="1" stopColor="#000" />
        </linearGradient>
        <clipPath id="clippath">
          <circle cx="237.6" cy="237.6" r="237.6" style={{ fill: 'none', strokeWidth: '0px' }} />
        </clipPath>
      </defs>
      <g>
        <circle cx="237.6" cy="237.6" r="237.6" style={{ fill: 'url(#linear-gradient)', strokeWidth: '0px' }} />
        <g style={{ clipPath: 'url(#clippath)' }}>
          <path
            d="M440.5,382.77c-2.7,4.17-5.8,7.67-8.81,11.56-33.15,7.11-66.45,14.02-99.39,22.03-30.95,7.52-78.62,23.71-107.38,24.78-34.99,1.3-101.3-11.76-134.91-23.68-4.63-1.64-38.61-15.9-40.47-17.07-6.32-3.97-13.44-17.09-17.62-23.68,30.52-15.82,59.82-19.03,93.06-24.23-.05,6.7.04,13.4,0,20.1,12.73,2.11,9.43-.5,8.81-20.65,36.7-5.97,74.62-4.63,110.96,3.3,16.62,3.63,34.05,10.67,50.11,14.32,37.84,8.6,106.12,14.4,145.65,13.22Z"
            style={{ fill: '#452d13', strokeWidth: '0px' }}
          />
          <path
            d="M431.69,394.33c-92.12,119.26-272.2,127-374.44,16.24-2.55-2.76-8.94-9.98-7.71-10.19,1.86,1.17,35.85,15.43,40.47,17.07,33.61,11.92,99.92,24.98,134.91,23.68,28.76-1.07,76.42-17.26,107.38-24.78,32.94-8.01,66.24-14.92,99.39-22.03Z"
            style={{ fill: '#21150b', strokeWidth: '0px' }}
          />
          <path
            d="M343.04,290.81c32.82,28.83,68.98,53.63,102.97,80.95,1.65,1.75-2.57,6.49-5.51,11.01-39.53,1.18-107.81-4.61-145.65-13.22-16.06-3.65-33.49-10.69-50.11-14.32-36.33-7.93-74.25-9.28-110.96-3.3-.45-14.55-1.11-28.92-1.1-43.5,11.63-7.31,44.38-45.1,47.63-45.7,3.48-.65,7.99,10.04,12.94,5.23,4.23-4.1,33.81-54.29,44.88-65.8,14.3,14.09,26.92,29.67,39.65,45.15l4.96-7.71c23.34,12.77,40.51,33.83,60.3,51.21Z"
            style={{ fill: '#2c709d', strokeWidth: '0px' }}
          />
          <path
            d="M124.98,352.48c-33.24,5.2-62.54,8.4-93.06,24.23-3.35-5.28-2.06-4.17-2.2-4.41,30.64-21.35,64.29-37.45,95.81-57.27-.1,12.48-.45,24.98-.55,37.44Z"
            style={{ fill: '#2c709d', strokeWidth: '0px' }}
          />
          <path
            d="M152.51,144.89c.62,1.06,2.24,6.31,3.03,6.61,2.47.95,26.45-23.93,27.26,20.65,23.75-1.35,10.23,36.05,11.56,37.99,1.12,1.64,23.3-.76,7.71,25.05-18.15,15.17-30.8,26.91-55.34,30.84-2.63.42-12.24,1.61-12.39,0,2.05-3.94,25.22-21.43,36.89-47.63.84-1.89,5.89-15.8,6.06-16.79.43-2.52.66-4.01-3.85-3.58-1.15,6.18-13.9,34.58-16.52,37.17l-.55-1.38c6.44-12.06,8.5-25.57,12.11-38.55q-2.34-.69-4.68-1.38c-4.29,15.67-6.8,31.76-14.59,46.25-5.17,7.09-9.12,13.83-15.97,19.82-3.76-40.91-1.64-82.05-2.2-123.07h-4.41c-.18,23.76.3,47.55,0,71.31-.04,3.45.55,16.83-.55,18.72-7.85-15.92-10.91-33.48-14.59-50.66q-2.06.69-4.13,1.38c2.96,15.97,6.63,31.68,12.66,46.81,2.94,7.37,6.98,11.43,7.16,12.11.42,1.58.28,24.83-.83,28.36-20.78-20.72-29.85-35.59-39.92-62.77,1.52-1.51,2.97-.91,3.3-1.38,1.43-1.97,2.91-24.77,10.46-47.36.24-.71-.7-.96-.55-1.38,2.04-5.86,8.5-17.78,12.66-22.3,17.49-18.99,30.88-.84,40.2,15.14Z"
            style={{ fill: '#bf9e62', strokeWidth: '0px' }}
          />
          <path
            d="M177.29,201.61c-.17.99-5.22,14.91-6.06,16.79-11.68,26.2-34.85,43.69-36.89,47.63-1.42,2.74-.96,2.18-1.1,5.51-.21,4.9.81,9.66.55,14.59,0,.16-1.65,2.59-1.65,2.75-.23,6.55.56,13.07.55,19.55-.01,14.58.66,28.95,1.1,43.5.62,20.15,3.92,22.75-8.81,20.65.04-6.7-.05-13.4,0-20.1.1-12.47.45-24.96.55-37.44.09-10.83.78-21.63.55-32.49-.09-4.25,1.13-6.69-.55-9.91-1.33-2.54-8.14-8.53-11.01-12.66-1.42-2.05-2.24-4.75-4.41-6.33,1.6-8.89,16.28,18.96,18.17,9.91.27-1.27.19-24.6,0-25.88-.11-.72-4.58-6.86-7.16-12.66-.86-1.93.07-3.11,0-3.3-.15-.38-1.03,2.02-3.85-6.61l1.38-1.38c2.06.07,5.33,16.18,7.43,13.22,1.1-1.89.51-15.27.55-18.72,3.23.82.21-5.09,3.3-4.68-.54,18.94.78,37.82,1.65,56.72l1.65-.28c6.85-5.99,10.8-12.73,15.97-19.82,1.43-1.96,3.26-5.42,3.58-5.51,2.07-.57,1.94,2.71,4.13.55,2.66,6.17,14.08-18.61,15.97-23.4,1.05-2.65,1.51-9.4,4.41-10.19Z"
            style={{ fill: '#445183', strokeWidth: '0px' }}
          />
          <path
            d="M64.96,208.21c.36-.02.74,0,1.1,0,9.32,27.02,19.23,39.88,46.25,50.11.46.55,1.59,1.19,2.2,1.65,2.87,4.14,9.69,10.12,11.01,12.66-4.15-1.17-8.51-1.61-12.66-2.75-18.97-5.21-38.67-15.57-50.66-31.66-6.81-9.14-16.57-28.88,2.75-30.01Z"
            style={{ fill: '#9b8c74', strokeWidth: '0px' }}
          />
          <path
            d="M126.08,226.94c-2.1,2.97-5.38-13.15-7.43-13.22l-1.38,1.38c2.82,8.63,3.71,6.23,3.85,6.61.07.19-.86,1.37,0,3.3,2.58,5.8,7.05,11.95,7.16,12.66.19,1.28.27,24.61,0,25.88-1.9,9.04-16.57-18.8-18.17-9.91,2.17,1.58,2.98,4.28,4.41,6.33-.62-.46-1.74-1.1-2.2-1.65-11.52-13.7-23.41-35.54-29.74-52.31-3.45-9.15-6.54-18.42-8.26-28.08.26.11,4.75,2.55,4.96,2.75,1.66,1.63,5.7,17.55,7.16,21.48,10.07,27.19,19.14,42.06,39.92,62.77,1.1-3.53,1.25-26.77.83-28.36-.18-.68-4.22-4.74-7.16-12.11-6.04-15.13-9.7-30.84-12.66-46.81q2.06-.69,4.13-1.38c3.68,17.18,6.74,34.74,14.59,50.66Z"
            style={{ fill: '#575d89', strokeWidth: '0px' }}
          />
          <path
            d="M99.65,152.05c-.14.42.79.67.55,1.38-7.55,22.59-9.04,45.39-10.46,47.36-.34.47-1.78-.14-3.3,1.38-1.45-3.93-5.49-19.84-7.16-21.48.72-10.52,3.93-38.23,20.37-28.63Z"
            style={{ fill: '#9b8c74', strokeWidth: '0px' }}
          />
          <path
            d="M74.32,177.93c1.72,9.66,4.81,18.94,8.26,28.08-2.38.58-1.2-1.74-2.48-2.2-.68-.25-13.63,1.89-14.04,2.48v1.93c-.37,0-.74-.02-1.1,0-.91-8.37-6.51-36.91,9.36-30.29Z"
            style={{ fill: '#9b8c74', strokeWidth: '0px' }}
          />
          <path
            d="M133.24,271.54c.14-3.33-.32-2.76,1.1-5.51.15,1.61,9.76.42,12.39,0,24.54-3.93,37.19-15.67,55.34-30.84-13.86,22.95-43.24,34.45-68.83,36.34Z"
            style={{ fill: '#9b8c74', strokeWidth: '0px' }}
          />
          <path
            d="M133.24,259.98l-1.65.28c-.87-18.89-2.19-37.78-1.65-56.72-3.09-.41-.07,5.5-3.3,4.68.3-23.76-.18-47.55,0-71.31h4.41c.56,41.02-1.56,82.16,2.2,123.07Z"
            style={{ fill: '#575d89', strokeWidth: '0px' }}
          />
          <path
            d="M156.92,235.2c-2.19,2.16-2.06-1.12-4.13-.55-.32.09-2.15,3.54-3.58,5.51,7.79-14.5,10.3-30.59,14.59-46.25q2.34.69,4.68,1.38c-3.61,12.98-5.68,26.49-12.11,38.55l.55,1.38Z"
            style={{ fill: '#575d89', strokeWidth: '0px' }}
          />
          <path
            d="M177.29,201.61c-2.89.79-3.36,7.53-4.41,10.19-1.89,4.79-13.31,29.57-15.97,23.4,2.62-2.59,15.37-30.99,16.52-37.17,4.52-.43,4.29,1.06,3.85,3.58Z"
            style={{ fill: '#575d89', strokeWidth: '0px' }}
          />
          <path
            d="M82.58,206.01c6.32,16.77,18.21,38.61,29.74,52.31-27.02-10.23-36.94-23.09-46.25-50.11v-1.93c.41-.59,13.37-2.72,14.04-2.48,1.28.47.09,2.79,2.48,2.2Z"
            style={{ fill: '#bf9e62', strokeWidth: '0px' }}
          />
        </g>
      </g>
    </svg>
  );
};
