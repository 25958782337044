import { HistoryItem, Job, Project } from '../../../store';
import { YearTick, HistoryItemLayout, TimelineLayout } from './types';
import { areIntervalsOverlapping, endOfYear, isBefore, startOfYear, subMonths } from 'date-fns';

const getOverlapRelIdxs = (items: HistoryItem[]) => {
  const overlaps: Map<number, number[]> = new Map();
  for (let i = 0; i < items.length; i++) {
    const { dateRange: dr1 } = items[i];
    for (let j = 0; j < items.length; j++) {
      const { dateRange: dr2 } = items[j];
      if (i !== j && areIntervalsOverlapping(dr1, dr2)) {
        const existing = overlaps.get(i) ?? [];
        existing.push(j - i);
        overlaps.set(i, existing);
      }
    }
  }
  return overlaps;
};

export const getTimelineLayout = ({
  minimumItemHeight,
  visibleProjects,
  visibleJobs,
}: {
  minimumItemHeight: number;
  visibleProjects: Project[];
  visibleJobs: Job[];
}): TimelineLayout => {
  // const jobs = this.visibleJobs;
  // const projects = this.visibleProjects;
  const ranges = [...visibleJobs, ...visibleProjects]
    .map(project => ({ ...project.dateRange, id: project.id }))
    .sort((a, b) => a.start.getTime() - b.start.getTime()); // note sorting matters for the range overlap logic
  const minRange = ranges.reduce((min, v) => (v.start.getTime() < min.start.getTime() ? v : min), ranges[0]);
  const maxRange = ranges.reduce((max, v) => (v.end.getTime() > max.end.getTime() ? v : max), ranges[0]);
  const endOfYearMostRecent = endOfYear(maxRange.end);
  const now = new Date();
  const endOfThisYear = endOfYear(now);
  const startOfYearMinRange = startOfYear(minRange.start);
  const monthsPriorToMinRange = subMonths(minRange.start, 6);
  const timelineRange = {
    start: isBefore(startOfYearMinRange, monthsPriorToMinRange) ? startOfYearMinRange : monthsPriorToMinRange,
    end: endOfYearMostRecent.getTime() < endOfThisYear.getTime() ? endOfYearMostRecent : now,
  };

  const timelineDuration = timelineRange.end.getTime() - timelineRange.start.getTime();
  const yearTicks: YearTick[] = [];
  for (let y = timelineRange.end.getFullYear(); y >= timelineRange.start.getFullYear(); y--) {
    const topNormalized =
      (timelineRange.end.getTime() - startOfYear(new Date(`${y}-01-02`)).getTime()) / timelineDuration;
    yearTicks.push({
      year: y,
      topNormalized,
    });
  }

  const projectsOverlaps = getOverlapRelIdxs(visibleProjects);
  const projects: HistoryItemLayout[] = visibleProjects.map((project, i) => {
    return {
      id: project.id,
      topNormalized: (timelineRange.end.getTime() - project.dateRange.end.getTime()) / timelineDuration,
      heightNormalized: (project.dateRange.end.getTime() - project.dateRange.start.getTime()) / timelineDuration,
      overlapRelIdxs: projectsOverlaps.get(i) ?? [],
    };
  });

  const jobOverlaps = getOverlapRelIdxs(visibleJobs);
  const jobs: HistoryItemLayout[] = visibleJobs.map((job, i) => {
    return {
      id: job.id,
      topNormalized: (timelineRange.end.getTime() - job.dateRange.end.getTime()) / timelineDuration,
      heightNormalized: (job.dateRange.end.getTime() - job.dateRange.start.getTime()) / timelineDuration,
      overlapRelIdxs: jobOverlaps.get(i) ?? [],
    };
  });

  let height = Math.max(projects.length, jobs.length) * minimumItemHeight;

  return {
    yearTicks,
    projects,
    jobs,
    height: height,
    timelineRange,
  };
};

export const getHistoryLayout = ({
  minimumItemHeight,
  visibleProjects,
  visibleJobs,
  viewportWidth,
}: {
  minimumItemHeight: number;
  visibleProjects: Project[];
  visibleJobs: Job[];
  viewportWidth: number;
}) => {};
