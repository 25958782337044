import { observer } from 'mobx-react-lite';
import { MouseEventHandler, useContext, ReactNode, useRef, useEffect, useCallback } from 'react';
import { Project, Company, Job, HistoryItem } from '../../../store/types';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { format } from 'date-fns';
import { CaratDownIcon } from '../icons';
import { StoreContext } from '../../../store';
import { assertIsDefined } from '../../../utils';
import { useClickAway, useMeasure } from '@uidotdev/usehooks';
import { HistoryViewContext } from './context';
import {
  GRID_GAP_PX,
  MED_LIST_W_BREAKPOINT,
  SML_LIST_W_BREAKPOINT,
  TNY_LIST_W_BREAKPOINT,
} from '../../../store/constants';
import { HistoryItemLayout } from './types';

export type HistoryItemViewProps = {
  item: HistoryItem;
  itemName: string;
  itemUrl?: string;
  itemDetail?: string | ReactNode;
  itemLayout: HistoryItemLayout;
  listWidth: number;
  moreLinkUrl: string;
  isHighlighted?: boolean;
  isOpen?: boolean;
  onHeightChanged: (height: number) => void;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export type HistoryItemSubclassProps = Omit<
  HistoryItemViewProps,
  'item' | 'itemName' | 'moreLinkUrl' | 'itemUrl' | 'itemDetail'
>;

export const HistoryItemView = observer(
  ({
    item,
    itemName,
    itemUrl,
    itemDetail,
    itemLayout,
    listWidth,
    moreLinkUrl,
    isHighlighted,
    isOpen,
    onHeightChanged,
    onMouseEnter,
    onMouseLeave,
    onClick,
  }: HistoryItemViewProps) => {
    const { history: historyStore } = useContext(StoreContext);
    const { timelineLayout } = useContext(HistoryViewContext);
    const { id, companyId, dateRange, bulletPts, description, imageUrl, imageAltText } = item;

    const navigate = useNavigate();

    // TODO: this may not be needed
    const [measureRef, { height }] = useMeasure();

    const clickAwayRef = useClickAway(() => {
      if (isOpen) {
        navigate(-1);
      }
    });

    useEffect(() => {
      if (height) {
        onHeightChanged(height);
      }
    }, [onHeightChanged, height]);

    const keywords = historyStore.getKeywordsForHistoryItem(item.id);
    let top = itemLayout.topNormalized + itemLayout.heightNormalized / 2;
    if (height && timelineLayout.height) {
      const centered = top - height / timelineLayout.height / 2;
      top = Math.max(centered, 0);
    }

    let leftAmt = 0;
    let widthPx = listWidth;
    const nOverlaps = itemLayout.overlapRelIdxs.length;
    if (nOverlaps > 0) {
      const overlapSum = itemLayout.overlapRelIdxs.reduce((acc, n) => acc + n, 0);
      leftAmt = overlapSum < 0 ? 0.5 : 0; // TODO: mmmm, not quite
      widthPx = listWidth / 2 - (leftAmt === 0 ? GRID_GAP_PX / 2 : 0);
      // console.log({ id, nOverlaps, overlapSum, leftAmt, widthPx, idxs: itemLayout.overlapRelIdxs });
    }

    const isMedSize = widthPx < MED_LIST_W_BREAKPOINT;
    const isSmlSize = widthPx < SML_LIST_W_BREAKPOINT;
    const isTnySize = widthPx < TNY_LIST_W_BREAKPOINT;

    let moreContent = null;
    let otherItemIsOpen = historyStore.someItemIsSelected && !isOpen;
    if (bulletPts) {
      if (isOpen) {
        moreContent = (
          <div className="card-more">
            <ul className="bullet-points">
              {bulletPts.map(pt => {
                return <li key={pt}>{pt}</li>;
              })}
            </ul>
          </div>
        );
      } else {
        moreContent = (
          <p className="card-more-link" onClick={() => navigate(moreLinkUrl)}>
            <span>Read more</span>
            <CaratDownIcon />
          </p>
        );
      }
    }

    // console.log({ listItemWidth, widthPx });
    return (
      <motion.div
        ref={r => {
          measureRef(r);
          if (r) {
            clickAwayRef.current = r;
          }
        }}
        className={classNames('card', {
          highlighted: isHighlighted,
          open: isOpen,
          faded: otherItemIsOpen,
          med: isMedSize,
          sml: isSmlSize,
          tny: isTnySize,
        })}
        // exit={{ transform: 'scale(1, 0)' }}
        // initial={{ transform: 'scale(1, 0)' }}
        // animate={{ transform: 'scale(1, 1)' }}
        style={{
          position: 'absolute',
          top: `${top * 100}%`,
          left: `${leftAmt * 100}%`,
          width: `${widthPx}px`,
          // minHeight: `${itemLayout.heightNormalized * 100}%`,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}>
        <div className="card-top">
          <div className="image-container">
            <div
              className={classNames('image-bg', item.imageSize)}
              style={{ backgroundImage: `url(/images/${imageUrl})` }}
            />
            <img src={`/images/${imageUrl}`} alt={imageAltText} />
          </div>
          <div className="card-content-container">
            <div className="headline">
              <h3>{itemUrl ? <a href={itemUrl}>{itemName}</a> : itemName}</h3>
              <div className="date-range-description">
                {dateRange.desc ?? `${format(dateRange.start, 'MMM y')} - ${format(dateRange.end, 'MMM y')}`}
              </div>
            </div>
            <div className="detail">{itemDetail}</div>
            <p className="description">{description}</p>
            {moreContent}
          </div>
        </div>
        <div className="card-bottom">
          <div className="keyword-list">
            {keywords.map(keyword => {
              return (
                <span key={keyword} className="keyword">
                  {keyword}
                </span>
              );
            })}
          </div>
          <div className="keyword-summary">{keywords.length} skills</div>
        </div>
      </motion.div>
    );
  },
);
