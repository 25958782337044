// https://codesandbox.io/p/sandbox/grass-shader-forked-okub75

// Based on https://codepen.io/al-ro/pen/jJJygQ by al-ro, but rewritten in react-three-fiber
import { PlaneGeometry, Vector3, Vector4, MeshDepthMaterial, Material } from 'three';
import React, { useMemo, useContext, useEffect, useState } from 'react';
import { GroupProps, useFrame } from '@react-three/fiber';
import { createGrassMaterial } from './GrassMaterial';
import { Triplet } from '../../../../types';
import { createNoise2D } from 'simplex-noise';
import { StoreContext } from '../../../../store';

export const Grass = ({
  bladeWidth = 0.12,
  bladeHeight = 1,
  joints = 5,
  width = 100,
  nInstances = 50000,
  getGroundYPosition,
  position,
  ...groupProps
}: {
  bladeWidth?: number;
  bladeHeight?: number;
  joints?: number;
  width?: number;
  getGroundYPosition: (x: number, z: number) => number;
  nInstances?: number;
  position: Triplet;
} & GroupProps) => {
  const { background: store } = useContext(StoreContext);
  const [stdMaterial, setStdMaterial] = useState<Material | null>(null);
  const [depthMaterial, setDepthMaterial] = useState<MeshDepthMaterial | null>(null);
  const [shader, setShader] = useState<any>();
  const [depthShader, setDepthShader] = useState<any>();

  useEffect(() => {
    (async () => {
      const { material, depthMaterial } = await createGrassMaterial({
        store,
        setMaterialShader: setShader,
        setDepthShader: setDepthShader,
      });
      setStdMaterial(material);
      setDepthMaterial(depthMaterial);
    })();
  }, []);

  const baseGeom = useMemo(
    () => new PlaneGeometry(bladeWidth, bladeHeight, 1, joints).translate(0, bladeHeight / 2, 0),
    [bladeWidth, bladeHeight, joints],
  );

  const attributeData = useMemo(
    () => getAttributeData(position[0], position[2], nInstances, width, getGroundYPosition),
    [nInstances, width, getGroundYPosition, position[0], position[2]],
  );

  useFrame(state => {
    if (shader) {
      shader.uniforms.time.value = state.clock.elapsedTime / 4;
      shader.uniforms.windIntensity.value = store.wind.intensity;
    }
    if (depthShader) {
      depthShader.uniforms.time.value = state.clock.elapsedTime / 4;
      depthShader.uniforms.windIntensity.value = store.wind.intensity;
    }
  });

  return !stdMaterial || !depthMaterial ? null : (
    <group {...groupProps}>
      <mesh frustumCulled={false} receiveShadow castShadow material={stdMaterial} customDepthMaterial={depthMaterial}>
        <instancedBufferGeometry
          index={baseGeom.index}
          attributes-position={baseGeom.attributes.position}
          attributes-uv={baseGeom.attributes.uv}>
          <instancedBufferAttribute attach={'attributes-offset'} args={[attributeData.offsets, 3]} />
          <instancedBufferAttribute attach={'attributes-orientation'} args={[attributeData.orientations, 4]} />
          <instancedBufferAttribute attach={'attributes-stretch'} args={[attributeData.stretches, 1]} />
          <instancedBufferAttribute attach={'attributes-colorVariation'} args={[attributeData.colorVariations, 1]} />
          <instancedBufferAttribute attach={'attributes-halfRootAngleSin'} args={[attributeData.halfRootAngleSin, 1]} />
          <instancedBufferAttribute attach={'attributes-halfRootAngleCos'} args={[attributeData.halfRootAngleCos, 1]} />
        </instancedBufferGeometry>
      </mesh>
    </group>
  );
};

const noise = createNoise2D(() => 0.823);

function getAttributeData(
  centerX: number,
  centerZ: number,
  instances: number,
  width: number,
  getGroundYPosition: (x: number, z: number) => number,
) {
  const offsets = [];
  const orientations = [];
  const stretches = [];
  const colorVariations = [];
  const halfRootAngleSin = [];
  const halfRootAngleCos = [];

  let quaternion_0 = new Vector4();
  let quaternion_1 = new Vector4();

  //The min and max angle for the growth direction (in radians)
  const min = -0.25;
  const max = 0.25;

  //For each instance of the grass blade
  for (let i = 0; i < instances; i++) {
    //Offset of the roots
    const offsetX = Math.random() * width - width / 2 + centerX;
    const offsetZ = Math.random() * width - width / 2 + centerZ;
    const offsetY = getGroundYPosition(offsetX, offsetZ);
    offsets.push(offsetX, offsetY, offsetZ);

    //Define random growth directions
    //Rotate around Y
    let angle = Math.PI - Math.random() * (2 * Math.PI);
    halfRootAngleSin.push(Math.sin(0.5 * angle));
    halfRootAngleCos.push(Math.cos(0.5 * angle));

    let RotationAxis = new Vector3(0, 1, 0);
    let x = RotationAxis.x * Math.sin(angle / 2.0);
    let y = RotationAxis.y * Math.sin(angle / 2.0);
    let z = RotationAxis.z * Math.sin(angle / 2.0);
    let w = Math.cos(angle / 2.0);
    quaternion_0.set(x, y, z, w).normalize();

    //Rotate around X
    angle = Math.random() * (max - min) + min;
    RotationAxis = new Vector3(1, 0, 0);
    x = RotationAxis.x * Math.sin(angle / 2.0);
    y = RotationAxis.y * Math.sin(angle / 2.0);
    z = RotationAxis.z * Math.sin(angle / 2.0);
    w = Math.cos(angle / 2.0);
    quaternion_1.set(x, y, z, w).normalize();

    //Combine rotations to a single quaternion
    quaternion_0 = multiplyQuaternions(quaternion_0, quaternion_1);

    //Rotate around Z
    angle = Math.random() * (max - min) + min;
    RotationAxis = new Vector3(0, 0, 1);
    x = RotationAxis.x * Math.sin(angle / 2.0);
    y = RotationAxis.y * Math.sin(angle / 2.0);
    z = RotationAxis.z * Math.sin(angle / 2.0);
    w = Math.cos(angle / 2.0);
    quaternion_1.set(x, y, z, w).normalize();

    //Combine rotations to a single quaternion
    quaternion_0 = multiplyQuaternions(quaternion_0, quaternion_1);

    orientations.push(quaternion_0.x, quaternion_0.y, quaternion_0.z, quaternion_0.w);

    //Define variety in height
    if (i < instances / 3) {
      stretches.push(Math.random() * 1.8);
    } else {
      stretches.push(Math.random());
    }
    colorVariations.push(noise(offsetX / 50, offsetZ / 50));
  }

  return {
    offsets: new Float32Array(offsets),
    orientations: new Float32Array(orientations),
    stretches: new Float32Array(stretches),
    colorVariations: new Float32Array(colorVariations),
    halfRootAngleCos: new Float32Array(halfRootAngleCos),
    halfRootAngleSin: new Float32Array(halfRootAngleSin),
  };
}

function multiplyQuaternions(q1: Vector4, q2: Vector4) {
  const x = q1.x * q2.w + q1.y * q2.z - q1.z * q2.y + q1.w * q2.x;
  const y = -q1.x * q2.z + q1.y * q2.w + q1.z * q2.x + q1.w * q2.y;
  const z = q1.x * q2.y - q1.y * q2.x + q1.z * q2.w + q1.w * q2.z;
  const w = -q1.x * q2.x - q1.y * q2.y - q1.z * q2.z + q1.w * q2.w;
  return new Vector4(x, y, z, w);
}
