export const REM_PX = 16;

export const TIMELINE_RANGE_MAX_WIDTH_REM = 3;
export const TIMELINE_RANGE_MAX_WIDTH_PX = REM_PX * TIMELINE_RANGE_MAX_WIDTH_REM;
export const TIMELINE_RANGE_MIN_WIDTH_REM = 1;
export const TIMELINE_RANGE_MIN_WIDTH_PX = REM_PX * TIMELINE_RANGE_MIN_WIDTH_REM;

export const TIMELINE_MAX_WIDTH_REM = 25;
export const TIMELINE_MAX_WIDTH_PX = REM_PX * TIMELINE_MAX_WIDTH_REM;
export const TIMELINE_MIN_WIDTH_PX = TIMELINE_RANGE_MIN_WIDTH_PX * 2;

export const TIMELINE_DEFAULT_WIDTH_PCT = 0.15;
export const HISTORY_VIEW_DEFAULT_LIST_WIDTH_PCT = 0.425;

export const LIST_MIN_WIDTH_PX = 150;
export const LIST_MAX_WIDTH_PX = 800;

export const GRID_GAP_REM = 2;
export const GRID_GAP_PX = GRID_GAP_REM * REM_PX;

// These are used when the available space is reduced
// for any reason. ie, stacking of cards or viewport size.
export const MED_LIST_W_BREAKPOINT = 600;
export const SML_LIST_W_BREAKPOINT = 300;
export const TNY_LIST_W_BREAKPOINT = 230;

export const VIEW_MED_BREAKPT = 930;
export const VIEW_SML_BREAKPT = 600;
export const VIEW_TNY_BREAKPT = 450;

export const HISTORY_VIEW_MAX_WIDTH_REM = 180;
export const HISTORY_VIEW_MAX_WIDTH_PX = HISTORY_VIEW_MAX_WIDTH_REM * REM_PX;
