import { Vector3, Matrix4, MathUtils, Color, Mesh, Euler, TextureLoader, Texture } from 'three';
import { createNoise2D } from 'simplex-noise';
import { SphericalPos, Triplet } from '../../../types';
import { slerp } from '../../../utils';
import { randomNeg1 } from '../../../utils';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { makeAutoObservable, observable, runInAction } from 'mobx';

const noise = createNoise2D(Math.random);

export const GROUND_WIDTH = 100;
export const GROUND_RES = 25;

export function getGroundYPosition(x: number, z: number) {
  const noise = createNoise2D(() => 0.22);
  var y = 1.2 * noise(x / GROUND_RES, z / GROUND_RES);
  y += 2 * noise(x / GROUND_WIDTH, z / GROUND_WIDTH);
  y += 0.2 * noise(x / GROUND_RES / 2, z / GROUND_RES / 2);
  return y;
}

export function positionOnGround(x: number, z: number): Triplet {
  return [x, getGroundYPosition(x, z), z];
}

export function clamp(n: number, min: number, max: number) {
  return Math.min(Math.max(min, n), max);
}

export function sphericalToCartesian(spherical: SphericalPos, distance = 1, flipTheta = false) {
  const phi = MathUtils.degToRad(90 - spherical.elevation);
  const theta = MathUtils.degToRad(spherical.azimuth);
  return new Vector3().setFromSphericalCoords(distance, phi, theta * (flipTheta ? -1 : 1));
}

export function getBlackbodyColor(temp: number) {
  const color = [255, 255, 255];
  color[0] = 56100000 * Math.pow(temp, -3.0 / 2.0) + 148.0;
  color[1] = 100.04 * Math.log(temp) - 623.6;
  if (temp > 6500.0) {
    color[1] = 35200000.0 * Math.pow(temp, -3.0 / 2.0) + 184.0;
  }
  color[2] = 194.18 * Math.log(temp) - 1448.6;
  color[0] = clamp(color[0], 0, 255);
  color[1] = clamp(color[1], 0, 255);
  color[2] = clamp(color[2], 0, 255);
  if (temp < 1000.0) {
    color[0] *= temp / 1000.0;
    color[1] *= temp / 1000.0;
    color[2] *= temp / 1000.0;
  }
  return new Color(color[0] / 255, color[1] / 255, color[2] / 255);
}

export const getSunColor = (elevationInDeg: number) => {
  const progress = Math.abs(Math.sin(MathUtils.degToRad(elevationInDeg)));
  const blackbody = getBlackbodyColor(slerp(2000, 6000, progress));
  blackbody.b = Math.pow(blackbody.b, 0.7);
  blackbody.r = Math.pow(blackbody.r, 0.4);
  return blackbody;
};

const SENSOR_SIZE = 35;

/**
 * Returns radians. Assumes 35 mm sensor size.
 */
export const focalLengthToFov = (focalLengthInMm: number) => {
  return 2 * Math.atan(SENSOR_SIZE / (2 * focalLengthInMm));
};

/**
 * Returns mm. Assumes 35 mm sensor size.
 */
export const fovToFocalLength = (fovInRadians: number) => {
  return SENSOR_SIZE / (2 * Math.tan(fovInRadians / 2));
};

export const objIsMesh = (value: unknown): value is Mesh => {
  return !!value && !!(value as Mesh).isMesh;
};

export const createRandomRotation = (amt = new Vector3(Math.PI)) => {
  return new Matrix4().makeRotationFromEuler(
    new Euler(
      Math.random() * randomNeg1() * amt.x,
      Math.random() * randomNeg1() * amt.y,
      Math.random() * randomNeg1() * amt.z,
    ),
  );
};

export type Loadable = GLTF | Texture;
export type LoadRequestType = 'gltf' | 'texture';
export type LoadRequest = {
  type: 'gltf' | 'texture';
  path: string;
  loader: GLTFLoader | TextureLoader;
  progress: number;
  promise: Promise<Loadable>;
  asset?: Loadable;
};
export class AssetLoader {
  loaders: Map<string, LoadRequest>;

  constructor() {
    this.loaders = new Map();
    makeAutoObservable(this);
  }

  getAssetType(path: string) {
    if (!!path.match(/png|jpg|jpeg/i)) {
      return 'texture';
    }
    if (!!path.match(/glb|gltf/)) {
      return 'gltf';
    }
    throw new Error('Unrecognized asset path.');
  }

  load<T extends Loadable>(path: string, type?: LoadRequestType): Promise<T> {
    const existing = this.loaders.get(path);
    if (existing) {
      // TODO: clone textures here? what about gltfs?
      return existing.asset ? Promise.resolve<T>(existing.asset as T) : (existing.promise as Promise<T>);
    }
    type = type ?? this.getAssetType(path);
    const loader = type === 'texture' ? new TextureLoader() : new GLTFLoader();
    const promise = loader
      .loadAsync(path, event => {
        runInAction(() => {
          request.progress = event.total > 0 ? event.loaded / event.total : 0;
        });
      })
      .catch(error => {
        console.error(`error loading ${path}`, error);
        throw error;
      })
      .then(asset => {
        runInAction(() => {
          request.progress = 1;
          request.asset = asset;
        });
        return asset as T;
      });
    const request: LoadRequest = observable({
      type,
      path,
      loader,
      promise,
      progress: 0,
    });
    this.loaders.set(path, request);
    return request.promise as Promise<T>;
  }

  get progress() {
    const loaders = Array.from(this.loaders.values());
    if (loaders.length === 0) {
      return 1;
    }
    return loaders.reduce((acc, loader) => acc + loader.progress, 0) / loaders.length;
  }
}
