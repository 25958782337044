import { useCounter } from '@uidotdev/usehooks';
import { useCallback, useEffect, useState } from 'react';
import { clamp } from '../../utils';

/**
 * Integers only.
 */
export const NumberCounter = ({
  start,
  target,
  duration,
  postfix,
}: {
  start: number;
  target: number;
  duration: number;
  postfix?: string;
}) => {
  const [current, { set: setCounter }] = useCounter(start);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    const tick = () => {
      const neg = start <= target ? 1 : -1;
      const diff = Math.abs(target - start) * neg;
      const progress = (Date.now() - startTime) / duration;
      const newValue = clamp(start + Math.round(progress * diff), Math.min(start, target), Math.max(start, target));
      if (diff !== 0) {
        setCounter(newValue);
        requestAnimationFrame(tick);
      }
    };
    setCounter(start);
    setStartTime(Date.now());
    requestAnimationFrame(tick);
  }, [start, setStartTime, setCounter, duration, startTime, target]);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  return (
    <span>
      {current}
      {postfix ?? ''}
    </span>
  );
};
