import '../styles/index.scss';
import { useState, useRef, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { TimelineView } from './HistoryView/TimelineView';
import { singleton, StoreContext } from '../../store';
import { KeywordList } from './KeywordList';
import { SearchForm } from './SearchForm';
import { Menu } from './Menu';
import { Header } from './Header';
import { Background } from './Background/Background';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import classNames from 'classnames';
import { Footer } from './Footer';
import { ControlPanel } from './ControlPanel/ControlPanel';
import { observer } from 'mobx-react-lite';
import { SimpleBackground } from './Background';
import { useWindowSize } from '@uidotdev/usehooks';

const USE_SIMPLE_BACKGROUND = true;

export const Chrome = observer(() => {
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const store = singleton();
  const { scrollYProgress } = useScroll();
  const location = useLocation();

  useMotionValueEvent(scrollYProgress, 'change', latest => {
    store.background.setCameraTrackYPosProgress(latest);
  });

  useEffect(() => {
    store.ui.setLocationPath(location.pathname);
  }, [store, location.pathname]);

  useEffect(() => {
    if (windowHeight !== null) {
      // console.log({ windowHeight });
      store.ui.setWindowHeight(window.outerHeight);
    }
  }, [windowHeight, store]);

  if (windowWidth === null) {
    return null;
  }

  return (
    <>
      {windowWidth > 730 ? (
        <StoreContext.Provider value={store}>
          <div className={classNames('site-container', `site-mode-${store.ui.siteMode}`)}>
            {USE_SIMPLE_BACKGROUND ? <SimpleBackground /> : <Background />}
            <Header />
            <div className="main-container">
              <main>
                <Outlet />
              </main>
            </div>
            {/* <Footer /> */}
          </div>
        </StoreContext.Provider>
      ) : (
        <div className="mobile-disclaimer">
          <p>
            Thanks for visiting! This site is a work in progress and is not yet optimized for mobile. Please visit on a
            desktop browser.
          </p>
        </div>
      )}
      {/* 
      <footer>
        <h2>This Site</h2>
        <p>
          A placeholder for a more fun portfolio, coming soon! (Under some definition of "soon". 😅)
          <br />
          <em>Last update: Aug 2023</em>
        </p>

        <h2>Elsewhere on the web</h2>
        <p>Software-y things:</p>
        <ul>
          <li>
            <a href="https://www.linkedin.com/in/patternleaf/">LinkedIn</a>
          </li>
          <li>
            <a href="https://www.github.com/patternleaf/">GitHub</a>
          </li>
        </ul>
        <p>I also take pictures, produce video and music, and sometimes write.</p>
        <ul>
          <li>
            <a href="https://www.vimeo.com/patternleaf/">Vimeo</a>
          </li>
          <li>
            <a href="https://www.instagram.com/patternleaf/">Instagram</a>
          </li>
          <li>
            <a href="http://photo.patternleaf.com/">SmugMug</a>
          </li>
          <li>
            <a href="https://www.soundcloud.com/patternleaf/">SoundCloud</a>
          </li>
          <li>
            <a href="https://www.pond5.com/artist/patternleaf/">Pond 5</a>
          </li>
          <li>
            <a href="https://www.substack.com/@patternleaf/">Substack</a>
          </li>
        </ul>
      </footer>
      */}
    </>
  );
});
