import { format, areIntervalsOverlapping } from 'date-fns';
import { DateRange } from '../../../store/types';
import { useContext } from 'react';
import { motion } from 'framer-motion';
import { StoreContext } from '../../../store';
import { observer } from 'mobx-react-lite';
import { MILLIS_PER_YEAR } from '../../../utils';
import { YearTick } from './types';
import { TIMELINE_RANGE_MAX_WIDTH_REM } from '../../../store/constants';

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const TimeRangeView = observer(
  ({
    range,
    containingRange,
    isHighlighted,
    track,
    side,
  }: {
    range: DateRange & { id: string };
    containingRange: DateRange;
    isHighlighted: boolean;
    track: number;
    side: 'left' | 'right';
  }) => {
    const containerDuration = containingRange.end.getTime() - containingRange.start.getTime();
    const height = (range.end.getTime() - range.start.getTime()) / containerDuration;
    const top = (containingRange.end.getTime() - range.end.getTime()) / containerDuration;
    const bgColor = isHighlighted ? `rgba(128, 128, 245, 1)` : `rgba(119, 119, 119, 1)`;
    const transparentBgColor = isHighlighted ? `rgba(128, 128, 245, 0.6)` : `rgba(119, 119, 119, 0.6)`;
    const fuzzyPct = (range.fuzziness ?? 0) * 100;
    const gradient = `linear-gradient(${transparentBgColor} 0%, ${bgColor} ${fuzzyPct}%, ${
      100 - fuzzyPct
    }%, ${transparentBgColor} 100%)`;

    return (
      <motion.div
        className="date-range"
        data-range-id={range.id}
        animate={{ top: `${top * 100}%`, height: `${height * 100}%` }}
        style={{
          width: `${TIMELINE_RANGE_MAX_WIDTH_REM}rem`,
          right: side === 'right' ? 0 : undefined,
          left: side === 'left' ? 0 : undefined,
          // background: gradient,
          background: transparentBgColor,
          // border: isHighlighted ? '1px solid red' : '1px solid gray',
        }}></motion.div>
    );
  },
);

export const TimelineView = observer(
  ({
    yearTicks,
    timelineHeight,
    timelineRange,
  }: {
    yearTicks: YearTick[];
    timelineHeight: number;
    timelineRange: { start: Date; end: Date };
  }) => {
    const { history: store } = useContext(StoreContext);

    // note sorting matters for the range overlap logic
    const projectRanges = store.visibleProjects
      .map(project => ({ ...project.dateRange, id: project.id }))
      .sort((a, b) => a.start.getTime() - b.start.getTime());
    const jobRanges = store.visibleJobs
      .map(job => ({ ...job.dateRange, id: job.id }))
      .sort((a, b) => a.start.getTime() - b.start.getTime());

    // const minRange = projectRanges.reduce((min, v) => (v.start.getTime() < min.start.getTime() ? v : min), ranges[0]);
    // const maxRange = projectRanges.reduce((max, v) => (v.end.getTime() > max.end.getTime() ? v : max), ranges[0]);

    const duration = timelineRange.end.getTime() - timelineRange.start.getTime();
    const milliHeight = timelineHeight / duration;
    const yearHeight = milliHeight * MILLIS_PER_YEAR;
    const monthHeight = yearHeight / 12;

    return (
      <div className="timeline" style={{ height: timelineHeight }}>
        <div className="ranges">
          {projectRanges.map((range, i) => {
            let prevOverlaps = 0;
            if (i > 0) {
              for (let j = i - 1; j >= 0; j--) {
                const prevRange = projectRanges[j];
                prevOverlaps += areIntervalsOverlapping(range, prevRange) ? 1 : 0;
              }
            }
            return (
              <TimeRangeView
                range={range}
                key={`${range.id}-${format(range.start, 'MM-yy')}-${format(range.end, 'MM-yy')}`}
                containingRange={timelineRange}
                isHighlighted={range.id === store.highlightedProjectId}
                track={prevOverlaps}
                side="right"
              />
            );
          })}
          {jobRanges.map((range, i) => {
            let prevOverlaps = 0;
            if (i > 0) {
              for (let j = i - 1; j >= 0; j--) {
                const prevRange = jobRanges[j];
                prevOverlaps += areIntervalsOverlapping(range, prevRange) ? 1 : 0;
              }
            }
            return (
              <TimeRangeView
                range={range}
                key={`${range.id}-${format(range.start, 'MM-yy')}-${format(range.end, 'MM-yy')}`}
                containingRange={timelineRange}
                isHighlighted={range.id === store.highlightedJobId}
                track={prevOverlaps}
                side="left"
              />
            );
          })}
        </div>
        {yearTicks.map(tick => {
          const months = MONTHS.map((name, i) => {
            return (
              <div
                className={`month-tick month-${name}`}
                key={`year-${tick.year}-month-${name}`}
                style={{
                  transform: `translate(0%, ${-monthHeight * (i + 1) + monthHeight / 2}px)`,
                  height: `${monthHeight}px`,
                }}>
                {/* {monthHeight > 16 ? `${name} - ${tick.year}` : '-'} */}
                {monthHeight > 16 ? `${name}` : '-'}
              </div>
            );
          });
          return (
            <motion.div
              className={`year-container year-${tick.year}`}
              key={`year-${tick.year}`}
              animate={{ transform: `translate(0px, ${tick.topNormalized * timelineHeight}px)` }}>
              {months}
              <div className="year-label">{tick.year}</div>
            </motion.div>
          );
        })}
      </div>
    );
  },
);
