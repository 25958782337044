import { createNoise2D, createNoise3D } from 'simplex-noise';
import {
  Shape,
  ShapeGeometry,
  BufferGeometry,
  PlaneGeometry,
  BufferAttribute,
  Color,
  Vector3,
  InterleavedBufferAttribute,
  Quaternion,
  Matrix4,
  RepeatWrapping,
  NearestFilter,
  InstancedMesh,
  Euler,
  AnimationClip,
  WebGLProgramParametersWithUniforms,
  MeshStandardMaterial,
  Mesh,
  AnimationMixer,
  AnimationAction,
  Texture,
  MeshBasicMaterial,
  Material,
  DoubleSide,
} from 'three';
import { InstancedUniformsMesh } from 'three-instanced-uniforms-mesh';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { LEAF_SIZE } from './constants';
import { LeafInstance, TreeThings, ETreeAnimationMode, ETreeColoringMode } from './tree-types';
import { assertIsDefined, inOutCubic } from '../../../../utils';
import { createRandomRotation } from '../utils';
import {
  limbsVertMainInjection,
  leafVertMainInjection,
  treeVertPreamble,
  leafVertPreamble,
  leafFragPreamble,
  leafFragMainInjection,
} from './shaders';
import { objIsMesh } from '../utils';
import { LeafWorkerMgr } from './LeafWorkerMgr';
import { BackgroundStore } from '../../../../store/BackgroundStore';

const NOISE_SEED_VALUE = 0.2958121063932284;

// to try different seeds:
// const seedValue = Math.random();
// console.log({ leafColorNoiseSeed: seedValue });

const noise3 = createNoise3D(() => NOISE_SEED_VALUE);

export const getLeafShapeGeometry = async (scale: number = 1) => {
  // extra async stuff here due to earlier versions which loaded an svg
  // from the network. the extra geometry was unnecessary and expensive but
  // keeping it async in case we do something similar in the future.
  let resolveGeometry: (value: BufferGeometry | PromiseLike<BufferGeometry>) => void | undefined;
  const geometryPromise = new Promise<BufferGeometry>(resolve => {
    resolveGeometry = resolve;
  });

  resolveGeometry!(new PlaneGeometry(LEAF_SIZE, LEAF_SIZE));

  const leafGeometry = await geometryPromise;

  leafGeometry.scale(scale, scale, scale);

  leafGeometry.computeBoundingBox();
  const bBox = leafGeometry.boundingBox!;

  let minX: number, minY: number, width: number, height: number;

  // some tweaky bits ...
  minX = bBox.min.x;
  minY = bBox.min.y;
  width = (bBox.max.x - minX) * 1.1;
  height = (bBox.max.y - minY) * 1.1;

  const posAttr = leafGeometry.getAttribute('position');
  const uvArray: number[] = [];
  for (let i = 0; i < posAttr.count; i++) {
    const x = posAttr.getX(i);
    const y = posAttr.getY(i);
    uvArray.push((x - minX) / width, (y - minY) / height);
  }
  leafGeometry.setAttribute('uv', new BufferAttribute(new Float32Array(uvArray), 2));
  return leafGeometry;
};

const orange = new Color('#ffbb55');
// const yellow = new Color('#fff269');
const yellow = new Color('#ffee69');
const green = new Color('#4ee743');
const red = new Color('#f44343');

const STYLIZED_DARK_COLOR = new Color('#777788');
const STYLIZED_LIGHT_COLOR = new Color('#ddddff');

const STYLIZED_DARK_LIMBS_COLOR = STYLIZED_DARK_COLOR.clone().lerp(new Color('#222222'), 0.8);

export const getLeafColor = (p: Vector3, coloringMode: ETreeColoringMode) => {
  p.multiplyScalar(0.09);
  const n = noise3(p.x, p.y, p.z);
  if (n < 0) {
    return yellow.clone().lerp(red, inOutCubic(-1 * n));
  }
  return yellow.clone().lerp(green, inOutCubic(n));
};

export const createLeafInstance = ({
  twigIndex,
  positionAttribute,
  morphAttributes,
}: {
  twigIndex: number;
  positionAttribute: BufferAttribute | InterleavedBufferAttribute;
  morphAttributes?: { [name: string]: (BufferAttribute | InterleavedBufferAttribute)[] };
}): LeafInstance => {
  const meshPosition = new Vector3(
    positionAttribute.getX(twigIndex),
    positionAttribute.getY(twigIndex),
    positionAttribute.getZ(twigIndex),
  );

  // try to align with its branch by assuming the previous position vertex will be along the branch.
  // TODO: this does _something_ but probably not quite what i want.
  const prevPosition =
    twigIndex > 1
      ? new Vector3(
          positionAttribute.getX(twigIndex - 1),
          positionAttribute.getY(twigIndex - 1),
          positionAttribute.getZ(twigIndex - 1),
        )
      : undefined;
  const axis = prevPosition
    ? new Vector3().subVectors(prevPosition, meshPosition).normalize()
    : new Vector3(0, 1, 0).randomDirection();

  const quat = new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0).normalize(), axis);

  const dummyMatrix = new Matrix4();
  const leafinessTransform = new Matrix4();
  // rotate to axis
  leafinessTransform.multiply(dummyMatrix.makeRotationFromQuaternion(quat));

  // translate out so the stem is attached to the twig vertex
  leafinessTransform.multiply(dummyMatrix.makeTranslation(0, LEAF_SIZE / 2, 0));

  // add some randomness but keep it attached at its base
  leafinessTransform
    .multiply(dummyMatrix.makeTranslation(0, -LEAF_SIZE / 2, 0))
    .multiply(createRandomRotation(new Vector3(Math.PI * 0.25, Math.PI * 0.25, Math.PI * 0.25)))
    .multiply(dummyMatrix.makeTranslation(0, LEAF_SIZE / 2, 0));

  const morphTargets: Vector3[] = [];
  const morphPositions = morphAttributes?.position;
  if (morphPositions) {
    for (let i = 0; i < morphPositions.length; i++) {
      const morphPosition = new Vector3(
        morphPositions[i].getX(twigIndex),
        morphPositions[i].getY(twigIndex),
        morphPositions[i].getZ(twigIndex),
      );
      morphTargets.push(morphPosition);
    }
  }

  return {
    // TODO: using only meshPosition and turning it into a translation matrix at frame time results
    // in all the instances being clustered around the origin. not sure why. :/
    // for now, store a full matrix with each instance.
    positionMatrix: dummyMatrix.makeTranslation(meshPosition.x, meshPosition.y, meshPosition.z),
    meshPosition,
    noiseOffset: [Math.random(), Math.random(), Math.random()],
    morphTargets,
    leafinessTransform,
  };
};

const fetchTreeData = async ({ gltfPath, store }: { gltfPath: string; store: BackgroundStore }) => {
  const [gltf, leafMap, leafBump, leafAlpha, trunkMap, trunkNormalMap] = await Promise.all([
    store.loadAsset<GLTF>(gltfPath),
    store.loadAsset<Texture>('/trees/aspen-diffuse.jpg'),
    store.loadAsset<Texture>('/trees/aspen-bump.jpg'),
    store.loadAsset<Texture>('/trees/aspen-alpha.jpg'),
    store.loadAsset<Texture>('/trees/Birch15.jpg'),
    store.loadAsset<Texture>('/trees/Birch15Normal.jpg'),
  ]);
  leafMap.wrapS = RepeatWrapping;
  leafMap.wrapT = RepeatWrapping;
  leafBump.wrapS = RepeatWrapping;
  leafBump.wrapT = RepeatWrapping;
  leafAlpha.wrapS = RepeatWrapping;
  leafAlpha.wrapT = RepeatWrapping;
  trunkMap.wrapS = RepeatWrapping;
  trunkMap.wrapT = RepeatWrapping;
  trunkNormalMap.wrapS = RepeatWrapping;
  trunkNormalMap.wrapT = RepeatWrapping;

  return {
    gltf,
    leafMap,
    leafBump,
    leafAlpha,
    trunkMap,
    trunkNormalMap,
  };
};

export const createTree = async ({
  path,
  animationMode,
  coloringMode,
  store,
}: {
  path: string;
  animationMode: ETreeAnimationMode;
  coloringMode: ETreeColoringMode;
  store: BackgroundStore;
}): Promise<TreeThings> => {
  let limbsMesh: Mesh | undefined;

  const { gltf, leafMap, leafBump, leafAlpha, trunkMap, trunkNormalMap } = await fetchTreeData({
    gltfPath: path,
    store,
  });

  const windClip: AnimationClip | undefined = gltf.animations[0];
  gltf.scene.traverse(object => {
    if (objIsMesh(object)) {
      // assume the only mesh is the tree trunk + limbs.
      limbsMesh = object;
    }
  });

  assertIsDefined(limbsMesh);

  const limbsAnimationMixer = new AnimationMixer(limbsMesh);
  let limbsAnimationAction: AnimationAction | undefined;
  if (windClip) {
    limbsAnimationAction = limbsAnimationMixer.clipAction(windClip);
    limbsAnimationAction.weight = 0;
    limbsAnimationAction.timeScale = 0.3;
  }

  const { twigAttributes, getLimbsShader } = await createLimbs({
    limbsMesh,
    textures: { trunkMap, trunkNormalMap },
    animationMode,
    coloringMode,
  });
  const { leavesMesh, leafInstances, getLeavesShader } = await createLeaves({
    twigAttributes,
    geometry: limbsMesh.geometry,
    textures: { leafMap, leafBump, leafAlpha },
    animationMode,
    coloringMode,
  });

  let workerMgr: LeafWorkerMgr | undefined;
  if (animationMode === ETreeAnimationMode.cpuMorph) {
    workerMgr = new LeafWorkerMgr({
      leavesMesh,
      limbsMesh,
      instances: leafInstances,
      workerConfigs: [],
    });
  }

  const onFrame = ({ windIntensity, elapsedTime }: { windIntensity: number; elapsedTime: number }) => {
    if (workerMgr && animationMode === ETreeAnimationMode.cpuMorph) {
      workerMgr.onFrame({ windIntensity, elapsedTime });
    } else if (animationMode === ETreeAnimationMode.gpuCustom) {
      const limbsShader = getLimbsShader();
      if (limbsShader) {
        limbsShader.uniforms.elapsedTime.value = elapsedTime;
        limbsShader.uniforms.windIntensity.value = windIntensity;
      }
      const leavesShader = getLeavesShader();
      if (leavesShader) {
        leavesShader.uniforms.elapsedTime.value = elapsedTime;
        leavesShader.uniforms.windIntensity.value = windIntensity;
      }
    }
  };

  const dispose = () => {
    if (workerMgr) {
      workerMgr.dispose();
    }
    limbsAnimationAction?.stop();
    [trunkMap, trunkNormalMap, leafMap, leafBump, leafAlpha].forEach(t => t.dispose());
  };

  return {
    onFrame,
    dispose,
    limbsMesh,
    getLimbsShader,
    leavesMesh,
    leafInstances,
    getLeavesShader,
    limbsAnimationMixer,
    limbsAnimationAction,
  };
};

export const createLimbs = async ({
  limbsMesh,
  textures,
  animationMode,
  coloringMode,
}: {
  limbsMesh: Mesh;
  textures: { trunkMap: Texture; trunkNormalMap: Texture };
  animationMode: ETreeAnimationMode;
  coloringMode: ETreeColoringMode;
}) => {
  let twigAttributes: (BufferAttribute | InterleavedBufferAttribute)[] = [];
  let limbsShader: WebGLProgramParametersWithUniforms | undefined;
  const { trunkMap, trunkNormalMap } = textures;

  let limbsMaterial: Material;
  if (coloringMode === ETreeColoringMode.realistic) {
    limbsMaterial = new MeshStandardMaterial({
      map: trunkMap,
      normalMap: trunkNormalMap,
    });
  } else {
    limbsMaterial = new MeshBasicMaterial({
      // map: trunkMap,
      color: coloringMode === ETreeColoringMode.stylizedDark ? STYLIZED_DARK_LIMBS_COLOR : STYLIZED_LIGHT_COLOR,
    });
  }

  limbsMaterial.onBeforeCompile = (shader: WebGLProgramParametersWithUniforms) => {
    shader.uniforms.windIntensity = { value: 0 };
    shader.uniforms.elapsedTime = { value: 0 };
    shader.vertexShader = treeVertPreamble + shader.vertexShader;
    shader.vertexShader = shader.vertexShader.replace(
      '#include <begin_vertex>',
      `
      #include <begin_vertex>
      ${animationMode === ETreeAnimationMode.cpuMorph ? '' : limbsVertMainInjection}
    `,
    );
    limbsShader = shader;
  };
  limbsMesh.material = limbsMaterial;
  limbsMesh.castShadow = true;
  limbsMesh.receiveShadow = true;

  // For blender to export an attribute to gltf, its name must be prefixed with "_".
  // In blender, use the Geometry Nodes panel to rename the _face_ attributes
  // corresponding to these guys, which are output by grove.
  // _Don't_ try to export GPU instances, since that will kill animation.
  twigAttributes = [
    limbsMesh.geometry.getAttribute('_end_twig'),
    limbsMesh.geometry.getAttribute('_side_twig'),
    limbsMesh.geometry.getAttribute('_upward_twig'),
  ].filter(v => !!v);

  return {
    twigAttributes,
    getLimbsShader: () => limbsShader,
  };
};

export const createLeaves = async ({
  twigAttributes,
  geometry,
  textures,
  animationMode,
  coloringMode,
}: {
  twigAttributes: (BufferAttribute | InterleavedBufferAttribute)[];
  geometry: BufferGeometry;
  textures: { leafMap: Texture; leafBump: Texture; leafAlpha: Texture };
  animationMode: ETreeAnimationMode;
  coloringMode: ETreeColoringMode;
}) => {
  let leavesShader: WebGLProgramParametersWithUniforms | undefined;
  const { leafMap, leafBump, leafAlpha } = textures;
  const positionAttribute = geometry.getAttribute('position');
  const morphAttributes = geometry.morphAttributes;
  const leafInstances: LeafInstance[] = [];

  twigAttributes.forEach(twigAttribute => {
    for (let twigIndex = 0; twigIndex < twigAttribute.count; twigIndex++) {
      const isTwig = twigAttribute.getX(twigIndex) === 1;
      if (isTwig) {
        leafInstances.push(createLeafInstance({ twigIndex, positionAttribute, morphAttributes }));
      }
    }
  });

  // for us, the camera will always be at some small positive z relative to the leaves.
  // sort twig data by z to avoid transparent instanced mesh render order problems.
  leafInstances.sort((a, b) => a.meshPosition.z - b.meshPosition.z);

  const nTwigs = leafInstances.length;
  let leafMaterial: Material;
  if (coloringMode === ETreeColoringMode.realistic) {
    leafMaterial = new MeshStandardMaterial({
      transparent: true,
      map: leafMap,
      bumpMap: leafBump,
      alphaMap: leafAlpha,
      depthWrite: false,
      side: DoubleSide,
    });
  } else {
    leafMaterial = new MeshBasicMaterial({
      transparent: true,
      alphaMap: leafAlpha,
      depthWrite: false,
      color: coloringMode === ETreeColoringMode.stylizedDark ? STYLIZED_DARK_COLOR : STYLIZED_LIGHT_COLOR,
      vertexColors: false,
      side: DoubleSide,
    });
  }

  // TODO: ugh. a bit of nastiness required to get InstancedUniformsMesh to see our
  // custom uniform. err, attribute.
  (leafMaterial as any).uniforms = {
    leafOffset1: { value: 0 },
    leafOffset2: { value: 0 },
  };

  leafMaterial.onBeforeCompile = (shader: WebGLProgramParametersWithUniforms) => {
    shader.uniforms.elapsedTime = { value: 0 };
    shader.uniforms.windIntensity = { value: 0 };
    shader.uniforms.leafOffset1 = { value: 0 };
    shader.uniforms.leafOffset2 = { value: 0 };
    shader.vertexShader = treeVertPreamble + leafVertPreamble + shader.vertexShader;
    shader.vertexShader = shader.vertexShader.replace(
      '#include <begin_vertex>',
      `
    #include <begin_vertex>
    ${animationMode === ETreeAnimationMode.cpuMorph ? '' : leafVertMainInjection}
    `,
    );
    if (coloringMode === ETreeColoringMode.realistic) {
      shader.fragmentShader = leafFragPreamble + shader.fragmentShader;
      shader.fragmentShader = shader.fragmentShader.replace(
        '#include <opaque_fragment>',
        `
        #include <opaque_fragment>
        ${leafFragMainInjection}
        `,
      );
    }

    leavesShader = shader;
  };

  leafMaterial.customProgramCacheKey = () => `grove-tree-${animationMode}-${coloringMode}`;

  const leafGeometry = await getLeafShapeGeometry();
  const leavesMesh = new InstancedUniformsMesh(leafGeometry, leafMaterial, nTwigs);
  for (let i = 0; i < leafInstances.length; i++) {
    if (animationMode === ETreeAnimationMode.cpuMorph) {
      leavesMesh.setMatrixAt(i, leafInstances[i].positionMatrix);
    } else {
      leavesMesh.setMatrixAt(i, leafInstances[i].positionMatrix.clone().multiply(leafInstances[i].leafinessTransform));
      leavesMesh.setUniformAt('leafOffset1', i, Math.random());
      leavesMesh.setUniformAt('leafOffset2', i, Math.random());
    }

    leavesMesh.setColorAt(i, getLeafColor(leafInstances[i].meshPosition, coloringMode));
  }
  leavesMesh.castShadow = true;
  leavesMesh.receiveShadow = true;

  return {
    getLeavesShader: () => leavesShader,
    leavesMesh,
    leafInstances,
  };
};

const getFlutter = (instance: LeafInstance, elapsedTime: number, windIntensity: number) => {
  return new Euler(
    Math.sin((elapsedTime + instance.noiseOffset[0] * 10) * 15) * 0.2 * windIntensity,
    Math.cos((elapsedTime + instance.noiseOffset[1] * 10) * 18) * 0.3 * windIntensity,
    Math.sin((elapsedTime + instance.noiseOffset[2] * 10) * 10) * 0.07 * windIntensity,
  );
};

export const calculateLeafInstanceMatrices = ({
  instances,
  startIndex,
  endIndex,
  windIntensity,
  morphTargetInfluences,
  elapsedTime,
  sharedMatrices,
}: {
  instances: LeafInstance[];
  startIndex: number;
  endIndex: number;
  windIntensity: number;
  morphTargetInfluences: number[];
  elapsedTime: number;
  sharedMatrices?: SharedArrayBuffer;
}) => {
  const matrices: Matrix4[] = [];
  const dummyMatrix = new Matrix4();
  for (let instanceIndex = startIndex; instanceIndex < Math.min(endIndex, instances.length); instanceIndex++) {
    const instance = instances[instanceIndex];

    const leafMatrix = instance.positionMatrix.clone();

    // try to track the morph target animation
    for (let i = 0; i < instance.morphTargets.length; i++) {
      const morphTranslation: [number, number, number] = [
        instance.morphTargets[i].x * morphTargetInfluences[i],
        instance.morphTargets[i].y * morphTargetInfluences[i],
        instance.morphTargets[i].z * morphTargetInfluences[i],
      ];
      leafMatrix.multiply(dummyMatrix.makeTranslation(...morphTranslation));
    }
    leafMatrix.multiply(instance.leafinessTransform);

    // flutter
    /*
    const flutterEuler = getFlutter(instance, elapsedTime, windIntensity);
    leafMatrix
      .multiply(dummyMatrix.makeTranslation(0, -LEAF_SIZE / 2, 0))
      .multiply(dummyMatrix.makeRotationFromEuler(flutterEuler))
      .multiply(dummyMatrix.makeTranslation(0, LEAF_SIZE / 2, 0));
    */
    matrices.push(leafMatrix);
  }
  if (sharedMatrices) {
    writeMatricesToBuffer({
      src: matrices,
      dest: sharedMatrices,
      destStart: startIndex,
    });
  }
  return matrices;
};

export const MATRIX_ELEMENT_SIZE = 32;
export const ELEMENTS_PER_MATRIX = 16;
export const BYTES_PER_MATRIX = MATRIX_ELEMENT_SIZE * ELEMENTS_PER_MATRIX;

export const writeMatricesToBuffer = ({
  src,
  dest,
  srcStart,
  destStart,
  n,
}: {
  src: Matrix4[];
  dest: ArrayBuffer | SharedArrayBuffer;
  srcStart?: number;
  destStart?: number;
  n?: number;
}) => {
  const dataView = new DataView(dest);
  // const elements = new Array(16);
  srcStart = srcStart ?? 0;
  destStart = destStart ?? 0;
  const endIndex = n !== undefined ? srcStart + n : srcStart + src.length;
  for (let srcIndex = srcStart, destIndex = destStart; srcIndex < endIndex; srcIndex++, destIndex++) {
    // const data = src[srcIndex].toArray(elements);
    for (let j = 0; j < ELEMENTS_PER_MATRIX; j++) {
      dataView.setFloat32(destIndex * BYTES_PER_MATRIX + j * MATRIX_ELEMENT_SIZE, src[srcIndex].elements[j]);
    }
  }
};

export const readMatricesFromBuffer = ({
  src,
  dest,
  srcStart,
  destStart,
  n,
}: {
  src: ArrayBuffer | SharedArrayBuffer;
  dest: Matrix4[];
  srcStart?: number;
  destStart?: number;
  n?: number;
}) => {
  const dataView = new DataView(src);
  const elements = new Array(16);
  srcStart = srcStart ?? 0;
  destStart = destStart ?? 0;
  const endIndex = n !== undefined ? srcStart + n : srcStart + dest.length;
  for (let srcIndex = srcStart, destIndex = destStart; srcIndex < endIndex; srcIndex++, destIndex++) {
    for (let j = 0; j < ELEMENTS_PER_MATRIX; j++) {
      dataView.getFloat32(srcIndex * BYTES_PER_MATRIX + j * MATRIX_ELEMENT_SIZE, elements[j]);
    }
    dest[destIndex].fromArray(elements);
  }
};

export const setMatrixFromBuffer = ({
  src,
  dest,
  i,
}: {
  src: ArrayBuffer | SharedArrayBuffer;
  dest: Matrix4;
  i: number;
}) => {
  const dataView = new DataView(src);
  for (let j = 0; j < ELEMENTS_PER_MATRIX; j++) {
    dest.elements[j] = dataView.getFloat32(i * BYTES_PER_MATRIX + j * MATRIX_ELEMENT_SIZE);
  }
};
