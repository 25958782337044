import { makeAutoObservable } from 'mobx';
import { clamp, linear } from '../../../utils';

type Props = { [index: string]: number };

/**
 *
 */
export class Animator {
  private prev: Props;
  private next?: Props;
  private _current?: Props;
  private startTime?: number;
  private easeFn: (t: number) => number;
  private duration: number;
  private onTick?: (v: Props) => void;

  constructor({
    initial,
    duration = 500,
    easeFn = linear,
    onTick,
  }: {
    initial: Props;
    duration?: number;
    easeFn?: (t: number) => number;
    onTick?: (v: Props) => void;
  }) {
    this.prev = { ...initial };
    this._current = undefined;
    this.next = undefined;
    this.startTime = undefined;
    this.easeFn = easeFn;
    this.duration = duration;
    this.onTick = onTick;

    makeAutoObservable(this);
  }

  tick() {
    if (this.next !== undefined && this.startTime) {
      const now = Date.now();
      const delta: Props = {};
      Object.entries(this.next).forEach(([name, value]) => {
        delta[name] = this.next![name] - this.prev[name];
      });
      // const delta = this.next - this.prev;
      const ease = this.easeFn((now - this.startTime) / this.duration);
      this._current = {};
      Object.entries(this.prev).forEach(([name, value]) => {
        this._current![name] = clamp(
          value + delta[name] * ease,
          Math.min(this.next![name], this.prev[name]),
          Math.max(this.next![name], this.prev[name]),
        );
      });
      let metOrExceeded = Array.from(Object.entries(this._current)).every(([name, value]) => {
        return delta[name] >= 0 ? value >= this.next![name] : value < this.next![name];
      });

      if (metOrExceeded) {
        this.prev = { ...this.next };
        this._current = undefined;
        this.next = undefined;
        this.startTime = undefined;
      } else {
        requestAnimationFrame(this.tick.bind(this));
      }
      if (this.onTick) {
        this.onTick(this.current);
      }
    }
  }

  setNext(n: Props) {
    if (this._current !== undefined) {
      this.prev = this._current;
    }
    this.next = { ...n };
    this.startTime = Date.now();
    requestAnimationFrame(this.tick.bind(this));
  }

  get current() {
    return this._current ?? this.prev;
  }
}
