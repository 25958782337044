import {
  WebGLCoordinateSystem,
  WebGPUCoordinateSystem,
  Object3D,
  PerspectiveCamera,
  WebGLCubeRenderTarget,
  WebGLRenderer,
  Scene,
  Matrix4,
} from 'three';

const fov = -90; // negative fov is not an error
const aspect = 1;

/**
 * Yuck. Main things this does is:
 * - allow updating only certain faces of the cube at at time.
 * - set the camera's scale by (-1, -1, -1) so that the scene renders
 *   correctly from the inside.
 */
export class SkyCubeCamera extends Object3D {
  type: string;
  renderTarget: WebGLCubeRenderTarget;
  coordinateSystem: typeof WebGLCoordinateSystem | typeof WebGPUCoordinateSystem | null;
  activeMipmapLevel: number;

  constructor(near: number, far: number, renderTarget: WebGLCubeRenderTarget) {
    super();

    this.type = 'CubeCamera';

    this.renderTarget = renderTarget;
    this.coordinateSystem = null;
    this.activeMipmapLevel = 0;

    const cameraPX = new PerspectiveCamera(fov, aspect, near, far);
    cameraPX.layers = this.layers;
    this.add(cameraPX);

    const cameraNX = new PerspectiveCamera(fov, aspect, near, far);
    cameraNX.layers = this.layers;
    this.add(cameraNX);

    const cameraPY = new PerspectiveCamera(fov, aspect, near, far);
    cameraPY.layers = this.layers;
    this.add(cameraPY);

    const cameraNY = new PerspectiveCamera(fov, aspect, near, far);
    cameraNY.layers = this.layers;
    this.add(cameraNY);

    const cameraPZ = new PerspectiveCamera(fov, aspect, near, far);
    cameraPZ.layers = this.layers;
    this.add(cameraPZ);

    const cameraNZ = new PerspectiveCamera(fov, aspect, near, far);
    cameraNZ.layers = this.layers;
    this.add(cameraNZ);
  }

  updateCoordinateSystem() {
    const coordinateSystem = this.coordinateSystem;

    const cameras = this.children.concat();

    const [cameraPX, cameraNX, cameraPY, cameraNY, cameraPZ, cameraNZ] = cameras;

    for (const camera of cameras) this.remove(camera);

    if (coordinateSystem === WebGLCoordinateSystem) {
      cameraPX.up.set(0, 1, 0);
      cameraPX.lookAt(1, 0, 0);

      cameraNX.up.set(0, 1, 0);
      cameraNX.lookAt(-1, 0, 0);

      cameraPY.up.set(0, 0, -1);
      cameraPY.lookAt(0, 1, 0);

      cameraNY.up.set(0, 0, 1);
      cameraNY.lookAt(0, -1, 0);

      cameraPZ.up.set(0, 1, 0);
      cameraPZ.lookAt(0, 0, 1);

      cameraNZ.up.set(0, 1, 0);
      cameraNZ.lookAt(0, 0, -1);
    } else if (coordinateSystem === WebGPUCoordinateSystem) {
      cameraPX.up.set(0, -1, 0);
      cameraPX.lookAt(-1, 0, 0);

      cameraNX.up.set(0, -1, 0);
      cameraNX.lookAt(1, 0, 0);

      cameraPY.up.set(0, 0, 1);
      cameraPY.lookAt(0, 1, 0);

      cameraNY.up.set(0, 0, -1);
      cameraNY.lookAt(0, -1, 0);

      cameraPZ.up.set(0, -1, 0);
      cameraPZ.lookAt(0, 0, 1);

      cameraNZ.up.set(0, -1, 0);
      cameraNZ.lookAt(0, 0, -1);
    } else {
      throw new Error('THREE.CubeCamera.updateCoordinateSystem(): Invalid coordinate system: ' + coordinateSystem);
    }

    for (const camera of cameras) {
      this.add(camera);
      // modification: invert the camera to correct for sky box vs reflections
      camera.applyMatrix4(new Matrix4().makeScale(-1, -1, -1));
      camera.updateMatrixWorld();
    }
  }

  update(renderer: WebGLRenderer, scene: Scene, cameras: number[]) {
    if (this.parent === null) this.updateMatrixWorld();

    const { renderTarget, activeMipmapLevel } = this;

    if (this.coordinateSystem !== renderer.coordinateSystem) {
      this.coordinateSystem = renderer.coordinateSystem;

      this.updateCoordinateSystem();
    }

    const [cameraPX, cameraNX, cameraPY, cameraNY, cameraPZ, cameraNZ] = this.children as PerspectiveCamera[];

    const currentRenderTarget = renderer.getRenderTarget();
    const currentActiveCubeFace = renderer.getActiveCubeFace();
    const currentActiveMipmapLevel = renderer.getActiveMipmapLevel();

    const currentXrEnabled = renderer.xr.enabled;

    renderer.xr.enabled = false;

    const generateMipmaps = renderTarget.texture.generateMipmaps;

    renderTarget.texture.generateMipmaps = false;

    if (cameras.includes(0)) {
      renderer.setRenderTarget(renderTarget, 0, activeMipmapLevel);
      renderer.render(scene, cameraPX);
    }

    if (cameras.includes(1)) {
      renderer.setRenderTarget(renderTarget, 1, activeMipmapLevel);
      renderer.render(scene, cameraNX);
    }

    if (cameras.includes(2)) {
      renderer.setRenderTarget(renderTarget, 2, activeMipmapLevel);
      renderer.render(scene, cameraPY);
    }

    if (cameras.includes(3)) {
      renderer.setRenderTarget(renderTarget, 3, activeMipmapLevel);
      renderer.render(scene, cameraNY);
    }

    if (cameras.includes(4)) {
      renderer.setRenderTarget(renderTarget, 4, activeMipmapLevel);
      renderer.render(scene, cameraPZ);
    }

    // mipmaps are generated during the last call of render()
    // at this point, all sides of the cube render target are defined
    // renderTarget.texture.generateMipmaps = generateMipmaps;

    if (cameras.includes(5)) {
      renderer.setRenderTarget(renderTarget, 5, activeMipmapLevel);
      renderer.render(scene, cameraNZ);
    }

    renderTarget.texture.generateMipmaps = generateMipmaps;

    renderer.setRenderTarget(currentRenderTarget, currentActiveCubeFace, currentActiveMipmapLevel);

    renderer.xr.enabled = currentXrEnabled;

    // renderTarget.texture.needsPMREMUpdate = true;
  }
}
