import { useContext } from 'react';
import { StoreContext } from '../../../store';
import { observer } from 'mobx-react-lite';
import { ESiteMode } from '../../../store/UiStore';

export const BackgroundControls = observer(() => {
  const { background: store, ui: uiStore } = useContext(StoreContext);
  const allDisabled = uiStore.siteMode === ESiteMode.portfolioOnly;
  return (
    <div className="cp-section">
      <div className="control">
        <div className="control-title">FPS</div>
        <div className="control-value" style={{ gridColumnStart: 'input', justifySelf: 'end' }}>
          {store.performance.instantFps.toFixed(2)} fps | {store.performance.meanFps.toFixed(2)} avg
        </div>
      </div>
      <div className="control">
        <div className="control-title">Wind</div>
        <input
          className="control-enable"
          disabled={allDisabled}
          type="checkbox"
          checked={store.wind.isManual}
          onChange={event => {
            store.setWindIsManual(event.target.checked);
          }}
        />
        <input
          className="control-input"
          type="range"
          min={0}
          max={1}
          step="any"
          value={store.wind.intensity}
          disabled={!store.wind.isManual || allDisabled}
          onChange={event => {
            store.setWindIntensity(event.target.valueAsNumber);
          }}
        />
        <span className="control-value">{store.wind.intensity.toFixed(2)}</span>
      </div>
      <div className="control-group">
        <span className="group-name">Sun (coming soon!)</span>
        <div className="control">
          <span className="control-title">Azimuth </span>
          <input
            className="control-input"
            type="range"
            min={-180}
            max={180}
            // disabled={allDisabled}
            disabled={true}
            step="any"
            value={store.sun.azimuth}
            onChange={event => {
              store.setSunAzimuth(event.target.valueAsNumber);
            }}
          />
          <span className="control-value">{store.sun.azimuth.toFixed(2)}</span>
        </div>
        <div className="control">
          <span className="control-title">Elevation </span>
          <input
            className="control-input"
            type="range"
            min={-180}
            max={180}
            step="any"
            // disabled={allDisabled}
            disabled={true}
            value={store.sun.elevation}
            onChange={event => {
              store.setSunElevation(event.target.valueAsNumber);
            }}
          />
          <div className="control-value">{store.sun.elevation.toFixed(2)}</div>
        </div>
      </div>
      <div className="control-group">
        <div className="group-name">Camera</div>
        <div className="control">
          <div className="control-title"> RotY </div>
          <input
            className="control-input"
            type="range"
            min={-Math.PI / 2}
            max={Math.PI / 2}
            step="any"
            disabled={allDisabled}
            value={-1 * store.camera.yRot}
            onChange={event => {
              store.setCameraYRot(-1 * event.target.valueAsNumber);
            }}
          />
          <div className="control-value">{store.camera.yRot.toFixed(2)}</div>
        </div>
        <div className="control">
          <div className="control-title">RotX</div>
          <input
            className="control-enable"
            type="checkbox"
            disabled={allDisabled}
            checked={store.cameraTrack.xRotIsManual}
            onChange={event => {
              store.setCameraTrackXRotIsManual(event.target.checked);
            }}
          />
          <input
            className="control-input"
            type="range"
            min={-Math.PI / 2}
            max={Math.PI / 2}
            step="any"
            value={store.camera.xRot}
            disabled={!store.cameraTrack.xRotIsManual || allDisabled}
            onChange={event => {
              store.setCameraXRot(event.target.valueAsNumber);
            }}
          />
          <div className="control-value">{store.cameraXRot.toFixed(2)}</div>
        </div>
        <div className="control">
          <div className="control-title">Zoom</div>
          <input
            className="control-enable"
            type="checkbox"
            checked={store.cameraTrack.fovIsManual}
            disabled={allDisabled}
            onChange={event => {
              store.setCameraTrackFovIsManual(event.target.checked);
            }}
          />
          <input
            className="control-input"
            type="range"
            min={store.cameraTrack.minFov}
            max={store.cameraTrack.maxFov}
            step="any"
            disabled={allDisabled}
            value={store.cameraFocalLength}
            onChange={event => {
              store.setCameraFocalLength(event.target.valueAsNumber);
            }}
          />
          <div className="control-value">{store.cameraFocalLength.toFixed(2)}</div>
        </div>
        <div className="control">
          <div className="control-title">X</div>
          <input
            className="control-enable"
            type="checkbox"
            checked={store.cameraTrack.xPosIsManual}
            disabled={allDisabled}
            onChange={event => {
              store.setCameraTrackXPosIsManual(event.target.checked);
            }}
          />
          <input
            className="control-input"
            type="range"
            min={store.cameraTrack.minXPos}
            max={store.cameraTrack.maxXPos}
            step="any"
            value={store.cameraXPos}
            disabled={!store.cameraTrack.xPosIsManual || allDisabled}
            onChange={event => {
              store.setCameraXPos(event.target.valueAsNumber);
            }}
          />
          <div className="control-value">{store.cameraXPos.toFixed(2)}</div>
        </div>
        <div className="control">
          <div className="control-title">Y</div>
          <input
            className="control-enable"
            type="checkbox"
            disabled={allDisabled}
            checked={store.cameraTrack.yPosIsManual}
            onChange={event => {
              store.setCameraTrackYPosIsManual(event.target.checked);
            }}
          />
          <input
            className="control-input"
            type="range"
            min={store.cameraTrack.minYPos}
            max={store.cameraTrack.maxYPos}
            step="any"
            value={store.cameraYPos}
            disabled={!store.cameraTrack.yPosIsManual || allDisabled}
            onChange={event => {
              store.setCameraYPos(event.target.valueAsNumber);
            }}
          />
          <div className="control-value">{store.cameraYPos.toFixed(2)}</div>
        </div>
        <div className="control">
          <div className="control-title">Z</div>
          <input
            className="control-input"
            type="range"
            min={0}
            max={20}
            step="any"
            disabled={allDisabled}
            value={store.camera.zPos}
            onChange={event => {
              store.setCameraZPos(event.target.valueAsNumber);
            }}
          />
          <div className="control-value">{store.camera.zPos.toFixed(2)}</div>
        </div>
        <div className="control">
          <span className="control-title">Exposure</span>
          <input
            className="control-input"
            type="range"
            min={0}
            max={5}
            step="any"
            disabled={allDisabled}
            value={store.camera.exposure}
            onChange={event => {
              store.setCameraExposure(event.target.valueAsNumber);
            }}
          />
          <div className="control-value">{store.camera.exposure.toPrecision(2)}</div>
        </div>
      </div>
    </div>
  );
});
